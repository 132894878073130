import { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Typography, useTheme } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import Grid from '@mui/system/Unstable_Grid';
import type { GridColDef } from '@mui/x-data-grid';

import { DataGridWithEmptyState } from '../../../components/DataGrid';
import { ColumnCell } from '../../../components/DataGrid/ColumnCell';
import ButtonWithTooltip from '../../../components/common/ButtonWithTooltip';
import Icon from '../../../components/common/Icon';
import ProgressStatus from '../../../components/common/ProgressStatus';
import useWindowResize from '../../../hooks/useWindowResize';
import type { ResponseStatus } from '../../../interface';
import RoutePaths from '../../../routes/RoutePaths';
import type { JobDescription } from '../../../services/jobDescriptions';
import { JobProgressSource } from '../../../services/jobDescriptions';
import { useAccountStatusStore } from '../../../stores/AccountStatusStore';
import type { ScoreColumnProps } from '../../../utils';
import { sortScoreColumn } from '../../../utils';
import { compareDates, dateToTimeAgo } from '../../../utils/dateUtils';
import CreateNewListing from '../../Create/CreateNewListing';
import { Controls } from './Controls';

interface JobXraysTableProps {
  jobXrays: JobDescription[] | undefined;
  isLoading?: boolean;
  onClick?: (
    id: string,
    status: ResponseStatus,
    source: JobProgressSource,
    sourceId: string,
  ) => void;
}

const JobXrays = ({ jobXrays, isLoading, onClick }: JobXraysTableProps) => {
  const navigate = useNavigate();
  const theme: Theme = useTheme();
  const [contentHeight, setContentHeight] = useState(0);
  const [contentHeaderHeight, setContentHeaderHeight] = useState(0);
  const headerRef = useRef<HTMLDivElement>(null);

  const { isJDImportEnabled } = useAccountStatusStore();

  const handleResize = () => {
    const appBarHeight = document.querySelector('.MuiToolbar-root')?.clientHeight || 0;
    const fullHeight = window.innerHeight;
    setContentHeight(fullHeight - appBarHeight);
    setContentHeaderHeight(headerRef.current?.clientHeight || 0);
  };

  useWindowResize(handleResize);

  const onRenderUsedForScore = useCallback(
    ({ progress, score: usedForScore, status }: ScoreColumnProps) => {
      const onSuccess = <Typography variant="body2">{usedForScore > 0 ? 'Yes' : 'No'}</Typography>;

      return <ProgressStatus progress={progress} status={status} onSuccess={onSuccess} />;
    },
    [],
  );

  const tableHeaders: GridColDef[] = useMemo(
    () => [
      {
        field: 'job',
        headerName: 'Job X-Ray',
        flex: 1,
        renderCell: (params) => ColumnCell(params.value),
        sortComparator: (value1, value2) => value1.primaryText.localeCompare(value2.primaryText),
        disableColumnMenu: true,
      },
      {
        field: 'usedForScore',
        headerName: 'Used for Job Matches',
        flex: 0.8,
        renderCell: (params) => onRenderUsedForScore(params.value),
        sortComparator: (value1, value2) => sortScoreColumn(value1, value2),
        disableColumnMenu: true,
      },
      {
        field: 'lastUpdated',
        headerName: 'Created',
        flex: 0.8,
        disableColumnMenu: true,
        renderCell: (params) =>
          params.value.status === 'in_progress'
            ? 'In Progress'
            : dateToTimeAgo(params.value.updated_at),
        sortComparator: (value1, value2) => compareDates(value1.updated_at, value2.updated_at),
      },
      {
        field: 'controls',
        headerName: '',
        flex: 0.2,
        renderCell: (params) => Controls(params.value),
        disableColumnMenu: true,
        sortable: false,
      },
    ],
    [onRenderUsedForScore],
  );

  const DataGridRows = useMemo(() => {
    const rows = jobXrays?.map((jobDescription: JobDescription) => ({
      id: jobDescription._id,
      job: {
        primaryText: jobDescription.job_company,
        secondaryText: jobDescription.job_title,
      },
      usedForScore: {
        progress: jobDescription.progress,
        score: jobDescription.used_for_score,
        status: jobDescription.status,
        source: jobDescription?.source || JobProgressSource.JOB_XRAY,
        sourceId: jobDescription?.source_id || jobDescription._id,
      },
      lastUpdated: { status: jobDescription.status, updated_at: jobDescription.updated_at },
      controls: {
        id: jobDescription._id,
        jobCompany: jobDescription.job_company,
        jobTitle: jobDescription.job_title,
      },
    }));

    return rows || [];
  }, [jobXrays]);

  const onDataGridRowClick = (params: {
    row: {
      id: string;
      usedForScore: { status: ResponseStatus; source: JobProgressSource; sourceId: string };
    };
  }) => {
    const rowId = params.row.id;
    const { status: rowStatus, source, sourceId } = params.row.usedForScore;
    if (onClick) {
      onClick(rowId, rowStatus, source, sourceId);
    }
  };

  const CreateJobXrayButton = (
    <ButtonWithTooltip
      sx={{ paddingRight: '15px' }}
      disabled={!isJDImportEnabled}
      showDisabledTooltip
      onClick={() => navigate(RoutePaths.CREATE_JOB)}
    >
      <Icon
        className={`fi ${isJDImportEnabled ? 'fi-rr-plus' : 'fi-rr-lock'}`}
        style={{ fontSize: '13px', padding: '3.5px' }}
      />
      Add
    </ButtonWithTooltip>
  );

  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        backgroundColor: 'background.mediumLight',
        height: contentHeight,
        maxHeight: contentHeight,
        padding: '32px 32px 0px 32px',
      }}
    >
      <Grid xs={11.5}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            paddingBottom: 4,
          }}
          ref={headerRef}
        >
          <Stack>
            <Typography variant="h3">Jobs</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Manage the jobs you’re interested in. The Job X-Ray is an analysis of the raw job
              description.
            </Typography>
          </Stack>

          {DataGridRows.length > 0 && CreateJobXrayButton}
        </Stack>
        {/* job descriptions table */}
        {/* TODO; Create a styled component for datagrid */}
        <DataGridWithEmptyState
          getRowHeight={() => 'auto'}
          hideFooterPagination
          columns={tableHeaders}
          rows={DataGridRows}
          isLoading={isLoading}
          onRowClick={onDataGridRowClick}
          sortingOrder={['asc', 'desc']}
          emptyGridState={
            <CreateNewListing
              primaryText="Create your first Job"
              secondaryText="Upload your first job description to JobQuest."
              icon="fi fi-br-briefcase"
              action={CreateJobXrayButton}
            />
          }
          mainBoxSx={{
            flexGrow: DataGridRows.length > 0 ? 0 : 1,
            height: `calc(${contentHeight}px - ${contentHeaderHeight}px - ${theme.spacing(
              DataGridRows.length > 0 ? 4 : 9,
            )})`,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default JobXrays;
