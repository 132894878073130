import { addDays, isAfter, isBefore } from 'date-fns';
import { isNil } from 'ramda';

import type { Receipt } from '../services/billing';
import { PlanTypes } from '../services/billing';

/**
 * The number of days in the grace period for invoice payment.
 */
export const invoiceGracePeriodDays = 3;

/**
 * Enum representing the status of a Stripe invoice.
 */
export enum StripeInvoiceStatus {
  Draft = 'draft',
  Open = 'open',
  Paid = 'paid',
  Uncollectible = 'uncollectible',
  Void = 'void',
}

/**
 * Determines if the given subscription plan type is a free plan.
 *
 * @param {PlanTypes | undefined} planType - The type of the subscription plan.
 * @returns {boolean} - Returns `true` if the plan is a free plan or if the plan type is undefined, otherwise returns `false`.
 */
export const isFreeSubcriptionPlan = (planType: PlanTypes | undefined) => {
  if (planType === undefined) {
    return true;
  }

  return planType === PlanTypes.STANDARD;
};

/**
 * Determines if the payment grace period for a given invoice has passed.
 *
 * @param invoice - The invoice to check.
 * @param invoices - The list of all invoices to search.
 * @returns {boolean} - Returns `true` if the grace period has passed for the given invoice, otherwise returns `false`.
 *
 * Function Logic:
 * 1. **Paid Invoice Check**:
 *    - If the invoice's status is `Paid`, the grace period does not apply, and the function returns `false`.
 *
 * 2. **Grace Period Calculation**:
 *    - Converts the invoice's due date (`dueDate`) and issue date (`invoiceDate`) to `Date` objects.
 *    - Calculates the grace period end date by adding a predefined number of days (`invoiceGracePeriodDays`) to the `dueDate`.
 *
 * 3. **Active Grace Period Check**:
 *    - If the current date is before the calculated `gracePeriodEndDate`, returns `false`, as the grace period is still active.
 *
 * 4. **Cross-Referencing Invoices**:
 *    - Iterates through the list of invoices (`invoices`) to determine if there is a newer invoice that prevents marking the current invoice as past due.
 *    - Conditions for overlapping grace period:
 *      - The other invoice's issue date is:
 *        1. After the current invoice's issue date.
 *        2. After the current invoice's due date.
 *        3. Before the current invoice's grace period end date.
 *    - If such an overlapping invoice exists, returns `false`.
 *
 * 6. **Grace Period Expired**:
 *    - If none of the above conditions are met, the function concludes that the grace period has passed and returns `true`.
 *
 * Usage:
 * - Use this function to validate overdue invoices and determine if grace periods have expired.
 */
export const isPaymentGracePeriodPassed = (invoice: Receipt, invoices: Receipt[]): boolean => {
  // If the invoice is already paid, the grace period does not apply.
  if (invoice.status === StripeInvoiceStatus.Paid) {
    return false;
  }

  // Convert invoice due date and invoice date from string to Date objects.
  const currentDueDate = new Date(invoice.dueDate);
  const currentInvoiceDate = new Date(invoice.invoiceDate);
  const currentDate = new Date();
  // Calculate the end date of the grace period by adding a predefined number of days to the due date.
  const gracePeriodEndDate = addDays(currentDueDate, invoiceGracePeriodDays);

  // If the current date is before the grace period end date, return false (grace period is still active).
  if (isBefore(currentDate, gracePeriodEndDate)) {
    return false;
  }

  // Loop through the list of invoices to check if there is a newer invoice
  // within the grace period that could prevent marking this invoice as past due.
  for (const inv of invoices) {
    const invoiceDate = new Date(inv.invoiceDate);

    // Check if the invoice date is:
    // 1. After the current invoice's date.
    // 2. After the current invoice's due date.
    // 3. Before the grace period end date.
    // If all conditions are met, return false because the grace period is not considered passed.
    if (
      isAfter(invoiceDate, currentInvoiceDate) &&
      isAfter(invoiceDate, currentDueDate) &&
      isBefore(invoiceDate, gracePeriodEndDate)
    ) {
      return false;
    }
  }

  // If none of the conditions above are met, the grace period is considered passed.
  return true;
};

/**
 * Returns the name of the plan based on the provided plan type.
 *
 * @param {PlanTypes} planType - The type of the plan.
 * @returns {string} The name of the plan.
 */
export const getPlanName = (planType: PlanTypes | string | undefined) => {
  switch (planType) {
    case PlanTypes.STANDARD:
      return 'Free';
    case PlanTypes.TRIAL:
      return 'Pro';
    // TODO: Update plan name to match mockup
    case PlanTypes.PRO:
      return 'Pro';
    default:
      return 'Free';
  }
};

/**
 * Formats the given amount as a string representing the monthly plan amount.
 *
 * @param {string | undefined} amount - The amount to be formatted. If undefined, defaults to '$0 Forever'.
 * @returns {string} The formatted amount string.
 */
export const getPlanAmountPerMonth = (amount: string | undefined) => {
  if (amount) {
    return `$${amount}`;
  }
  return '$0 Forever';
};

/**
 * Returns a formatted string indicating the trial end date.
 *
 * @param trialEndDate - The trial end date as a string. If undefined, a default message is returned.
 * @returns A string indicating the formatted trial end date or a message indicating the date is not available.
 */
export const getTrialEndDateText = (trialEndDate: Date | undefined): string => {
  if (!trialEndDate) {
    return 'Trial end date is not available';
  }

  const dateToFormat = typeof trialEndDate === 'string' ? new Date(trialEndDate) : trialEndDate;
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = dateToFormat.toLocaleDateString('en-US', options);
  return `Your trial ends on ${formattedDate}`;
};

/**
 * Retrieves the latest invoice from an array of invoices.
 *
 * @param invoices - An array of invoices, where each invoice is of type `Receipt`.
 *   - The array is expected to be sorted in descending order by invoice date, with the most recent invoice at the first position.
 * @returns The latest invoice (`Receipt`) if available, or `undefined` if:
 *   - The invoices array is empty.
 *   - The invoices array is undefined.
 *   - The first invoice has a status of `Draft`, in which case the second invoice is considered the latest.
 *
 * @remarks
 * This function assumes that:
 * - The invoices array is pre-sorted by date in descending order.
 * - A `Draft` status indicates a pending or upcoming invoice that should not be treated as the latest finalized invoice.
 */
export const getLatestInvoice = (invoices: Receipt[] | undefined): Receipt | undefined => {
  // Return undefined if the invoices array is empty or undefined
  if ((invoices && invoices.length === 0) || invoices === undefined) {
    return undefined;
  }

  // If the first invoice has a Draft status, return the second invoice; otherwise, return the first invoice
  return invoices[0].status === StripeInvoiceStatus.Draft ? invoices[1] : invoices[0];
};

/**
 * Formats the next invoice date into a human-readable string.
 *
 * @param nextInvoiceDate - The date of the next invoice. If undefined, a default message is returned based on the plan's cancellation status.
 * @param planName - The user's current subscribed plan. Should be a value of the `PlanTypes` enum.
 * @param latestInvoiceStatus - (Optional) The status of the latest invoice, provided as a value from the `StripeInvoiceStatus` enum.
 *   - Indicates whether the latest invoice is paid, overdue, or undefined.
 *   - Note: `latestInvoiceStatus` will be undefined when the user switches from a trial to a Pro plan, but the trial period is still ongoing, and payment will not be charged until the trial ends.
 * @param isPlanCancelled - (Optional, default: `false`) A flag indicating whether the user's subscription plan is cancelled.
 * @returns A string providing:
 *   - The next invoice date in a human-readable format.
 *   - A cancellation message if the plan is cancelled.
 *   - A fallback message if the next invoice date is unavailable.
 */
export const getNextInvoiceDateText = (
  nextInvoiceDate: Date | undefined,
  planName: PlanTypes,
  latestInvoiceStatus?: StripeInvoiceStatus,
  isPlanCancelled = false,
): string => {
  // If there is no next invoice date, return an appropriate message
  if (!nextInvoiceDate) {
    return isPlanCancelled
      ? 'Your plan has been cancelled. Your subscription expires soon.'
      : 'Next invoice date is not available';
  }

  // Convert to nextInvoice Date object if the input is a string
  const formattedNextInvoiceDate =
    typeof nextInvoiceDate === 'string' ? new Date(nextInvoiceDate) : nextInvoiceDate;

  // Calculate grace period end date and get the current date
  const gracePeriodDate = addDays(formattedNextInvoiceDate, invoiceGracePeriodDays);
  const currentDate = new Date();

  // Format the date to a readable format (e.g., 15 Sep 2024)
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  const formattedDate = formattedNextInvoiceDate.toLocaleDateString('en-US', options);

  // Initialize the message
  let nextInvoiceMessage = '';

  // Handle the case where the plan is cancelled
  if (isPlanCancelled) {
    nextInvoiceMessage = `Your plan has been cancelled. Your subscription expires on ${formattedDate}.`;
  } else if (planName === PlanTypes.PRO) {
    // Handle active Pro plan scenarios
    // Case 1: No issues with the invoice status (e.g., paid or undefined during trial period)
    if (isNil(latestInvoiceStatus) || latestInvoiceStatus === StripeInvoiceStatus.Paid) {
      nextInvoiceMessage = `Next invoice is on ${formattedDate}`;
    } else if (
      isAfter(currentDate, formattedNextInvoiceDate) &&
      isBefore(currentDate, gracePeriodDate)
    ) {
      // Case 2: Overdue invoice within the grace period
      nextInvoiceMessage = `Your invoice was due on ${formattedDate}. Your subscription will be downgraded to Free.`;
    }
  }

  return nextInvoiceMessage;
};

/**
 * Formats a given date into a human-readable string in the format "MMM DD, YYYY".
 *
 * @param date - The date to format, which can be a Date object or a string representing a date.
 * @returns A formatted date string in the "MMM DD, YYYY" format.
 */
export const formatBillingDate = (date: Date | string) => {
  const dateToFormat = typeof date === 'string' ? new Date(date) : date;
  const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
  return dateToFormat.toLocaleDateString('en-US', options);
};
