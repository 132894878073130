import { useMemo, useState } from 'react';

import {
  Box,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';

import Button from '../../components/common/Button';
import theme from '../../theme/theme';
import { getOrdinalSuffix, getStringMonth } from '../../utils/dateUtils';

interface DowngradeSubscriptionContentProps {
  onDowngradeConfirm?: (reason: string) => void;
  nextInvoiceDate?: Date | undefined;
  onback?: () => void;
}

const DowngradeReasons = [
  'I got a job/interview',
  'I updated my resume',
  'I’m not able to achieve my objective with JobQuest',
  'I don’t like the service',
  'Other',
];

/**
 * DowngradeSubscriptionContent component renders a modal content for downgrading a subscription.
 * It provides a list of reasons for downgrading and allows the user to select one.
 * The user can confirm the downgrade or go back to the previous step.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onDowngradeConfirm - Callback function to handle the downgrade confirmation.
 * @property {Date | undefined} props.nextInvoiceDate - The date of the next invoice.
 * @param {Function} props.onback - Callback function to handle the back action.
 *
 * @returns {JSX.Element} The rendered component.
 */
const DowngradeSubscriptionContent = ({
  onDowngradeConfirm = () => {},
  nextInvoiceDate,
  onback,
}: DowngradeSubscriptionContentProps) => {
  const [selectedValue, setSelectedValue] = useState('0');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const paymentEndDate = useMemo(() => {
    if (nextInvoiceDate) {
      const date = new Date(nextInvoiceDate);
      return `${date.getDate()}${getOrdinalSuffix(date.getDate())} ${getStringMonth(
        date.getMonth() + 1,
      )}`;
    }
    return null;
  }, [nextInvoiceDate]);

  return (
    <Stack sx={{ gap: 2 }}>
      <Stack sx={{ gap: 1, paddingBottom: 2 }}>
        {/* TODO: pass plan end date form parent component and use here */}
        <Typography variant="body3">
          {`Your payment will not be renewed after this payment cycle ends on ${paymentEndDate}, you will not
          lose any existing data.`}
        </Typography>
        <Typography variant="body3">
          Please let us know your reason for cancelling your JobQuest Pro subscription.{' '}
        </Typography>
      </Stack>

      {/* list with gray border and 4 list items with radio buttons, use Mui list component */}
      <Box
        sx={{
          border: '1px solid',
          borderColor: 'border.main',
          borderRadius: '8px',
          marginBottom: 3,
        }}
      >
        <RadioGroup value={selectedValue} onChange={handleChange}>
          <List>
            {DowngradeReasons.map((reason, index) => (
              <ListItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                sx={{
                  padding: `${theme.spacing(0.625)} ${theme.spacing(1.5)}`,
                  alignItems: 'flex-start',
                  ':hover': {
                    backgroundColor: 'background.main',
                  },
                  backgroundColor: selectedValue === index.toString() ? 'primary.light' : 'inherit',
                  borderRadius: 1,
                }}
                onClick={(e) => setSelectedValue((e.target as HTMLInputElement).value)}
              >
                <FormControlLabel
                  value={index}
                  control={<Radio size="small" />}
                  label={
                    <ListItemText
                      primary={reason}
                      sx={{ margin: 0 }}
                      primaryTypographyProps={{ variant: 'body3', sx: { wordBreak: 'break-word' } }}
                    />
                  }
                />
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      </Box>

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          gap: 1,
        }}
      >
        <Button
          variant="text"
          onClick={onback}
          sx={{
            color: 'text.primary',
            border: '1.5px solid',
            borderColor: 'border.light',
            width: { xs: '45%', sm: 'fit-content' },
            padding: {
              xs: `${theme.spacing(2)}`,
              sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
            },
            ':hover': {
              backgroundColor: 'background.main',
            },
          }}
        >
          Back
        </Button>
        <Button
          color="error"
          sx={{
            width: { xs: '55%', sm: 'fit-content' },
            padding: {
              xs: `${theme.spacing(2)}`,
              sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
            },
          }}
          onClick={() => onDowngradeConfirm(DowngradeReasons[parseInt(selectedValue, 10)])}
        >
          Cancel Subscription
        </Button>
      </Stack>
    </Stack>
  );
};

export default DowngradeSubscriptionContent;
