import { useState } from 'react';

import { Typography, useTheme } from '@mui/material';
import type { Grid2Props } from '@mui/material/Unstable_Grid2';
import Grid from '@mui/material/Unstable_Grid2';

import SelectCard from '../../../components/common/SelectCard';
import type { CareerStageKey } from '../constants';
import { careerStageOptions } from '../constants';

/**
 * This component renders a grid of selectable cards, each representing a different career stage.
 * It allows users to select their current career stage by clicking on a card. Upon selection,
 * the `onChange` callback is triggered, passing the selected career stage key to the parent component.
 *
 * @component
 *
 * @param {function} props.onChange - A callback function that is called with the selected career stage key when a card is clicked.
 * @param {string} props.defaultValue - The default value for the selected career stage.
 * @param {Object} props.headingProps - The props for the heading element.
 * @param {Object} props.contentProps - The props for the content element.
 * @returns {JSX.Element} A grid of selectable career stage cards.
 *
 * @example
 * // Example usage
 * const handleCareerStageChange = (selectedKey: string) => {
 *   console.log('Selected Career Stage:', selectedKey);
 * };
 *
 * <ChooseCareerStage
 *   onChange={setSelectedCareerStage}
 *   defaultValue={CareerStageKey.INTERSHIP}
 *   headingProps={{ sx: { textAlign: 'left' }, paddingX: 0 }}
 *   contentProps={{ lg: 6 }}
 * />
 */

interface ChooseCareerStageProps {
  onChange: (key: CareerStageKey) => void;
  defaultValue?: CareerStageKey;
  headingProps?: Grid2Props;
  contentProps?: Grid2Props;
}

const ChooseCareerStage = ({
  onChange,
  defaultValue,
  headingProps,
  contentProps,
}: ChooseCareerStageProps) => {
  const [selectedKey, setSelectedKey] = useState(defaultValue);
  const theme = useTheme();

  const handleChooseCareerStage = (key: string) => {
    setSelectedKey(key as CareerStageKey);
    if (onChange) {
      onChange(key as CareerStageKey);
    }
  };

  return (
    <Grid spacing={2} container sx={{ justifyContent: 'center', width: '100%', margin: 0 }}>
      <Grid
        xs={12}
        {...headingProps}
        sx={{
          paddingTop: 0,
          paddingBottom: 3,
          textAlign: { xs: 'center', md: 'left' },
          ...headingProps?.sx,
        }}
      >
        <Typography variant="h3">Choose a Career Stage</Typography>
      </Grid>
      {careerStageOptions.map((item) => (
        <Grid
          key={item.key}
          xs={12}
          md={6}
          lg={4}
          {...contentProps}
          sx={{ [theme.breakpoints.down('md')]: { paddingX: 0 }, ...contentProps?.sx }}
        >
          <SelectCard
            cardContent={item}
            sx={{ borderColor: selectedKey === item.key ? 'primary.main' : 'border.light' }}
            onClick={handleChooseCareerStage}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ChooseCareerStage;
