import { Stack, Typography } from '@mui/material';

import Icon from './Icon';

/**
 * A functional component that renders a tooltip content indicating
 * that the user needs to upgrade to the Pro version to access certain features.
 *
 * @returns {JSX.Element} The JSX element representing the tooltip content.
 */
const DefaultDisabledTooltipContent = () => (
  <Stack direction="row" gap={1}>
    <Icon className="fi fi-rr-progress-upload" style={{ fontSize: '16px' }} />
    <Typography variant="label2">Upgrade to JobQuest Pro</Typography>
  </Stack>
);

export default DefaultDisabledTooltipContent;
