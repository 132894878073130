import type { User } from '../stores/AuthStore';

export enum StorageKeys {
  AUTHENTICATED_USER = 'authenticated-user',
  TOKEN = 'accessToken',
  REFRESH_TOKEN = 'refreshToken',
  OTP_TOKEN = 'otp_token',
  JOB_MATCHES_SNACKBAR_VISIBLE = 'job-matches-snackbar-visible',
  JOB_MATCH_LIST_STATUS = 'job-match-list-status',
  JOB_DESCRIPTIONS_IN_PROGRESS = 'job-desctions-in-progress',
  GLOBAL_ERROR = 'global-error',
  LOG_IN_SNACKBAR = 'log-in-snackbar',
  CONTINUE_TO_JOQBQUEST_URL = 'continue-to-jobquest-url',
  REFETCH_ACCOUNT_STATUS = 'refetch-account-status',
}

function set(key: string, value: string) {
  localStorage.setItem(key, value);
  return true;
}

function get(key: string) {
  return localStorage.getItem(key);
}

function remove(key: string) {
  localStorage.removeItem(key);
}

/**
 * Clear all items from local storage
 * @returns {void}
 */
function clear() {
  localStorage.clear();
}

function setJwtToken(token: string) {
  if (!token) return new Error('JwtToken is required');

  localStorage.setItem(StorageKeys.TOKEN, token);
  return true;
}

function getJwtToken() {
  return localStorage.getItem(StorageKeys.TOKEN);
}

function removeJwtToken() {
  localStorage.removeItem(StorageKeys.TOKEN);
}

function setRefreshToken(token: string) {
  if (!token) return new Error('Refresh Token is required');

  localStorage.setItem(StorageKeys.REFRESH_TOKEN, token);
  return true;
}

function getRefreshToken() {
  return localStorage.getItem(StorageKeys.REFRESH_TOKEN);
}

function removeRefreshToken() {
  localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
}

function setOtpToken(token: string) {
  if (!token) return new Error('OTP Token is required');

  localStorage.setItem(StorageKeys.OTP_TOKEN, token);
  return true;
}

function getOtpToken() {
  return localStorage.getItem(StorageKeys.OTP_TOKEN);
}

function removeOtpToken() {
  localStorage.removeItem(StorageKeys.OTP_TOKEN);
}

/**
 * Sets current authenticated user in local storage
 * @param user
 * @returns boolean
 * @example
 * import { Storage } from 'services/storage';
 * Storage.setCurrentUser(user);
 */
function setCurrentUser(user: User) {
  if (!user) return new Error('User is required');

  localStorage.setItem(StorageKeys.AUTHENTICATED_USER, JSON.stringify(user));
  return true;
}

/**
 * Get current authenticated user from local storage
 * @returns {User | null}
 * @example
 * import { Storage } from 'services/storage';
 * Storage.getCurrentUser();
 * @see {@link User}
 */
function getCurrentUser(): User | null {
  const user = localStorage.getItem(StorageKeys.AUTHENTICATED_USER);
  return user ? JSON.parse(user) : null;
}

/**
 * Remove current authenticated user from local storage
 * @returns {void}
 */
function removeCurrentUser() {
  localStorage.removeItem(StorageKeys.AUTHENTICATED_USER);
}

/**
 * Remove all tokens (jwtToken, RefreshToken, Otp_Token) from local storage
 * @returns {void}
 * @example
 * import { Storage } from 'services/storage';
 * Storage.removeTokens();
 */
function removeTokens() {
  removeJwtToken();
  removeRefreshToken();
  removeOtpToken();
}

export const getJobMatchListStatus = (): string =>
  localStorage.getItem(StorageKeys.JOB_MATCH_LIST_STATUS) || 'done';

export const setJobMatchListStatus = (progressStatus: string) => {
  localStorage.setItem(StorageKeys.JOB_MATCH_LIST_STATUS, progressStatus);
};

export const getJobDesctionsInProgess = (): boolean => {
  const isInProgress = localStorage.getItem(StorageKeys.JOB_DESCRIPTIONS_IN_PROGRESS);
  return isInProgress === 'true';
};

export const setJobDesctionsInProgess = (isInProgress: boolean) => {
  localStorage.setItem(StorageKeys.JOB_DESCRIPTIONS_IN_PROGRESS, isInProgress.toString());
};

export const getShowLogInSnackbarStatus = (): boolean => {
  const logInSncakbarStatus = localStorage.getItem(StorageKeys.LOG_IN_SNACKBAR);
  return logInSncakbarStatus === 'true';
};

export const setShowLogInSnackbarStatus = (logInSncakbarStatus: boolean) => {
  localStorage.setItem(StorageKeys.LOG_IN_SNACKBAR, logInSncakbarStatus.toString());
};

/**
 * Sets the refetch account status in the local storage.
 *
 * @param {boolean} [refetch=true] - The refetch status to be stored. Defaults to true.
 */
export const setRefetchAccountStatus = (refetch = true) => {
  localStorage.setItem(StorageKeys.REFETCH_ACCOUNT_STATUS, refetch.toString());
};

/**
 * Retrieves the refetch account status from local storage.
 *
 * @returns {boolean} - Returns `true` if the refetch account status is set to 'true' in local storage, otherwise `false`.
 */
export const getShouldRefetchAccountStatus = (): boolean => {
  const refetchStatus = localStorage.getItem(StorageKeys.REFETCH_ACCOUNT_STATUS);
  return refetchStatus === 'true';
};

/**
 * Storage service
 * @module services/storage
 * @exports Storage
 * @description A service to handle local storage related operations
 * @example
 * import { Storage } from 'services/storage';
 * Storage.set('key', 'value');
 * or
 * Storage.get('key');
 * or
 * Storage.remove('key');
 * or
 * Storage.clear();
 * or
 * and for more see implementation...
 */
export const Storage = {
  set,
  get,
  remove,
  clear,
  setJwtToken,
  getJwtToken,
  removeJwtToken,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  setOtpToken,
  getOtpToken,
  removeOtpToken,
  removeTokens,
  setCurrentUser,
  getCurrentUser,
  removeCurrentUser,
  getJobMatchListStatus,
  setJobMatchListStatus,
  setRefetchAccountStatus,
  getShouldRefetchAccountStatus,
};
