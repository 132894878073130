import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, useTheme } from '@mui/material';

import BootstrapTooltip from '../../../components/common/BootstrapTooltip';
import Button from '../../../components/common/Button';
import Chip from '../../../components/common/Chip';
import Icon from '../../../components/common/Icon';
import ListingCard from '../../../components/common/ListingCard';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { StyledChip } from '../../../components/common/ProgressStatusChip';
import { useManageSubscriptionModal } from '../../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import type { ResponseStatus } from '../../../interface';
import RoutePaths from '../../../routes/RoutePaths';
import type { JobMatchScore } from '../../../services/jobMatches';
import { useAccountStatusStore } from '../../../stores/AccountStatusStore';
import { useSwipeableDrawerStore } from '../../../stores/SwipeableDrawerStore';
import { getScoreColor } from '../../../utils/colorUtils';
import { dateToTimeAgo } from '../../../utils/dateUtils';
import CreateNewListing from '../../Create/CreateNewListing';
import { Controls } from './Controls';

interface JobMatchesListProps {
  jobMatches: JobMatchScore[] | undefined;
  isLoading?: boolean;
  onClick?: (id: string, rowStatus: ResponseStatus) => void;
}

interface RowControl {
  id: string;
  jobCompany: string;
  jobTitle: string;
}

const JobMatchesList = ({ jobMatches, isLoading, onClick }: JobMatchesListProps) => {
  const { isOpen, toggle: toggleSwipeableDrawer } = useSwipeableDrawerStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const [control, setControl] = useState<RowControl>({ id: '', jobCompany: '', jobTitle: '' });
  const { openModal: openManageSubscriptionModal } = useManageSubscriptionModal();
  const { isCreateJobMatchScoreEnabled } = useAccountStatusStore();

  const cardRows = useMemo(() => {
    const rows = jobMatches?.map((jobMatch) => ({
      id: jobMatch._id,
      status: jobMatch.status,
      progress: jobMatch.progress,
      lastUpdated: jobMatch.updated_at,
      score: jobMatch.total_score,
      control: {
        id: jobMatch._id || '',
        jobCompany: jobMatch.job_company || '',
        jobTitle: jobMatch.job_title || '',
      },
      items: [
        {
          icon: 'fi fi-rr-briefcase',
          heading: jobMatch.job_company,
          subHeading: jobMatch.job_title,
        },
        {
          icon: 'fi fi-rr-document',
          heading: (
            <span>
              {jobMatch.resume_is_ai_customized || jobMatch.resume_is_ai_generated
                ? jobMatch?.resume_name || jobMatch?.job_company || ''
                : 'Base'}
              {jobMatch.resume_is_ai_customized && <Chip label="AI Customized" />}
              {jobMatch.resume_is_ai_generated && <Chip label="AI Sample" />}
            </span>
          ),
          subHeading: jobMatch.resume_filename,
        },
      ],
    }));

    return rows || [];
  }, [jobMatches]);

  const onDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, rowControl: RowControl): void => {
      e.stopPropagation();
      setControl(rowControl);
      toggleSwipeableDrawer();
    },
    [toggleSwipeableDrawer],
  );

  useEffect(() => {
    // when swipeable drawer is closed, reset control
    if (!isOpen) {
      setControl({ id: '', jobCompany: '', jobTitle: '' });
    }
  }, [isOpen]);

  const CreateJobMatchButton = useMemo(() => {
    const onCreateButtonClick = () => {
      if (isCreateJobMatchScoreEnabled) {
        navigate(RoutePaths.CREATE_JOB_MATCH);
      } else {
        openManageSubscriptionModal();
      }
    };

    return (
      <Button
        sx={{
          padding: `${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(2)} ${theme.spacing(
            2,
          )}`,
          ...(!isCreateJobMatchScoreEnabled && {
            color: 'text.secondary',
            paddingRight: 2,
            backgroundColor: 'background.mediumLight',
            ':hover': {
              borderWidth: 0,
              backgroundColor: 'background.main',
            },
          }),
        }}
        onClick={onCreateButtonClick}
      >
        <Icon
          className={`fi ${isCreateJobMatchScoreEnabled ? 'fi-rr-plus' : 'fi-rr-lock'}`}
          style={{
            fontSize: theme.spacing(1.625),
            padding: '3.5px',
            ...(!isCreateJobMatchScoreEnabled && { color: theme.palette.text.secondary }),
          }}
        />
        Create
      </Button>
    );
  }, [isCreateJobMatchScoreEnabled, navigate, openManageSubscriptionModal, theme]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {cardRows.length > 0 ? (
        <>
          <Stack sx={{ gap: 2, padding: '24px 12px' }}>
            {cardRows.map((row) => (
              <ListingCard
                key={row.id}
                items={row.items}
                description={
                  row.status === 'in_progress' ? 'In progress' : dateToTimeAgo(row.lastUpdated)
                }
                // TODO: Create Separete component for delete icon
                control={
                  <BootstrapTooltip title="Delete">
                    <IconButton
                      aria-label="delete icon"
                      size="small"
                      component="button"
                      onClick={(e) => onDeleteClick(e, row.control)}
                    >
                      <Icon className="fi fi-br-trash" color={theme.palette.text.secondary} />
                    </IconButton>
                  </BootstrapTooltip>
                }
                status={row.status}
                progress={row.progress}
                onSuccess={
                  <StyledChip
                    icon={
                      <Icon
                        fontSize="8px"
                        className="fi fi-ss-circle"
                        style={{ color: getScoreColor(row.score) }}
                      />
                    }
                    label={`Match ${row.score}%`}
                    sx={{
                      backgroundColor: getScoreColor(row.score, 'color', 'light'),
                    }}
                  />
                }
                onClick={() => onClick?.(row.id, row.status)}
                enableStatus
              />
            ))}
          </Stack>
          <Controls {...control} />
        </>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            height: '100%',
          }}
        >
          <CreateNewListing
            primaryText="Create your first Job Match"
            secondaryText="Get your job match score and find out how well your resume matches a job description."
            icon="fi fi-br-exchange"
            action={CreateJobMatchButton}
            sx={{ border: 'none' }}
          />
        </Box>
      )}
    </>
  );
};

export default JobMatchesList;
