import { useState } from 'react';

import { Stack, Typography } from '@mui/material';

import Button from '../../../components/common/Button';
import InfoPopup from '../../../components/common/InfoPopup';
import ModalActions from '../../../components/common/Modal/ModalActions';

interface DeleteAccountProps {
  onDelete: () => void;
}

/**
 * Component for deleting user account.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onDelete - The function to be called when the account delete process is confirmed.
 * @returns {JSX.Element} The JSX element representing the delete account component.
 */
const DeleteAccount = ({ onDelete }: DeleteAccountProps) => {
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => setShowPopup(true);
  const closePopup = () => setShowPopup(false);

  return (
    <Stack gap="24px">
      <InfoPopup
        content={{
          title: 'Delete Account?',
          description:
            'Are you sure you want to permanently delete your account and all the associated data? This action cannot be undone.',
        }}
        open={showPopup}
        onOpen={openPopup}
        onClose={closePopup}
        actions={
          <ModalActions
            type="delete"
            onConfirm={onDelete}
            onClose={closePopup}
            disableConfirmButtonDuration={5}
          />
        }
      />
      <Stack>
        <Typography variant="label1">Delete Account</Typography>
        <Typography variant="body2">
          Permanently delete your account and all data, including resumes.
        </Typography>
      </Stack>
      <Button color="error" sx={{ maxWidth: 'fit-content' }} size="small" onClick={openPopup}>
        Delete Account
      </Button>
    </Stack>
  );
};

export default DeleteAccount;
