import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressManager from '../../../components/ProgressManager';
import { ProgressScreenType } from '../../../components/ProgressManager/constants';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { JobDescriptionTypes, uploadJobDescription } from '../../../services/jobDescriptions';
import UploadOnboardingProfile from './UploadOnboardingProfile';

/**
 * `OnboardingJobXray` component facilitates the onboarding process for creating a Job X-Ray.
 * It allows users to upload a job description, processes the data, and then provides insights
 * about the job description through a job x-ray.
 *
 * This component displays a job description upload form initially. Upon successful upload,
 * it transitions to a progress manager that tracks the processing status of the job x-ray.
 *
 * @component
 * @returns {JSX.Element} Rendered `OnboardingJobXray` component
 *
 * @example
 * // Basic usage:
 * <OnboardingJobXray />
 *
 */
const OnboardingJobXray = () => {
  // State to track the Job X-Ray ID for progress management and conditional rendering
  const [jobXrayId, setJobXrayId] = useState('');
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const [showProgress, setShowProgress] = useState(false);
  const queryClient = useQueryClient(); // Query client for cache management

  /**
   * Handles the upload of a job description for creating a job x-ray.
   *
   * @function
   * @param {Object} data - The data object containing the job description.
   * @param {string} data.description - The job description text to upload.
   * @returns {Promise<Object>} The API response containing the job x-ray ID.
   */
  const uploadJdQueryFuntion = async ({ description }: { description: string }) => {
    const res = await uploadJobDescription(description);
    return res;
  };

  /**
   * `uploadJD` mutation handle the job description upload process.
   * On success, it logs an event, sets the `jobXrayId`, and triggers the progress manager.
   * Displays error messages in the snackbar on failure.
   */
  const { isLoading: isJdUploading, mutate: uploadJD } = useMutation({
    retry: 3,
    mutationFn: uploadJdQueryFuntion,
    onSuccess: (jdUploadData) => {
      // Invalidate cache to refresh job x-rays data
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]);
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Onboarding Job X-Ray Creation started',
      );
      setJobXrayId(jdUploadData._id);
      setShowProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  /**
   * Navigates back to the previous page in the onboarding flow.
   */
  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {showProgress ? (
        /**
         * `ProgressManager` displays the progress of Job X-Ray creation.
         * Displays success event on successful completion of the report.
         */
        <ProgressManager
          id={jobXrayId}
          progressScreenKey={ProgressScreenType.JDXray}
          successEventLabel="Onboarding Job X-Ray Creation Successful"
        />
      ) : (
        /**
         * `UploadOnboardingProfile` component renders the upload form
         * and invokes the `uploadJD` mutation on form submission.
         */
        <UploadOnboardingProfile
          onCreateClick={uploadJD}
          enableInputSection={{ resume: false, jobDescription: true }}
          onBackClick={onBackClick}
          isLoading={isJdUploading}
          title={isMobile ? 'Create your first Job X-Ray' : 'Create Job X-Ray'}
          description="Analyzes job descriptions to extract key insights and highlights, helping you tailor your resume effectively."
        />
      )}
    </>
  );
};

export default OnboardingJobXray;
