import type { CardProps } from '../../../components/common/Card';
import Card from '../../../components/common/Card';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';

/**
 * OnboardingCard component.
 *
 * This component is used to render an onboarding card with customizable styles and content.
 *
 * @component
 * @example
 * ```tsx
 *
 * <OnboardingCard>
 *   <h1>Welcome to the onboarding process!</h1>
 *   <p>Follow the steps below to get started.</p>
 * </OnboardingCard>
 *
 * ```
 */
const OnboardingCard = ({ children, sx, rootSx, cardContentProps, ...restProps }: CardProps) => {
  const { isMobile } = useResponsiveDevice();

  return (
    <Card
      rootSx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...rootSx,
      }}
      sx={{
        height: '100%',
        boxSizing: 'border-box',
        ...(!isMobile ? { borderRadius: 3, padding: 5 } : { border: 'none' }),
        ...sx,
      }}
      cardContentProps={{
        ...cardContentProps,
        sx: {
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 3, sm: 5 },
          alignItems: 'center',
          justifyContent: 'space-between',
          maxWidth: '70rem',
          height: '100%',
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
          ...cardContentProps?.sx,
        },
      }}
      {...restProps}
    >
      {children}
    </Card>
  );
};

export default OnboardingCard;
