import BootstrapTooltip from './BootstrapTooltip';
import type { ButtonProps } from './Button';
import Button from './Button';
import DefaultDisabledTooltipContent from './DefaultDisabledTooltipContent';

export interface ButtonWithTooltipProps extends ButtonProps {
  showDisabledTooltip?: boolean;
  disabledTooltipContent?: string | React.ReactNode;
}

/**
 * Custom button component that wraps a `Button` and displays a tooltip when the button is disabled.
 * The tooltip can display custom content or a default message when the button is disabled.
 *
 * @component
 * @example
 * // Example usage:
 * <ButtonWithTooltip
 *   disabled={true}
 *   showDisabledTooltip={true}
 *   disabledTooltipContent="This button is currently unavailable"
 * >
 *   Submit
 * </ButtonWithTooltip>
 *
 * @param {ButtonWithTooltipProps} props - The props for the `ButtonWithTooltip` component.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled. When `true`, the button is visually disabled, and a tooltip will be shown if `showDisabledTooltip` is true.
 * @param {boolean} [props.showDisabledTooltip=false] - Whether to show a tooltip when the button is disabled. When `true`, the `disabledTooltipContent` will be displayed as a tooltip when the button is disabled.
 * @param {string | React.ReactNode} [props.disabledTooltipContent=<DefaultDisabledTooltipContent />] - Custom content for the tooltip when the button is disabled. By default, a predefined message is displayed. You can pass a string or any React node to customize the tooltip content.
 * @param {...ButtonProps} [restProps] - All other props are passed directly to the `Button` component, including properties such as `onClick`, `startIcon`, `trailingIcon`, etc.
 *
 * @returns {JSX.Element} The rendered `ButtonWithTooltip` component, which includes a `Button` wrapped in a `BootstrapTooltip`.
 */
const ButtonWithTooltip = ({
  disabled,
  showDisabledTooltip = false,
  disabledTooltipContent = <DefaultDisabledTooltipContent />,
  ...restProps
}: ButtonWithTooltipProps) => (
  // Wrapping the button in a tooltip component that shows only if the button is disabled and `showDisabledTooltip` is true
  <BootstrapTooltip
    title={disabled && showDisabledTooltip ? disabledTooltipContent : ''}
    placement="top"
    PopperProps={{
      sx: {
        marginBottom: '-0.5rem',
      },
    }}
  >
    <span style={{ width: 'fit-content' }}>
      {/* Button component with passed props, including `disabled` prop */}
      <Button disabled={disabled} {...restProps} />
    </span>
  </BootstrapTooltip>
);

export default ButtonWithTooltip;
