import { useRef } from 'react';
import Slider from 'react-slick';

import { Box, Stack, Typography } from '@mui/material';

import './styles/index.css';

interface CarouselProps {
  slides: { title: string; description: string; image: string }[];
}

/**
 * A carousel component that displays a collection of slides.
 *
 * @component
 * @param {Object[]} slides - An array of slide objects.
 * @param {string} slides[].title - The title of the slide.
 * @param {string} slides[].image - The URL of the slide image.
 * @param {string} slides[].description - The description of the slide.
 * @returns {JSX.Element} The rendered carousel component.
 *
 * @example
 * const slides = [
 *   {
 *     title: 'Slide 1',
 *     description: 'This is the first slide',
 *     image: 'https://example.com/slide1.jpg',
 *   },
 *   {
 *     title: 'Slide 2',
 *     description: 'This is the second slide',
 *     image: 'https://example.com/slide2.jpg',
 *   },
 * ];
 *
 * <Carousel slides={slides} />
 */

const Carousel = ({ slides }: CarouselProps) => {
  const sliderRef = useRef<Slider>();

  const ReactSlickSettings = {
    ref: (slider: Slider) => {
      sliderRef.current = slider;
    },
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Slider {...ReactSlickSettings}>
      {slides.map((slide) => (
        <Stack key={slide?.title}>
          {/* slide image */}
          <Box
            sx={{
              backgroundColor: 'background.mediumLight',
              paddingY: { xs: 2, sm: 6 },
              marginBottom: 2,
              height: { xs: 'auto', sm: '12.5rem' },
            }}
          >
            <img style={{ width: '100%', height: '100%' }} src={slide?.image} alt={slide?.title} />
          </Box>
          {/* active slide details */}
          <Stack
            sx={{
              textAlign: 'center',
              gap: 1,
            }}
          >
            <Typography variant="label1" sx={{ color: 'text.primaryGray' }}>
              {slide?.title}
            </Typography>
            <Typography variant="body3">{slide?.description}</Typography>
          </Stack>
        </Stack>
      ))}
    </Slider>
  );
};

export default Carousel;
