import { useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AlertColor } from '@mui/material';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import AddMissingInfoButton from '../../components/common/AddMissingInfoButton';
import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import { useManageSubscriptionModal } from '../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import { useSnackbar } from '../../contexts/snackbar';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { ResponseStatus } from '../../interface';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import type { JobMatchScore } from '../../services/jobMatches';
import { JobMatchScoreKeys, getAllJobMatches } from '../../services/jobMatches';
import { Storage, StorageKeys } from '../../services/storage';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import { useAppBarStore } from '../../stores/AppBarStore';
import { useLastVisitedTopPageStore } from '../../stores/LastVisitedTopPageStore';
import theme from '../../theme/theme';
import { getListingSnackbar } from '../../utils';
import JobMatchesList from './components/JobMatchesList';
import JobMatchesTable from './components/JobMatchesTable';

const snackbarMessage = {
  missing: 'Job X-Ray is missing some required information',
  in_progress: 'New Job Match Score is being created',
  error: 'New Job Match Score could not be created',
  success: 'Job Match Score created successfully',
};

const JobMatchesPage = () => {
  const navigate = useNavigate();
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { setLastVisitedTopPage } = useLastVisitedTopPageStore();
  const { isMobile, isTablet } = useResponsiveDevice();
  const { setAppBar, resetToDesktopAppBar, setInitialState } = useAppBarStore();
  const [isNavigatedFromScoreProgressPage, setIsNavigatedFromScoreProgressPage] = useState(false);
  const { isCreateJobMatchScoreEnabled } = useAccountStatusStore();
  const { openModal: openManageSubscriptionModal } = useManageSubscriptionModal();

  const CreateJobMatchButton = useMemo(() => {
    const onCreateButtonClick = () => {
      if (isCreateJobMatchScoreEnabled) {
        navigate(RoutePaths.CREATE_JOB_MATCH);
      } else {
        openManageSubscriptionModal();
      }
    };

    return (
      <Button
        variant="text"
        sx={{
          padding: 1.5,
          minWidth: 'max-content',
          ...(!isCreateJobMatchScoreEnabled && {
            backgroundColor: 'background.mediumLight',
            ':hover': {
              borderWidth: 0,
              backgroundColor: 'background.main',
            },
          }),
        }}
        onClick={onCreateButtonClick}
      >
        <Icon
          className={`fi ${isCreateJobMatchScoreEnabled ? 'fi-rr-plus' : 'fi-rr-lock'}`}
          style={{
            fontSize: '20px',
            ...(!isCreateJobMatchScoreEnabled && { color: theme.palette.text.secondary }),
          }}
        />
      </Button>
    );
  }, [isCreateJobMatchScoreEnabled, navigate, openManageSubscriptionModal]);

  // TODO: add control for latest JD in snackbar
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getSnackbarControl = () => (
    <Button
      variant="text"
      sx={{
        color: 'common.white',
        textTransform: 'capitalize',
        padding: '8px 12px',
        marginLeft: '30px',
      }}
      // TODO: add navigate path
      onClick={() => navigate('')}
    >
      <Typography variant="label2"> view score </Typography>
    </Button>
  );

  const jobMatchesQueryHandler = async (): Promise<{
    result: JobMatchScore[];
    status: { status: string };
  }> => {
    const res = await getAllJobMatches();

    const listingSnackbar = getListingSnackbar(
      res?.status?.status,
      RoutePaths.JOBMATCHES,
      isNavigatedFromScoreProgressPage,
      setIsNavigatedFromScoreProgressPage,
      snackbarMessage,
    );

    if (listingSnackbar) {
      if (listingSnackbar?.showControl) {
        const pendingMatchScoreId = res?.result.find(
          (matchScore) => matchScore.status === ResponseStatus.PENDING,
        )?._id;
        const missingInfoButton = (
          <AddMissingInfoButton
            onClick={() => {
              hideSnackbar();
              navigate(RoutePaths.CREATE_JOB_MATCH, {
                state: { matchScoreId: pendingMatchScoreId },
              });
            }}
            sx={{ marginLeft: { xs: 0, md: 2.5 }, color: 'common.white' }}
            typographyVariant="label2"
            hideIcon
          />
        );
        showSnackbar(
          listingSnackbar.severity as AlertColor,
          listingSnackbar.message,
          missingInfoButton,
        );
      } else {
        logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, listingSnackbar.message);
        showSnackbar(listingSnackbar.severity as AlertColor, listingSnackbar.message);
      }
    }

    return res;
  };

  const { data: jobMatchesQueryResponse, isLoading } = useQuery({
    queryKey: [JobMatchScoreKeys.JOBMATCHES],
    queryFn: jobMatchesQueryHandler,
    refetchInterval: (data) => {
      // if job matches creation is in progress, refetch every 5 seconds
      if (data?.status?.status === ResponseStatus.IN_PROGRESS) {
        return 5000;
      }
      return false;
    },
  });

  // job matches data from api response
  const jobMatches = jobMatchesQueryResponse?.result;

  useLayoutEffect(() => {
    setLastVisitedTopPage(RoutePaths.JOBMATCHES);
    setIsNavigatedFromScoreProgressPage(
      Storage.get(StorageKeys.CONTINUE_TO_JOQBQUEST_URL) === RoutePaths.JOBMATCHES,
    );
  }, [setLastVisitedTopPage]);

  useLayoutEffect(() => {
    if (isMobile || isTablet) {
      setAppBar('Job Matches', jobMatches && jobMatches?.length > 0 ? CreateJobMatchButton : null);
    } else {
      resetToDesktopAppBar();
    }
    return () => {
      setInitialState(isMobile);
    };
  }, [
    CreateJobMatchButton,
    isMobile,
    isTablet,
    jobMatches,
    resetToDesktopAppBar,
    setAppBar,
    setInitialState,
  ]);

  const onClick = (id: string, status: ResponseStatus) => {
    if (id) {
      if (status === ResponseStatus.DONE) {
        navigate(`${RoutePaths.JOBMATCHES}/${id}`);
      } else if (status === ResponseStatus.PENDING) {
        navigate(RoutePaths.CREATE_JOB_MATCH, { state: { matchScoreId: id } });
      }
    }
  };

  return isMobile || isTablet ? (
    <JobMatchesList jobMatches={jobMatches} isLoading={isLoading} onClick={onClick} />
  ) : (
    <JobMatchesTable jobMatches={jobMatches} isLoading={isLoading} onClick={onClick} />
  );
};

export default JobMatchesPage;
