import { isNotNil } from 'ramda';
import type { ReactNode } from 'react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import type { SxProps } from '@mui/material';
import { Box, FormHelperText, IconButton, Stack, Typography, useTheme } from '@mui/material';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import Icon from '../common/Icon';
import InfoPopup from '../common/InfoPopup';
import InputLabel from '../common/InputLabel';
import TextWrappedLinkButton from '../common/TextWrappedLinkButton';

interface FileDropzoneProps {
  onFileChange: (file: File | undefined) => void;
  hideLabel?: boolean;
  label?: string;
  defaultValue?: File;
  helperText?: string | ReactNode;
  sx?: SxProps;
}

enum DropzoneState {
  DEFAULT = 'default',
  SELECTED = 'selected',
  DRAG = 'drag',
  ERROR = 'error',
}

const dropzoneColor = {
  [DropzoneState.DEFAULT]: 'border.light',
  [DropzoneState.SELECTED]: 'border.light',
  [DropzoneState.DRAG]: 'primary.main',
  [DropzoneState.ERROR]: 'error.main',
};

/**
 * The `FileDropzone` component provides a user interface for uploading resume files.
 * It supports drag-and-drop functionality and allows users to either drag a file into the dropzone
 * or select a file directly to upload. This component is designed specifically for uploading PDF files.
 *
 * @prop {Function} onFileChange - Callback function called when a file is selected or removed.
 * @prop {boolean} [hideLabel=false] - If `true`, hides the label.
 * @prop {string} [label="Resume"] - The label for the dropzone, defaults to "Resume".
 * @prop {File} [defaultValue] - Default file value, if any.
 * @prop {string | ReactNode} [helperText] - Helper text displayed below the label.
 * @prop {SxProps} [sx] - Optional styling using MUI's `sx` prop.
 *
 * @remarks
 * This component uses `react-dropzone` to manage drag-and-drop events and accepts only PDF files.
 * Additionally, it shows informative text about the concept of a base resume,
 * with a link to further information displayed in a popup.
 *
 * @component
 *
 * @example
 * ```tsx
 * const handleFileChange = (file: File | undefined) => {
 *   // Handle the uploaded file
 * };
 *
 * <FileDropzone
 *   onFileChange={handleFileChange}
 *   label="Upload Resume"
 *   helperText="Supported format: PDF"
 * />
 * ```
 *
 * ### States:
 * - **DEFAULT**: No file selected.
 * - **SELECTED**: A file is selected.
 * - **DRAG**: A file is being dragged over the dropzone.
 * - **ERROR**: A non-PDF file is dragged into the dropzone.
 *
 */
const FileDropzone = ({
  onFileChange,
  hideLabel,
  label,
  helperText,
  defaultValue,
  sx,
}: FileDropzoneProps) => {
  const [file, setFile] = useState<File | undefined>(defaultValue);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const { isMobile } = useResponsiveDevice();
  const [dropzoneState, setDropzoneState] = useState<DropzoneState>(DropzoneState.DEFAULT);

  const theme = useTheme();

  const onDrop = useCallback(
    (uploadedFile: File[]) => {
      onFileChange(uploadedFile[0]);
      setFile(uploadedFile[0]);
      setDropzoneState(DropzoneState.SELECTED);
    },
    [onFileChange],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onDragEnter: () => setDropzoneState(DropzoneState.DRAG),
    onDragLeave: () => setDropzoneState(DropzoneState.DEFAULT),
    onDropRejected: () => setDropzoneState(DropzoneState.ERROR),
    multiple: false,
    accept: {
      'application/pdf': ['.pdf'],
    },
  });

  const handleRemoveResumeFile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      setFile(undefined);
      onFileChange(undefined);
      setDropzoneState(DropzoneState.DEFAULT);
    },
    [onFileChange],
  );

  const closePopup = () => {
    setShowPopup(false);
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  return (
    <Box>
      {!hideLabel && (
        <Stack>
          <InputLabel htmlFor="resume" label={label || 'Resume'} required />
          <FormHelperText sx={{ marginTop: '0px', marginBottom: '8px' }}>
            {helperText || (
              <TextWrappedLinkButton
                variant="body3"
                text={{
                  startingText: 'Importing a new resume creates a base resume for JobQuest. ',
                  linkText: 'Base Resume',
                  endingText: '',
                }}
                onClick={openPopup}
              />
            )}
          </FormHelperText>
          <InfoPopup
            content={{
              title: 'What is a Base Resume?',
              description:
                'Importing a new resume creates a base resume that you can duplicate, edit, AI customize, and download. Using the base resume, you can easily create many versions of resumes for different job positions. Avoid importing the same resume twice.',
            }}
            open={showPopup}
            onOpen={openPopup}
            onClose={closePopup}
          />
        </Stack>
      )}
      <Box {...getRootProps()} sx={sx}>
        <input {...getInputProps()} />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '40px',
            border: dropzoneState === DropzoneState.DRAG ? '1.5px dashed' : '1px solid',
            borderColor: dropzoneColor[dropzoneState],
            borderRadius: '8px',
            padding: '20px 12px',
            cursor: 'pointer',
            gap: '12px',
            '&:hover': {
              borderColor: 'border.main',
            },
            '&:focus-visible': {
              outlineColor: 'primary.main',
            },
          }}
        >
          {dropzoneState === DropzoneState.DRAG ? (
            <Typography variant="label2" sx={{ color: 'primary.main' }}>
              Drop your file here to upload
            </Typography>
          ) : (
            <>
              <Icon
                className={`fi ${
                  dropzoneState === DropzoneState.SELECTED ? 'fi-rr-file' : 'fi-rr-add-document'
                }`}
                fontSize="32px"
                color={theme.palette.text.secondary}
              />
              {dropzoneState === DropzoneState.SELECTED ? (
                <Stack sx={{ textAlign: 'left', flexGrow: 1 }}>
                  <Typography variant="body3">{file?.name}</Typography>
                  <Typography variant="assistive" color="grey.600">
                    Ready to upload
                  </Typography>
                </Stack>
              ) : (
                <Stack sx={{ textAlign: 'left', flexGrow: 1 }}>
                  <Typography variant="body3">Select a resume PDF file to upload</Typography>
                  {!isMobile && (
                    <Typography variant="assistive" color="grey.600">
                      or drop your file here
                    </Typography>
                  )}
                </Stack>
              )}
              {isNotNil(file) && (
                <IconButton
                  aria-label="remove icon"
                  size="small"
                  component="button"
                  onClick={handleRemoveResumeFile}
                >
                  <Icon className="fi fi-rr-cross-small" />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FileDropzone;
