import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { InputLabel, Link, Stack, TextField, Typography } from '@mui/material';

import Button from '../../components/common/Button';
import Card from '../../components/common/Card';
import Logo from '../../components/common/Logo';
import RoutePaths from '../../routes/RoutePaths';
import { useAuthStore } from '../../stores/AuthStore';

const SignInPage = () => {
  const location = useLocation();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const { login } = useAuthStore();

  const navigate = useNavigate();

  const emailProps = register('email', {
    required: 'Please enter the email',
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Please enter correct email address',
    },
  });

  const handleLogin = async ({ email }: { email: string }) => {
    setLoading(true);
    try {
      await login(email);
      setLoading(false);
      navigate(RoutePaths.VERIFICATION, { state: { path: RoutePaths.SIGN_IN, email } });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError(err?.response?.data?.errors);
      setLoading(false);
    }
  };

  const handleCreateAccount = (email: string) => {
    navigate(RoutePaths.SIGN_UP, { state: { email } });
  };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: { xs: 5, md: 3 },
        maxWidth: '34.375rem',
        paddingTop: 8,
        paddingBottom: 3,
        margin: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
      }}
    >
      <Logo width={{ xs: '180px', md: '300px' }} height="auto" enablePlanChip={false} />
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <form onSubmit={handleSubmit(handleLogin as any)}>
        <Card
          sx={{
            width: '80vw',
            maxWidth: '500px',
            padding: { xs: 1, md: 2 },
            borderColor: 'border.light',
            borderRadius: '12px',
          }}
        >
          {/* card content */}
          <Stack gap={6} sx={{ alignItems: 'center', width: '100%' }}>
            <Stack gap={3} sx={{ width: '100%' }}>
              <Typography variant="h2" sx={{ textAlign: 'center' }}>
                Sign In
              </Typography>
              <Stack gap={1} sx={{ width: '100%' }}>
                <InputLabel
                  htmlFor="email"
                  sx={{
                    color: 'error.main',
                    '& .MuiFormLabel-asterisk': { paddingLeft: 0.5, fontSize: '12px' },
                  }}
                  required
                >
                  <Typography variant="label2" color="text.primary">
                    Email
                  </Typography>
                </InputLabel>
                {/* TODO: Update textField component to handle react hook form onChnage */}
                <TextField
                  defaultValue={location.state?.email || ''}
                  placeholder="dori@example.com"
                  {...emailProps}
                  // TODO: move to styled-components or a better css implementation
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      fieldset: {
                        borderColor: 'border.light',
                        borderRadius: '8px',
                      },
                    },
                    width: '100%',
                  }}
                  error={!!errors.email || !!error}
                />
                {(errors.email?.message || error) && (
                  <Typography variant="assistive" color="error">
                    {(errors.email?.message as string) || error}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <Button type="submit" loading={loading} sx={{ width: '80px' }}>
              <Typography variant="label2"> Next </Typography>
            </Button>
          </Stack>
        </Card>
      </form>
      <Typography variant="body3" sx={{ display: 'flex', alignItems: 'center' }}>
        Don&apos;t have an account?
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          component="button"
          underline="hover"
          sx={{ paddingLeft: 0.5, color: 'primary.main' }}
          onClick={() => handleCreateAccount(getValues('email'))}
        >
          Sign Up
        </Link>
      </Typography>
    </Stack>
  );
};

export default SignInPage;
