import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Avatar, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useAuthStore } from '../../../stores/AuthStore';
import Button from '../../common/Button';
import Icon from '../../common/Icon';

const ProfileMenu = () => {
  // TODO: pass using Drawer context
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const { user, logout } = useAuthStore();

  let nameInitials;
  if (user?.name) {
    const name = user.name.split(' ');
    const firstNameInitial = name[0][0].toUpperCase() || '';
    const lastNameInitial = name?.[1]?.[0]?.toUpperCase() || name?.[0]?.[1]?.toUpperCase() || ''; // Handling single names

    nameInitials = `${firstNameInitial}${lastNameInitial}`;
  } else {
    nameInitials = user?.email.slice(0, 2).toUpperCase();
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSettings = useCallback(() => {
    handleClose();
    navigate('/settings');
  }, [handleClose, navigate]);

  const handleLogout = useCallback((): void => {
    logout();
    handleClose();
  }, [logout, handleClose]);

  const menuListContent = useMemo(
    () => [
      {
        icon: 'fi fi-rr-settings',
        label: 'Settings',
        onClick: handleSettings,
      },
      {
        icon: 'fi fi-rr-exit',
        label: 'Sign out',
        onClick: handleLogout,
      },
    ],
    [handleLogout, handleSettings],
  );

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
        sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
      >
        {/* <AccountCircle /> */}
        <Avatar>{nameInitials}</Avatar>
        <Icon
          className={anchorEl ? 'fi fi-rr-angle-small-up' : 'fi fi-rr-angle-small-down'}
          style={{
            display: 'inline-flex',
            color: theme.palette.text.tertiary,
          }}
        />
      </IconButton>
      {/* TODO: move to separate component */}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{ sx: { minWidth: '200px', borderRadius: '8px' } }}
        sx={{ marginTop: '2.5rem' }}
      >
        <MenuItem
          sx={{
            borderBottom: '1px solid',
            borderColor: 'border.light',
            alignItems: 'flex-start',
          }}
          component={Stack}
        >
          {user?.name && <Typography variant="body3">{`${user.name}`}</Typography>}
          <Typography variant="assistive" sx={{ color: 'text.secondary' }}>
            {user?.email}
          </Typography>
        </MenuItem>
        {menuListContent.map((menuItem) => (
          <MenuItem key={menuItem.label} sx={{ padding: 0 }} onClick={menuItem.onClick}>
            <Button
              variant="text"
              sx={{
                color: 'text.primary',
                width: '100%',
                justifyContent: 'flex-start',
              }}
            >
              <Icon className={menuItem.icon} />
              <Typography variant="body3">{menuItem.label}</Typography>
            </Button>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ProfileMenu;
