import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import { Grid, Stack, Typography } from '@mui/material';

import Button from '../../components/common/Button';
import Card from '../../components/common/Card';
import Logo from '../../components/common/Logo';
import { useSnackbar } from '../../contexts/snackbar';
import RoutePaths from '../../routes/RoutePaths';
import { setShowLogInSnackbarStatus } from '../../services/storage';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import { useAuthStore } from '../../stores/AuthStore';

const VerificationPage = () => {
  const [otp, setOtp] = useState<string>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { verifyOtp, resendOtp } = useAuthStore();

  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const { initialize: initializeAccountStatusStore } = useAccountStatusStore();

  const location = useLocation();
  const { path, email } = location.state;

  const submitVerificationCode = async (
    event: React.FormEvent<HTMLFormElement> | null,
  ): Promise<void> => {
    if (event) {
      event.preventDefault();
    }

    if (!otp) {
      setError('Please enter the verification code');
      return;
    }

    setLoading(true);
    setError('');
    try {
      const res = await verifyOtp(otp);
      setLoading(false);

      // init get account status
      initializeAccountStatusStore();

      if (res?.onboard_user) {
        navigate(RoutePaths.ONBOARDING);
      } else {
        navigate(RoutePaths.JOBMATCHES);
        setShowLogInSnackbarStatus(false);
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError(err?.response?.data?.errors);
      setLoading(false);
    }
  };

  const resendVerificationCode = async () => {
    setLoading(true);
    try {
      await resendOtp();
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setError(err?.response?.data?.errors);
      setLoading(false);
    }

    setTimeout(() => {
      showSnackbar('success', 'Verification code sent to your email');
      setLoading(false);
    }, 1000);
  };

  const onVerificationInputChange = (value: string) => {
    setError('');
    setOtp(value);
  };

  // TODO: check why copy pasting a number is resulting in otp NaN
  // const onVerificationInputComplete = (value: string) => {
  //   return;
  //   const parsedOtp = parseInt(value, 10);
  //   setOtp(parsedOtp);
  //   // eslint-disable-next-line no-console
  //   console.log({ value, parsedOtp, otp });

  //   setTimeout(() => {
  //     submitVerificationCode(null);
  //   }, 10);
  // };

  return (
    <Stack
      sx={{
        alignItems: 'center',
        gap: { xs: 5, md: 3 },
        maxWidth: '34.375rem',
        paddingTop: 8,
        paddingBottom: 3,
        margin: 'auto',
        height: 'auto',
        boxSizing: 'border-box',
      }}
    >
      <Logo width={{ xs: '180px', md: '300px' }} height="auto" enablePlanChip={false} />
      <form onSubmit={submitVerificationCode}>
        <Card
          sx={{
            width: '80vw',
            maxWidth: '420px',
            padding: 2,
            borderColor: 'border.light',
            borderRadius: '12px',
          }}
        >
          <Stack sx={{ gap: 4 }}>
            <Stack gap={1}>
              <Typography variant="h2" sx={{ textAlign: 'center' }}>
                {path === RoutePaths.SIGN_IN
                  ? 'Verify Code for Sign In'
                  : 'Verify Code for Create Account'}
              </Typography>
              <Typography variant="body3" textAlign="center" display="block">
                Enter the code we sent to {email}
              </Typography>
            </Stack>
            <Stack sx={{ gap: 6, alignItems: 'center' }}>
              <Stack sx={{ gap: 0.5, alignItems: 'center' }}>
                <Stack
                  sx={{
                    '& .vi__character': {
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                      border: (theme) =>
                        `1px solid ${error ? theme.palette.error.main : '#E9E9E9'}`,
                      borderRadius: '8px',
                      height: '40px',
                      width: '40px',
                      // /* Body 3 */
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      padding: 1.25,
                      lineHeight: '20px' /* 142.857% */,
                    },
                    '& .vi__character--selected': {
                      outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                      color: (theme) => theme.palette.primary.main,
                    },
                    '& :where(.vi__container)': {
                      maxWidth: { xs: '100%' },
                    },
                    alignItems: 'center',
                  }}
                >
                  <VerificationInput
                    autoFocus
                    onChange={onVerificationInputChange}
                    // onComplete={(value) => onVerificationInputComplete(value)}
                    placeholder=""
                  />

                  {error && (
                    <Typography variant="assistive" color="error.main">
                      {error}
                    </Typography>
                  )}
                </Stack>

                <Stack
                  sx={{
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: 'center',
                    color: 'text.secondary',
                  }}
                >
                  <Typography variant="assistive">
                    Didn&apos;t get code? Check your spam folder.
                  </Typography>
                  <Button variant="text" onClick={resendVerificationCode} sx={{ padding: 0 }}>
                    <Typography variant="assistiveBold" color="primary.main">
                      Resend
                    </Typography>
                  </Button>
                </Stack>
              </Stack>
              <Grid justifyContent="space-between" container>
                <Grid item>
                  <Button
                    variant="text"
                    type="button"
                    sx={{ color: 'primary' }}
                    onClick={() => navigate(path, { state: { email } })}
                  >
                    <Typography variant="label2"> Go Back </Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button type="submit" loading={loading} sx={{ minWidth: '80px' }}>
                    <Typography variant="label2"> Next </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Card>
      </form>
    </Stack>
  );
};

export default VerificationPage;
