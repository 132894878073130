import { Link, Stack, Typography } from '@mui/material';

import type { CardProps } from '../common/Card';
import Icon from '../common/Icon';
import StyledCard from '../common/StyledCard';

interface ResourceLink {
  label: string;
  href: string;
}

interface FAQsProps {
  links: ResourceLink[];
  cardProps?: CardProps;
}

/**
 * Renders a FAQs component.
 *
 * @param links - An array of objects containing texts for questions and href properties for each link.
 * @param cardProps - Props to be passed to the StyledCard component.
 */
const FAQs = ({ links, cardProps }: FAQsProps) => (
  <Stack gap={2.5}>
    <Typography variant="h3">FAQ</Typography>
    <StyledCard {...cardProps}>
      <Stack gap={1.5}>
        {links.map(({ label, href }) => (
          <Link
            key={label}
            href={href}
            target="_blank"
            rel="noreferrer"
            variant="label2"
            color="text.secondary"
            sx={{
              '&:hover': {
                color: 'primary.main',
              },
              '.hover-icon': {
                opacity: 0,
              },
              '&:hover .hover-icon': {
                opacity: 1,
                transform: 'translateX(5px)',
              },
            }}
          >
            {label}
            <Icon
              className="hover-icon fi fi-rr-arrow-up-right"
              fontSize="10px"
              color="primary.main"
              style={{ display: 'inline-flex' }}
            />
          </Link>
        ))}
      </Stack>
    </StyledCard>
  </Stack>
);

export default FAQs;
