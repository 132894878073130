import { useLocation, useNavigate } from 'react-router-dom';

import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Stack,
  Typography,
} from '@mui/material';
import type { CSSObject, Theme } from '@mui/material/styles';
import { styled, useTheme } from '@mui/material/styles';

import RoutePaths from '../../../routes/RoutePaths';
import { PlanTypes } from '../../../services/billing';
import { LoggerEvents, logEvent } from '../../../services/log';
import { useAccountStatusStore } from '../../../stores/AccountStatusStore';
import { useSideBarStore } from '../../../stores/SideBarStore';
import Button from '../../common/Button';
import Icon from '../../common/Icon';
import { drawerWidth, feedbackUrl } from '../../common/constants';
import UpgradePlanCard from './UpgradePlanCard';

const sidebarItems = [
  {
    name: 'Job Matches',
    icon: 'fi fi-rr-exchange',
    path: RoutePaths.JOBMATCHES,
  },
  {
    name: 'Jobs',
    icon: 'fi fi-rr-briefcase',
    path: RoutePaths.JOBS,
  },
  {
    name: 'Resumes',
    icon: 'fi fi-rr-document',
    path: RoutePaths.RESUMES,
  },
];

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const SideBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { accountStatus } = useAccountStatusStore();
  const subscription = accountStatus?.subscription;

  const isLinkActive = (path: string) => location.pathname.includes(path);

  const { isOpen, toggle: toggleSideBarDrawer } = useSideBarStore();

  const handleClickFeeback = () => {
    logEvent(LoggerEvents.FEEDBACK_CLICK);
    window.open(feedbackUrl, '_blank');
  };

  const onUpgradeClick = () => {
    navigate(`${RoutePaths.BILLING}?action=upgrade`);
  };

  return (
    <Drawer
      className="desktop-drawer"
      variant={isOpen ? 'persistent' : 'permanent'}
      anchor="left"
      open={isOpen}
    >
      <DrawerHeader>
        <IconButton onClick={toggleSideBarDrawer}>
          {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <Stack justifyContent="space-between" height="100%" my="24px" px="12px">
        <List sx={{ padding: '0px' }}>
          {sidebarItems.map((item) => (
            <ListItem
              key={item.name}
              disablePadding
              sx={{
                display: 'block',
                mb: '16px',
              }}
              onClick={() => navigate(item.path)}
            >
              <ListItemButton
                sx={{
                  minHeight: 36,
                  justifyContent: isOpen ? 'initial' : 'center',
                  backgroundColor: isLinkActive(item.path) ? 'primary.light' : 'transparent',
                  borderRadius: '8px',
                  padding: '8px',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    maxHeight: '20px',
                    mr: isOpen ? '10px' : 'auto',
                    justifyContent: 'center',
                    backgroundColor: isLinkActive(item.path) ? 'primary.light' : 'transparent',
                    borderRadius: '8px',
                  }}
                >
                  {item.icon && (
                    <Icon
                      className={item.icon}
                      color={
                        isLinkActive(item.path)
                          ? theme.palette.primary.main
                          : theme.palette.text.secondary
                      }
                      fontSize="20px"
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{ variant: isLinkActive(item.path) ? 'label2' : 'body3' }}
                  primary={item.name}
                  sx={{
                    opacity: isOpen ? 1 : 0,
                    color: isLinkActive(item.path) ? 'primary.main' : 'text.primary',
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Stack gap={2}>
          {(subscription === PlanTypes.STANDARD || subscription === PlanTypes.TRIAL) && (
            <UpgradePlanCard isOpen={isOpen} onUpgradeClick={onUpgradeClick} />
          )}

          <Button
            variant="text"
            onClick={handleClickFeeback}
            sx={{
              minHeight: 36,
              minWidth: 0,
              justifyContent: isOpen ? 'initial' : 'center',
              padding: '8px 12px',
              color: 'text.primary',
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }}
          >
            <Icon
              className="fi fi-rr-shield-interrogation"
              color={theme.palette.text.secondary}
              fontSize="20px"
            />
            {isOpen && (
              <Typography variant="body3" sx={{ ml: isOpen ? '' : 'auto' }}>
                Feedback
              </Typography>
            )}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default SideBar;
