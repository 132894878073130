import { isNotNil } from 'ramda';

import { Stack, Typography, useTheme } from '@mui/material';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import BootstrapTooltip from './BootstrapTooltip';
import Card, { type CardProps } from './Card';
import DefaultDisabledTooltipContent from './DefaultDisabledTooltipContent';
import Icon from './Icon';

/**
 * A reusable component that displays a card containing an icon, a heading,
 * and an optional description. It is designed with responsive behavior, showing or hiding
 * the description based on the device type (mobile, tablet, or desktop).
 *
 * @component
 *
 * @param {Object} cardContent - Defines the content of the card
 *
 * @param {string} cardContent.key - The key for the card
 * @param {string} cardContent.icon - The class name for the icon to be displayed in the card
 * @param {string} cardContent.heading - The heading text for the card
 * @param {string} cardContent.description - An optional description for the card, visible on tablet and desktop screens
 * @param {boolean} enableDescription - An optional flag to enable the description. Defaults to true on tablet and desktop screens
 * @param {function} onClick - The function to be called when the card is clicked
 * @param {boolean} disabled - An optional flag to disable the card
 * @param {boolean} showDisabledTooltip - An optional flag to show a tooltip when the card is disabled
 * @param {string | React.ReactNode} disabledTooltipContent - An optional tooltip content to display when the card is disabled
 *
 * @returns {JSX.Element} A card component with an icon, heading, and optional description
 *
 * @example
 * // Usage example
 * const cardData = {
 *   key: 'blank-resume',
 *   icon: 'fi-rr-add-document',
 *   heading: 'Add Blank Resume',
 *   description: 'Build a resume from scratch',
 * };
 *
 * <SelectCard cardContent={cardData} onClick={onClick}/>
 *
 * ### Usage
 * This component can be used when you need a card that represents an option or feature
 * in a selection list. It supports custom icons, headings, and descriptions, and can be
 * customized further with additional styles via the `sx` and `rootSx` props.
 */

interface SelectCardProps extends Omit<CardProps, 'onClick'> {
  cardContent: {
    key: string;
    icon: string;
    heading: string;
    description?: string;
  };
  enableDescription?: boolean;
  disabled?: boolean;
  showDisabledTooltip?: boolean;
  disabledTooltipContent?: string | React.ReactNode;
  onClick: (key: string) => void;
}

const SelectCard = ({
  sx,
  rootSx,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onClick = (key: string) => {},
  cardContent = {
    key: '',
    icon: '',
    heading: '',
  },
  enableDescription,
  disabled,
  showDisabledTooltip = false,
  disabledTooltipContent = <DefaultDisabledTooltipContent />,
  cardContentProps,
  ...restProps
}: SelectCardProps) => {
  const theme = useTheme();
  const { isDesktop, isTablet } = useResponsiveDevice();
  const shouldEnableDescription = isNotNil(enableDescription)
    ? enableDescription
    : isDesktop || isTablet;

  return (
    <Card
      className={disabled ? 'card-disabled' : ''}
      sx={{
        borderRadius: '12px',
        backgroundColor: disabled ? 'background.mediumLight' : 'common.white',
        '.card-disabled': {
          borderColor: `${theme.palette.grey[300]} !important`,
        },
        '&:hover': {
          backgroundColor: disabled ? 'background.mediumLight' : 'primary.light',
          cursor: disabled ? 'not-allowed' : 'pointer',
        },
        ...sx,
      }}
      cardContentProps={{
        ...cardContentProps,
        sx: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 2,
          wordBreak: 'break-word',
          padding: 3,
          '&:last-child': {
            paddingBottom: 3,
          },
          ...cardContentProps?.sx,
        },
      }}
      rootSx={{ height: '100%', ...rootSx }}
      onClick={() => onClick(cardContent.key)}
      {...restProps}
    >
      <Stack
        sx={{
          width: '3rem',
          height: '3rem',
          minWidth: '2.5rem',
          minHeight: '2.5rem',
          borderRadius: '50%',
          background: disabled ? theme.palette.background.main : theme.palette.primary.light,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Icon
          className={disabled ? 'fi fi-rr-lock ' : cardContent.icon}
          style={{
            color: disabled ? theme.palette.text.tertiary : theme.palette.primary.main,
            fontSize: '24px',
          }}
        />
      </Stack>

      <BootstrapTooltip
        title={disabled && showDisabledTooltip ? disabledTooltipContent : ''}
        placement="top"
      >
        <Stack sx={{ gap: 0.25 }}>
          <Typography variant="label1">{cardContent.heading}</Typography>
          {cardContent.description && shouldEnableDescription && (
            <Typography variant="assistive">{cardContent.description}</Typography>
          )}
        </Stack>
      </BootstrapTooltip>
    </Card>
  );
};

export default SelectCard;
