import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Grid2 from '@mui/material/Unstable_Grid2';
import { useQuery } from '@tanstack/react-query';
import { useToggle } from '@uidotdev/usehooks';

import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { useManageSubscriptionModal } from '../../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import { useSnackbar } from '../../../contexts/snackbar';
import RoutePaths from '../../../routes/RoutePaths';
import type { Receipt, SubscriptionPlan } from '../../../services/billing';
import {
  BillingKeys,
  getCurrentSubscription,
  getInvoices,
  getManagePaymentMethodSession,
} from '../../../services/billing';
import { getLatestInvoice } from '../../../utils/billingUtils';
import { BillingFaqs } from './BillingFaqs';
import CurrentSubscription from './CurrentSubscription';
import Receipts from './Receipts/Receipts';

/**
 * Renders the billing section of the profile settings page.
 *
 * @returns The JSX element representing the billing section.
 */
const BillingSection: React.FC = () => {
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();

  const { openModal } = useManageSubscriptionModal();

  const [isLoading, toggleLoading] = useToggle(false);

  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');

    if (action === 'upgrade') {
      openModal();
      // remove the query params from the url
      navigate(RoutePaths.BILLING);
    }
  }, [location.search, navigate, openModal]);

  // get current subscription from billing service
  const { data: subscription } = useQuery<SubscriptionPlan>({
    queryKey: [BillingKeys.CURRENT_SUBSCRIPTION_PLAN],
    queryFn: getCurrentSubscription,
  });

  // get invoices from billing service
  const { data: invoices } = useQuery<Receipt[]>({
    queryKey: [BillingKeys.INVOICES],
    queryFn: getInvoices,
  });

  const onUpgradeDowngradeClick = () => {
    openModal();
  };

  const onManagePaymentMethodClick = () => {
    toggleLoading(true);
    // call the service method and open the payment method management page
    getManagePaymentMethodSession()
      .then((session) => {
        toggleLoading(false);
        if (session?.url) {
          window.location.href = session.url;
        }
      })
      .catch(() => {
        toggleLoading(false);
        showSnackbar('error', 'Failed to load payment method management page. Please try again.');
      });
  };

  return (
    <Grid2 container sx={{ width: '100%' }}>
      {isLoading && <LoadingSpinner />}
      <Grid2 xs={12} md={6}>
        <CurrentSubscription
          subscriptionPlan={subscription}
          latestInvoice={getLatestInvoice(invoices)}
          onUpgradeDowngradeClick={onUpgradeDowngradeClick}
          onManagePaymentMethodClick={onManagePaymentMethodClick}
        />
        <Receipts
          receipts={invoices}
          sx={{
            paddingTop: 5,
          }}
        />
      </Grid2>
      <Grid2 xs={12} md={6} sx={{ paddingLeft: { md: 8 }, paddingTop: { xs: 5, md: 0 } }}>
        <BillingFaqs />
      </Grid2>
    </Grid2>
  );
};

export default BillingSection;
