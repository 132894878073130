import http from './http';

export enum LoggerEvents {
  RESUME_DOWNLOAD_FAILURE = 'resume_download_failure',
  RESUME_SUCCESS_FAILURE = 'resume_download_success',
  FEEDBACK_CLICK = 'feedback_clicked',
}

// TODO: check with BE, do we still need this?
// deprecate and phase out

export const logEvent = async (event: string, data: object = {}) => {
  try {
    const res = await http.post('/event_logger/', { event, data });
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
