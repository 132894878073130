import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import FileDropzone from '../../../components/FileDropzone';
import ProgressManager from '../../../components/ProgressManager';
import { ProgressScreenType } from '../../../components/ProgressManager/constants';
import Button from '../../../components/common/Button';
import Logo from '../../../components/common/Logo';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { ResumesKeys, uploadResumeFile } from '../../../services/resumes';
import theme from '../../../theme/theme';
import { isNilOrEmpty } from '../../../utils';
import OnboardingCard from './OnboardingCard';

/**
 * `OnboardingImportResume` component facilitates the onboarding process for importing a resume.
 * It provides a file upload interface and displays a progress tracker upon successful upload.
 * Users can upload their resume to create an AI-powered Resume scan.
 *
 * ## Features:
 * - Allows users to upload a resume file.
 * - Tracks and displays the progress of the resume upload and scan process.
 * - Provides navigation options to go back or proceed with the onboarding flow.
 *
 * @component
 * @returns {JSX.Element} Rendered `OnboardingImportResume` component.
 *
 * @example
 * // Basic usage:
 * <OnboardingImportResume />
 *
 */
const OnboardingImportResume = () => {
  // State management for file uploads and progress
  const [file, setFile] = useState<File>();
  const [resumeId, setResumeId] = useState('');
  const [showProgress, setShowProgress] = useState(false);

  // Contexts and hooks
  const { showSnackbar } = useSnackbar(); // For displaying notifications
  const navigate = useNavigate(); // For navigating within the app
  const { isMobile } = useResponsiveDevice(); // Responsive behavior based on device type
  const queryClient = useQueryClient(); // Query client for cache management

  /**
   * `uploadResume` mutation handles the file upload process.
   *
   * ## On success:
   * - Invalidates the cache for resumes to fetch updated data.
   * - Stores the uploaded resume ID in the state.
   * - Displays the progress screen for the uploaded resume.
   *
   * ## On failure:
   * - Shows an error message in the snackbar.
   */
  const { isLoading: isUploadingResume, mutate: uploadResume } = useMutation({
    retry: 3,
    mutationFn: uploadResumeFile, // Mutation function for uploading the resume
    onSuccess: (resumeData) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // Refresh resume data
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Onboarding Resume Scan Creation started',
      );
      setResumeId(resumeData._id); // Save resume ID
      setShowProgress(true); // Show progress screen
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      // Display error message
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Resume. Please try again.',
      );
    },
  });

  /**
   * Handles navigation back to the previous page in the onboarding flow.
   */
  const onBackClick = () => {
    navigate(-1);
  };

  /**
   * Initiates the resume upload process when the "Create" button is clicked.
   */
  const onCreateClick = (): void => {
    if (file) {
      uploadResume(file);
    }
  };

  return (
    <>
      {showProgress ? (
        /**
         * `ProgressManager` component displays the progress of the resume scan process.
         * Shows a success event upon completion.
         *
         * Props:
         * - `id`: The ID of the uploaded resume.
         * - `progressScreenKey`: Specifies the progress screen type.
         * - `successEventLabel`: Logs the success event in analytics.
         */
        <ProgressManager
          id={resumeId}
          progressScreenKey={ProgressScreenType.AiResumeScan}
          successEventLabel="Onboarding Resume Scan Creation Successful"
        />
      ) : (
        <Box
          sx={{
            padding: { xs: `${theme.spacing(2)} ${theme.spacing(0.5)}`, sm: 2 },
            height: '100%',
            boxSizing: 'border-box',
          }}
        >
          {/* Displays the app logo at the top */}
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <Logo style={{ height: theme.spacing(6.25) }} />
          </Box>
          {/* Container for onboarding content */}
          <OnboardingCard
            rootSx={{ height: `calc(100% - ${theme.spacing(8.25)})` }}
            sx={{ width: '100%', maxWidth: '1000px' }}
            cardContentProps={{ sx: { gap: 4, alignItems: 'unset' } }}
          >
            <Stack sx={{ gap: 4 }}>
              {/* Header Section: Title and description for onboarding */}
              <Stack sx={{ gap: 1.5, width: '100%' }}>
                <Typography variant={isMobile ? 'h2' : 'h1'}>Import Resume</Typography>
                <Typography variant="body2">
                  Import your existing resume, edit, and download in our ATS template.
                </Typography>
              </Stack>
              {/* File dropzone for uploading a resume file */}
              <FileDropzone onFileChange={(inputFile: File | undefined) => setFile(inputFile)} />
            </Stack>

            {/* Action buttons for navigation */}
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                gap: 1.5,
              }}
            >
              {/* Back button to navigate to the previous step */}
              <Button
                variant="outlined"
                onClick={onBackClick}
                sx={{
                  width: { xs: '30%', sm: 'fit-content' },
                  ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
                }}
              >
                Back
              </Button>
              {/* Create button to trigger resume upload */}
              <Button
                onClick={onCreateClick}
                sx={{
                  width: { xs: '70%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
                loading={isUploadingResume}
                disabled={isNilOrEmpty(file)} // Disabled if no file is uploaded
              >
                Import
              </Button>
            </Stack>
          </OnboardingCard>
        </Box>
      )}
    </>
  );
};

export default OnboardingImportResume;
