import { type Theme, useMediaQuery } from '@mui/material';

const useResponsiveDevice = () => {
  const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.between('sm', 'md'));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isMobileOrTablet = isMobile || isTablet;

  return { isMobile, isTablet, isDesktop, isMobileOrTablet };
};

export default useResponsiveDevice;
