import { isAfter } from 'date-fns';

import type { SxProps } from '@mui/material';
import { Stack, Typography } from '@mui/material';

import type { Receipt } from '../../../../services/billing';
import { isPaymentGracePeriodPassed } from '../../../../utils/billingUtils';
import NoReceiptsContent from './NoReceiptsContent';
import ReceiptContent from './ReceiptContent';

interface ReceiptsProps {
  receipts?: Receipt[];
  sx?: SxProps;
}

/**
 * Renders the receipts section of the profile settings page.
 *
 * @returns The JSX element representing the receipts section.
 */
const Receipts = ({ receipts = [], sx }: ReceiptsProps) => (
  <Stack
    sx={{
      gap: 2.5,
      width: '100%',
      ...sx,
    }}
  >
    <Typography variant="h3">Receipts</Typography>
    {receipts.length ? (
      <Stack>
        {receipts.map((receipt) => (
          <ReceiptContent
            key={receipt.id}
            receipt={receipt}
            isGracePeriodPassed={isPaymentGracePeriodPassed(receipt, receipts)}
            hasNewInvoice={receipts.some((r) =>
              isAfter(new Date(r.invoiceDate), new Date(receipt.invoiceDate)),
            )}
          />
        ))}
      </Stack>
    ) : (
      <NoReceiptsContent />
    )}
  </Stack>
);

export default Receipts;
