import type { FC } from 'react';

import { Stack } from '@mui/material';
import { useToggle } from '@uidotdev/usehooks';

import Modal from '../../components/common/Modal/Modal';
import ModalDetail from '../../components/common/Modal/ModalDetail';
import type { StripePlan, SubscriptionPlan } from '../../services/billing';
import { PlanTypes } from '../../services/billing';
import DowngradeSubscriptionContent from './DowngradeSubscriptionContent';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import { subscriptionPlansStatic } from './subscriptionPlansStatic';

interface ManageSubscriptionModalProps {
  isOpen: boolean;
  subscription?: SubscriptionPlan;
  subscriptionPlans?: StripePlan[];
  onClose: () => void;
  onUpdateConfirm?: (price_id: string) => void;
  onDowngradeConfirm?: (reason: string) => void;
  onResubscribeConfirm?: (sub_id: string) => void;
}

/**
 * ManageSubscriptionModal component allows users to manage their subscription plans.
 * It provides options to update, downgrade, or resubscribe to a subscription plan.
 *
 * @param {boolean} isOpen - Determines if the modal is open.
 * @param {Subscription} subscription - The current subscription details.
 * @param {subscriptionPlans} subscriptionPlans - The subscription plans available on stripe.
 * @param {() => void} onClose - Callback function to handle modal close event.
 * @param {(priceId: string) => void} onUpdateConfirm - Callback function to handle subscription update confirmation.
 * @param {(reason: string) => void} onDowngradeConfirm - Callback function to handle subscription downgrade confirmation.
 * @param {(subscriptionId: string) => void} onResubscribeConfirm - Callback function to handle resubscribe confirmation.
 *
 * @returns {JSX.Element} The rendered ManageSubscriptionModal component.
 */
const ManageSubscriptionModal: FC<ManageSubscriptionModalProps> = ({
  isOpen,
  subscription,
  subscriptionPlans,
  onClose,
  onUpdateConfirm,
  onDowngradeConfirm,
  onResubscribeConfirm,
}) => {
  const [showDowngradeContent, toggleShowDowngradeContent] = useToggle(false);

  const onUpdateConfirmClick = () => {
    if (onUpdateConfirm && subscriptionPlans) {
      const proPlanPriceId = subscriptionPlans?.find(
        (plan) => plan.name === PlanTypes.PRO,
      )?.price_id;

      if (proPlanPriceId) {
        onUpdateConfirm(proPlanPriceId);
      }
    }
  };

  const onDowngradeClick = () => {
    toggleShowDowngradeContent(true);
  };

  const onResubscribeClick = () => {
    if (onResubscribeConfirm && subscription) {
      onResubscribeConfirm(subscription.id);
    }
  };

  const onCloseEvent = () => {
    toggleShowDowngradeContent(false);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseEvent}
      contentSx={{
        width: 'max-content',
      }}
    >
      <ModalDetail
        title={
          !showDowngradeContent
            ? 'Manage Subscription'
            : 'Are you sure you want to cancel your subscription?'
        }
        subtitle={
          !showDowngradeContent ? 'Unlock unlimited scans, job match scores & more...' : undefined
        }
        onClose={!showDowngradeContent ? onCloseEvent : undefined}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            gap: 2,
            pb: 0.2,
            transition: 'all 0.3s ease',
          }}
        >
          {!showDowngradeContent &&
            subscriptionPlansStatic.map((plan) => (
              <SubscriptionPlanCard
                key={plan.id}
                plan={{
                  ...plan,
                  amount:
                    Number(subscriptionPlans?.find((p) => p.name === plan.planName)?.amount) || 0,
                }}
                subscription={subscription}
                onUpdateConfirmClick={onUpdateConfirmClick}
                onDowngradeClick={onDowngradeClick}
                onResubscribeClick={onResubscribeClick}
              />
            ))}

          {showDowngradeContent && (
            <DowngradeSubscriptionContent
              nextInvoiceDate={subscription?.nextInvoiceDate}
              onback={() => toggleShowDowngradeContent(false)}
              onDowngradeConfirm={(reason: string) => {
                if (onDowngradeConfirm) {
                  onCloseEvent();
                  onDowngradeConfirm(reason);
                }
              }}
            />
          )}
        </Stack>
      </ModalDetail>
    </Modal>
  );
};

export default ManageSubscriptionModal;
