import { useEffect, useState } from 'react';
import Confetti from 'react-confetti';

import { Box, Stack, Typography } from '@mui/material';

import Button from '../../../components/common/Button';
import Carousel from '../../../components/common/Carousel';
import { useAuthStore } from '../../../stores/AuthStore';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { slidesContent } from '../constants';
import OnboardingCard from './OnboardingCard';

interface WelcomeProps {
  onGetStartedClick: () => void;
}

// TODO: generalize Confetti
const ConfettiColors = ['#00B050', '#007435', '#F4C5C8', '#228EF2', '#7030A0', '#D67319'];

/**
 * `Welcome` displays a welcome message, confetti animation, and an onboarding carousel, guiding new users
 * through the platform's features. The component includes a "Get Started" button to proceed with onboarding.
 *
 * This component displays the user's first name, if available, and includes a `Carousel` to guide users through
 * onboarding slides. Confetti animates on load, adding an engaging welcome effect for new users. The confetti animation
 * displays customized colors, gravity, and duration, stopping automatically after 4 seconds to allow pieces to gracefully fall.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {() => void} props.onGetStartedClick - Callback function triggered when the "Get Started" button is clicked.
 * @returns {JSX.Element} - The rendered Welcome component.
 *
 * @example
 * // Example usage of the Welcome component
 * const handleGetStarted = () => {
 *   console.log("User clicked Get Started!");
 * };
 *
 * <Welcome onGetStartedClick={handleGetStarted} />
 *
 */
const Welcome = ({ onGetStartedClick }: WelcomeProps) => {
  const { user } = useAuthStore();
  const { isMobile } = useResponsiveDevice();

  const [confettiPieces, setConfettiPieces] = useState(0);

  const getUserName = () => {
    if (user?.name) {
      const userName = user.name.split(' ');
      if (userName.length > 2) {
        return `${userName[0]} ${userName[1]}`;
      }
      return userName[0];
    }
    return '';
  };

  useEffect(() => {
    setConfettiPieces(200);
    // after 7 seconds, set confetti pieces to 0, so they can gracefully fall away
    // and new pieces are not generated
    const timer = setTimeout(() => {
      setConfettiPieces(0);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box>
      <Confetti
        numberOfPieces={confettiPieces}
        tweenDuration={500}
        colors={ConfettiColors}
        initialVelocityY={8}
        gravity={0.08}
        style={{ width: '100%', height: '100%' }}
      />
      <OnboardingCard cardContentProps={{ sx: { maxWidth: '40rem' } }}>
        <Stack sx={{ gap: { xs: 1.5, sm: 4 }, width: '100%' }}>
          <Typography
            variant={isMobile ? 'h2' : 'h1'}
            sx={{ textAlign: { xs: 'left', sm: 'center' } }}
          >
            Welcome to JobQuest{user?.name !== '' ? `, ${getUserName()}!` : ''}
          </Typography>
          <Stack sx={{ gap: 3, width: '100%' }}>
            <Typography variant="body3">
              We&apos;re excited to guide you through your job-seeking journey. From personalized
              job matches to AI-generated resumes, JobQuest is here to make your next career move
              easier than ever.
            </Typography>
            <Carousel slides={slidesContent} />
          </Stack>
        </Stack>
        <Button
          onClick={onGetStartedClick}
          disabled={confettiPieces > 0}
          sx={{ marginTop: 3, ...(isMobile ? { width: '100%', padding: 2 } : {}) }}
        >
          Get Started
        </Button>
      </OnboardingCard>
    </Box>
  );
};

export default Welcome;
