import { useCallback, useMemo } from 'react';

import { Stack, Typography, useTheme } from '@mui/material';

import Button from '../../components/common/Button';
import Card from '../../components/common/Card';
import Icon from '../../components/common/Icon';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { PlanTypes, type SubscriptionPlan } from '../../services/billing';
import { getPlanName } from '../../utils/billingUtils';

// Adjust the import path as needed

interface Feature {
  label: string;
  subText: string;
}

interface Plan {
  id: string;
  planName: string;
  amount: number;
  features: Feature[];
}

interface SubscriptionPlanCardProps {
  plan: Plan;
  subscription: SubscriptionPlan | undefined;
  onDowngradeClick: () => void;
  onUpdateConfirmClick: () => void;
  onResubscribeClick?: () => void;
}

/**
 * SubscriptionPlanCard component renders a card displaying subscription plan details.
 * It includes plan name, amount, features, an action button to upgrade or downgrade the plan, and a button to resubscribe if the plan is cancelled.
 *
 * @component
 * @param {SubscriptionPlanCardProps} props - The properties for the SubscriptionPlanCard component.
 * @param {Plan} props.plan - The subscription plan details.
 * @param {Subscription} [props.subscription] - The current subscription details, if any.
 * @param {Function} props.onDowngradeClick - Callback function to handle downgrade action.
 * @param {Function} props.onUpdateConfirmClick - Callback function to handle upgrade action.
 * @param {Function} [props.onResubscribeClick] - Callback function to handle resubscribe action.
 *
 * @returns {JSX.Element} The rendered SubscriptionPlanCard component.
 */
const SubscriptionPlanCard = ({
  plan,
  subscription,
  onDowngradeClick,
  onUpdateConfirmClick,
  onResubscribeClick,
}: SubscriptionPlanCardProps) => {
  const theme = useTheme();
  const { isMobileOrTablet } = useResponsiveDevice();

  const getButtonText = (planName: string) => {
    if (planName === PlanTypes.STANDARD) {
      return 'Forever';
    }

    if (!subscription) {
      return planName === PlanTypes.PRO ? 'Subscribe' : 'Forever';
    }

    if (subscription && subscription.planName === PlanTypes.PRO) {
      if (subscription.isPlanCancelled) {
        return 'Resubscribe';
      }

      return 'Cancel Subscription';
    }

    return 'Subscribe';
  };

  const getButtonColor = useCallback(
    (planName: string) => {
      if (planName === PlanTypes.PRO) {
        return subscription?.planName === PlanTypes.PRO && !subscription?.isPlanCancelled
          ? 'error.main'
          : 'common.white';
      }

      return subscription?.planName === PlanTypes.PRO ? 'text.primary' : 'border.light';
    },
    [subscription?.isPlanCancelled, subscription?.planName],
  );

  const getButtonBgColor = useCallback(
    (planName: string) => {
      if (planName === PlanTypes.PRO) {
        return subscription?.planName === PlanTypes.PRO && !subscription?.isPlanCancelled
          ? 'common.white'
          : 'primary.main';
      }

      return 'common.white';
    },
    [subscription?.isPlanCancelled, subscription?.planName],
  );

  const getButtonHoverBgColor = useCallback(
    (planName: string) => {
      if (planName === PlanTypes.PRO) {
        if (subscription?.isPlanCancelled) {
          return 'primary.dark';
        }

        return subscription?.planName === PlanTypes.PRO ? 'error.light' : 'border.light';
      }

      return 'border.light';
    },
    [subscription?.isPlanCancelled, subscription?.planName],
  );

  const ButtonStyles = useMemo(
    () => ({
      mt: 1.5,
      mb: 1.5,
      color: getButtonColor(plan.planName),
      backgroundColor: getButtonBgColor(plan.planName),
      borderColor: 'border.light',
      borderWidth: '1x',
      ':hover': {
        borderColor: 'border.light',
        borderWidth: '1x',
        backgroundColor: getButtonHoverBgColor(plan.planName),
      },
      '&.Mui-disabled': {
        backgroundColor: 'common.white !important',
      },
    }),
    [getButtonBgColor, getButtonColor, getButtonHoverBgColor, plan.planName],
  );

  const CardButtonCallbackFn = useMemo(() => {
    if (plan.planName === PlanTypes.PRO) {
      if (subscription?.isPlanCancelled) {
        return onResubscribeClick;
      }

      return subscription?.planName === PlanTypes.PRO ? onDowngradeClick : onUpdateConfirmClick;
    }
    return undefined;
  }, [
    onDowngradeClick,
    onResubscribeClick,
    onUpdateConfirmClick,
    plan.planName,
    subscription?.isPlanCancelled,
    subscription?.planName,
  ]);

  return (
    <Card
      key={plan.id}
      {...(isMobileOrTablet ? { rootSx: { marginX: 1 } } : {})} // Add rootSx conditionally
      sx={{
        borderRadius: 1.5,
        width: '100%',
        height: '100%',
      }}
    >
      <Stack>
        <Typography variant="body2">{getPlanName(plan.planName)}</Typography>
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="h1">${plan.amount}</Typography>
          <Typography variant="body3" color="text.primaryGray" paddingBottom={0.375}>
            /month
          </Typography>
        </Stack>
        <Button
          variant={
            plan.planName === PlanTypes.PRO && subscription?.planName !== PlanTypes.PRO
              ? 'contained'
              : 'outlined'
          }
          fullWidth
          sx={ButtonStyles}
          disabled={plan.planName === PlanTypes.STANDARD}
          onClick={CardButtonCallbackFn}
        >
          {/* {plan.planName !== PlanTypes.PRO ? 'Current Plan' : 'Upgrade'} */}
          {getButtonText(plan.planName)}
        </Button>

        {/* features */}
        <Stack gap={1.5}>
          <Typography variant="label2">
            {plan.planName !== PlanTypes.PRO ? 'Core features' : 'Everything in free, plus'}
          </Typography>
          {plan.features.map((feature) => (
            <Stack key={feature.label} direction="row" gap={1}>
              <Icon
                className="fi fi-br-check"
                fontSize="16px"
                style={{
                  color:
                    plan.planName !== PlanTypes.PRO
                      ? theme.palette.text.tertiary
                      : theme.palette.primary.main,
                }}
              />
              <Stack>
                <Typography variant="body3">{feature.label}</Typography>
                <Typography variant="assistive" color="text.primaryGray">
                  {feature.subText}
                </Typography>
              </Stack>
            </Stack>
          ))}

          {plan.planName === PlanTypes.PRO && (
            <Typography variant="assistive" color="text.tertiary" ml={2.8}>
              Cancel anytime
            </Typography>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default SubscriptionPlanCard;
