import { useMemo } from 'react';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import Button, { type ButtonProps } from './Button';
import ButtonWithTooltip from './ButtonWithTooltip';
import Icon from './Icon';

const AiCustomizeButton = ({ onClick, ...restProps }: ButtonProps) => {
  const { isAICustomizeResumeEnabled } = useAccountStatusStore();
  const { isMobile } = useResponsiveDevice();

  const featureLockedButtonStyles = useMemo(
    () => ({
      ...(!isAICustomizeResumeEnabled && {
        backgroundColor: 'background.mediumLight',
        color: 'text.tertiary',
        ':hover': {
          borderWidth: 0,
          backgroundColor: 'background.main',
        },
      }),
    }),
    [isAICustomizeResumeEnabled],
  );

  const buttonContent = useMemo(
    () => (
      <>
        <Icon
          className={`fi ${isAICustomizeResumeEnabled ? 'fi-rr-magic-wand' : 'fi-rr-lock'}`}
          fontSize="20px"
        />
        AI Customize
      </>
    ),
    [isAICustomizeResumeEnabled],
  );

  return isMobile ? (
    <Button
      sx={{
        width: { xs: '100%', sm: 'fit-content' },
        padding: { xs: '16px 12px', sm: '8px 12px' },
        ...featureLockedButtonStyles,
      }}
      onClick={onClick}
      {...restProps}
    >
      {buttonContent}
    </Button>
  ) : (
    <ButtonWithTooltip
      sx={{
        width: { xs: '100%', sm: 'fit-content' },
        padding: { xs: '16px 12px', sm: '8px 12px' },
        ...featureLockedButtonStyles,
      }}
      disabled={!isAICustomizeResumeEnabled}
      showDisabledTooltip={!isAICustomizeResumeEnabled && !isMobile}
      onClick={onClick}
      {...restProps}
    >
      {buttonContent}
    </ButtonWithTooltip>
  );
};

export default AiCustomizeButton;
