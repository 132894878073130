import { CircularProgress, Button as MUIButton, Typography } from '@mui/material';
import type { ButtonProps as MUIButtonProps } from '@mui/material';

import theme from '../../theme/theme';

/**
 * Custom button component that extends Material-UI's Button with additional features:
 * - A loading state indicator using CircularProgress.
 * - Support for optional start and trailing icons.
 * - A disabled state that visually disables the button.
 *
 * @param {ButtonProps} props - The props for the custom button component.
 * @param {boolean} [props.loading=false] - Whether the button is in a loading state. If true, the button will be disabled and a CircularProgress spinner is displayed with button text.
 * @param {React.ReactNode} [props.startIcon] - Optional icon to be displayed before the button text.
 * @param {React.ReactNode} [props.trailingIcon] - Optional icon to be displayed after the button text.
 * @param {boolean} [props.disabled=false] - Whether the button is disabled. The button will not be clickable and will have a visually disabled style when true.
 * @param {string} [props.variant='contained'] - The variant of the button, determines the style of the button (e.g., 'contained', 'outlined').
 * @param {object} [props.sx] - Optional style prop to override the default styles.
 * @param {...MUIButtonProps} [restProps] - Any other props passed to the Material-UI Button component, such as onClick, onMouseEnter, etc.
 *
 * @returns {JSX.Element} The rendered button component.
 *
 * @component
 * @example
 * // Example usage:
 * <Button loading={true} onClick={handleClick}>Submit</Button>
 *
 */
export interface ButtonProps extends MUIButtonProps {
  loading?: boolean; // Flag to show a loading spinner in place of button text
  trailingIcon?: React.ReactNode; // Optional icon to be displayed after the button text
  startIcon?: React.ReactNode; // Optional icon to be displayed before the button text
  disabled?: boolean; // Flag to disable the button
}

const Button = ({
  variant = 'contained',
  loading,
  children,
  disabled = false,
  sx,
  startIcon,
  trailingIcon,
  ...restProps
}: ButtonProps) => {
  // Prepare the button content with icons and loading spinner if needed
  const buttonContent = (
    <Typography
      sx={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
      variant="label2"
    >
      {/* Display startIcon if provided */}
      {startIcon && startIcon}

      {/* Show loading spinner if loading flag is true */}
      {loading && <CircularProgress sx={{ color: 'inherit' }} size={20} />}

      {/* Display button text or children */}
      {children}

      {/* Display trailingIcon if provided */}
      {trailingIcon && trailingIcon}
    </Typography>
  );

  return (
    <MUIButton
      disabled={disabled || loading} // Disable button if either disabled or loading is true
      variant={variant} // Use the variant passed in props (contained, outlined, etc.)
      sx={{
        padding:
          variant === 'outlined'
            ? `${theme.spacing(0.875)} ${theme.spacing(1.5)}`
            : `${theme.spacing(1)} ${theme.spacing(1.5)}`, // Adjust padding based on the variant
        minWidth: theme.spacing(10), // Set minimum width for the button
        textTransform: 'capitalize', // Capitalize the button text
        color: variant !== 'contained' ? 'primary.main' : 'common.white', // Set text color based on variant
        borderRadius: theme.spacing(1), // Set border radius for rounded corners
        borderWidth: variant === 'outlined' ? theme.spacing(0.25) : 0, // Border width for outlined buttons
        ':hover': {
          borderWidth: variant === 'outlined' ? theme.spacing(0.25) : 0, // Border on hover for outlined variant
        },
        '&.Mui-disabled': { backgroundColor: 'background.main' }, // Adjust background color for disabled state
        ...sx, // Allow additional styling through the sx prop
      }}
      {...restProps} // Spread the remaining props (e.g., onClick, etc.)
    >
      {buttonContent} {/* Render the button content */}
    </MUIButton>
  );
};

export default Button;
