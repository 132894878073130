import { useMemo } from 'react';

import { Stack, Typography, useTheme } from '@mui/material';

import StringConcatenator from '../../../../components/ResumeTemplate/components/StringConcatenator';
import Button from '../../../../components/common/Button';
import Icon from '../../../../components/common/Icon';
import type { Receipt } from '../../../../services/billing';
import { isNotNilOrEmpty } from '../../../../utils';
import { StripeInvoiceStatus, formatBillingDate } from '../../../../utils/billingUtils';

interface ReceiptContentProps {
  receipt: Receipt;
  key: string;
  isGracePeriodPassed: boolean;
  hasNewInvoice: boolean;
}

/**
 * Component to display the content of a receipt.
 *
 * @param {Object} props - The properties object.
 * @param {Receipt} props.receipt - The receipt object containing details like invoice date, paid date, and URL.
 * @param {string} props.key - The unique key for the receipt item.
 * @param {boolean} props.isGracePeriodPassed - Indicates whether the subscription payment grace period has passed.
 * @param {boolean} props.hasNewInvoice - Indicates whether new invoice is present.
 *
 * @returns {JSX.Element} The JSX element representing the receipt content.
 */
const ReceiptContent = ({
  receipt,
  key,
  isGracePeriodPassed = false,
  hasNewInvoice = false,
}: ReceiptContentProps) => {
  const { invoiceDate, paidDate, url, status } = receipt;
  const theme = useTheme();

  const invoiceStatusText = useMemo(() => {
    switch (status) {
      case StripeInvoiceStatus.Paid:
        return (
          <Typography variant="assistive" sx={{ color: 'text.secondary' }}>
            {`Paid: ${formatBillingDate(paidDate)}`}
          </Typography>
        );
      case StripeInvoiceStatus.Draft:
        return (
          <Typography variant="assistive" sx={{ color: 'primary.mediumDark' }}>
            Upcoming Payment
          </Typography>
        );
      default:
        return (
          <Typography variant="assistive" sx={{ color: 'error.main' }}>
            <StringConcatenator
              value1="Payment Failed"
              value2={
                isGracePeriodPassed
                  ? `Your subscription ${hasNewInvoice ? 'was' : 'is'} downgraded to Free.`
                  : ''
              }
              value1Style={{ margin: 0 }}
              value2Style={{ color: theme.palette.text.secondary }}
              separator=" - "
            />
          </Typography>
        );
    }
  }, [status, paidDate, isGracePeriodPassed, hasNewInvoice, theme]);

  return (
    <Stack
      key={key}
      sx={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '20px 12px',
        borderBottom: '1px solid',
        borderColor: 'border.light',
        ':hover': {
          backgroundColor: 'background.mediumLight',
          '.hover-icon': {
            visibility: 'visible',
          },
        },
      }}
    >
      <Stack gap={0.5}>
        <Typography variant="body3">Invoiced: {formatBillingDate(invoiceDate)}</Typography>
        {invoiceStatusText}
      </Stack>
      {isNotNilOrEmpty(url) && (
        <Button
          className="hover-icon"
          variant="text"
          onClick={() => window.open(url, '_blank', 'noreferrer')}
          sx={{
            height: 36,
            width: 36,
            minWidth: 0,
            color: 'text.primary',
            backgroundColor: 'background.mediumLight',
            visibility: 'hidden',
            '&:hover': {
              backgroundColor: 'background.main',
            },
          }}
        >
          <Icon
            className="fi fi-rr-arrow-up-right"
            style={{
              fontSize: theme.spacing(2),
              color: theme.palette.text.tertiary,
              cursor: 'pointer',
            }}
          />
        </Button>
      )}
    </Stack>
  );
};

export default ReceiptContent;
