import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, IconButton, Stack, useTheme } from '@mui/material';

import BootstrapTooltip from '../../../components/common/BootstrapTooltip';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';
import ListingCard from '../../../components/common/ListingCard';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { useManageSubscriptionModal } from '../../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import { ResponseStatus } from '../../../interface';
import RoutePaths from '../../../routes/RoutePaths';
import type { JobDescription } from '../../../services/jobDescriptions';
import { JobProgressSource } from '../../../services/jobDescriptions';
import { useAccountStatusStore } from '../../../stores/AccountStatusStore';
import { useSwipeableDrawerStore } from '../../../stores/SwipeableDrawerStore';
import { addSeparator, capitalize } from '../../../utils';
import { dateToTimeAgo } from '../../../utils/dateUtils';
import CreateNewListing from '../../Create/CreateNewListing';
import { Controls } from './Controls';

interface JobXraysListProps {
  jobXrays: JobDescription[] | undefined;
  isLoading?: boolean;
  onClick?: (
    id: string,
    status: ResponseStatus,
    source: JobProgressSource,
    sourceId: string,
  ) => void;
}

interface RowControl {
  id: string;
  jobCompany: string;
  jobTitle: string;
}

const JobXraysList = ({ jobXrays, isLoading, onClick }: JobXraysListProps) => {
  const { isOpen, toggle: toggleSwipeableDrawer } = useSwipeableDrawerStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const [control, setControl] = useState<RowControl>({ id: '', jobCompany: '', jobTitle: '' });
  const { openModal: openManageSubscriptionModal } = useManageSubscriptionModal();
  const { isJDImportEnabled } = useAccountStatusStore();

  const cardRows = useMemo(() => {
    const rows = jobXrays?.map((jobXray) => ({
      id: jobXray._id,
      status: jobXray.status,
      sourceId: jobXray.source_id || jobXray._id,
      source: jobXray.source || JobProgressSource.JOB_XRAY,
      progress: jobXray.progress,
      lastUpdated: jobXray.updated_at,
      usedForScore: jobXray.used_for_score,
      control: {
        id: jobXray._id || '',
        jobCompany: jobXray.job_company || '',
        jobTitle: jobXray.job_title || '',
      },
      items: [
        {
          icon: 'fi fi-rr-briefcase',
          heading: jobXray.job_company,
          subHeading: jobXray.job_title,
        },
      ],
    }));

    return rows || [];
  }, [jobXrays]);

  const onDeleteClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, rowControl: RowControl): void => {
      e.stopPropagation();
      setControl(rowControl);
      toggleSwipeableDrawer();
    },
    [toggleSwipeableDrawer],
  );

  useEffect(() => {
    // when swipeable drawer is closed, reset control
    if (!isOpen) {
      setControl({ id: '', jobCompany: '', jobTitle: '' });
    }
  }, [isOpen]);

  const CreateJobXrayButton = useMemo(() => {
    const onCreateButtonClick = () => {
      if (isJDImportEnabled) {
        navigate(RoutePaths.CREATE_JOB);
      } else {
        openManageSubscriptionModal();
      }
    };

    return (
      <Button
        sx={{
          padding: `${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(2)} ${theme.spacing(
            2,
          )}`,
          ...(!isJDImportEnabled && {
            color: 'text.secondary',
            paddingRight: 2,
            backgroundColor: 'background.mediumLight',
            ':hover': {
              borderWidth: 0,
              backgroundColor: 'background.main',
            },
          }),
        }}
        onClick={onCreateButtonClick}
      >
        <Icon
          className={`fi ${isJDImportEnabled ? 'fi-rr-plus' : 'fi-rr-lock'}`}
          style={{
            fontSize: theme.spacing(1.625),
            padding: '3.5px',
            ...(!isJDImportEnabled && { color: theme.palette.text.secondary }),
          }}
        />
        Add
      </Button>
    );
  }, [isJDImportEnabled, navigate, openManageSubscriptionModal, theme]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      {cardRows.length > 0 ? (
        <>
          <Stack sx={{ gap: 2, padding: '24px 12px' }}>
            {cardRows.map((row) => (
              <ListingCard
                key={row.id}
                items={row.items}
                description={addSeparator(
                  row.status === ResponseStatus.IN_PROGRESS
                    ? 'In progress'
                    : dateToTimeAgo(row.lastUpdated),
                  capitalize(`${row.usedForScore ? '' : 'Not '}used for Job Matches`),
                  ' • ',
                )}
                // TODO: Create Separete component for delete icon
                control={
                  <BootstrapTooltip title="Delete">
                    <IconButton
                      aria-label="delete icon"
                      size="small"
                      component="button"
                      onClick={(e) => onDeleteClick(e, row.control)}
                    >
                      <Icon className="fi fi-br-trash" color={theme.palette.text.secondary} />
                    </IconButton>
                  </BootstrapTooltip>
                }
                status={row.status}
                progress={row.progress}
                onClick={() => onClick?.(row.id, row.status, row.source, row.sourceId)}
                enableStatus={row.status !== ResponseStatus.DONE}
              />
            ))}
          </Stack>
          <Controls {...control} />
        </>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            height: '100%',
          }}
        >
          <CreateNewListing
            primaryText="Create your first Job"
            secondaryText="Upload your first job description to JobQuest."
            icon="fi fi-br-briefcase"
            action={CreateJobXrayButton}
            sx={{ border: 'none' }}
          />
        </Box>
      )}
    </>
  );
};

export default JobXraysList;
