import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressManager from '../../../components/ProgressManager';
import { ProgressScreenType } from '../../../components/ProgressManager/constants';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { JobDescriptionTypes } from '../../../services/jobDescriptions';
import { GenerateAiCustomizeResume, ResumesKeys, uploadJdResume } from '../../../services/resumes';
import UploadOnboardingProfile from './UploadOnboardingProfile';

/**
 * `OnboardingAiCustomizedResume` component handles the onboarding flow for users
 * aiming to create an AI-customized resume based on a job description and optional resume upload.
 * It manages API requests to upload job descriptions, create customized resumes, and displays progress.
 *
 * @component
 * @returns {JSX.Element} Rendered `OnboardingAiCustomizedResume` component.
 *
 * @example
 * // Example usage:
 * <OnboardingAiCustomizedResume />
 */
const OnboardingAiCustomizedResume = () => {
  const [aiCustomizedResumeId, setAiCustomizedResumeId] = useState('');
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const [showProgress, setShowProgress] = useState(false);
  const queryClient = useQueryClient(); // Query client for cache management

  /**
   * `createAiCustmizedResume` mutation initiate AI customization of a resume after job description and resume are upload.
   * - On success, stores the resume ID and opens the progress screen.
   * - On error, displays an error message in the snackbar.
   */
  const { mutate: createAiCustmizedResume } = useMutation({
    retry: 3,
    mutationFn: GenerateAiCustomizeResume,
    onSuccess: (res) => {
      // Invalidate cache to refresh resume data
      queryClient.invalidateQueries([ResumesKeys.RESUMES]);
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Onboarding AI Customized Resume Creation started',
      );
      setAiCustomizedResumeId(res?._id);
      setShowProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to create AI customized resume. Please try again.',
      );
    },
  });

  /**
   * Handles the upload of a job description and resume, required for creating a match score.
   *
   * @async
   * @function
   * @param {Object} data - Data object containing the job description and optional resume file.
   * @param {string} data.description - Job description text to upload.
   * @param {File} [data.file] - Optional resume file.
   * @returns {Promise<Object>} API response containing job description and resume data.
   */
  const uploadJdResumeQueryFuntion = async ({
    description,
    file,
  }: {
    description: string;
    file?: File;
  }) => {
    const res = await uploadJdResume(description, file as File);
    return res;
  };

  /**
   * `uploadJdResumeData` mutation uploads the job description and resume data.
   *
   * On success, it invokes the `createJobMatch` mutation to initiate match score creation.
   * On error, it handles potential errors by displaying appropriate snackbar messages.
   */
  const { mutate: uploadJdResumeData, isLoading: isJdResumeUploading } = useMutation({
    retry: 3,
    mutationFn: uploadJdResumeQueryFuntion,
    onMutate: () => {
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Upload Job Description & Resume data for onboarding AI customized resume',
      );
    },
    onSuccess: (jdResumeData: { job_description_id: string; resume_id: string }) => {
      // Invalidate cache to refresh job x-rays data
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]);
      createAiCustmizedResume({
        job_description_id: jdResumeData.job_description_id,
        resume_id: jdResumeData.resume_id,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors ||
          'Failed to upload Resume or Job Description. Please try again.',
      );
    },
  });

  /**
   * Handles navigation back to the previous page in the onboarding process.
   */
  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {showProgress ? (
        /**
         * `ProgressManager` displays progress for the resume AI customization process
         * Logs a success event on completion.
         */
        <ProgressManager
          id={aiCustomizedResumeId}
          progressScreenKey={ProgressScreenType.AiCustomize}
          successEventLabel="Onboarding AI customized resume Creation Successful"
        />
      ) : (
        /**
         * `UploadOnboardingProfile` component renders the upload form
         * and triggers `uploadJdResumeData` on form submission.
         */
        <UploadOnboardingProfile
          onCreateClick={uploadJdResumeData}
          onBackClick={onBackClick}
          isLoading={isJdResumeUploading}
          title={isMobile ? 'Tailor your resume to the Target Job' : 'Tailor Resume with AI'}
          description="Fine-tune your resume to match the specifics of the job you're applying for."
          buttonLabel="Tailor"
        />
      )}
    </>
  );
};

export default OnboardingAiCustomizedResume;
