import { type FC, useCallback, useEffect } from 'react';
import Slider from 'react-slick';

import { Box, Divider, Stack, Typography } from '@mui/material';
import { useToggle } from '@uidotdev/usehooks';

import Button from '../../components/common/Button';
import Icon from '../../components/common/Icon';
import Modal from '../../components/common/Modal/Modal';
import SwipeableDrawer, { Puller } from '../../components/common/SwipeableDrawer';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import type { StripePlan, SubscriptionPlan } from '../../services/billing';
import { PlanTypes } from '../../services/billing';
import DowngradeSubscriptionContent from './DowngradeSubscriptionContent';
import SubscriptionPlanCard from './SubscriptionPlanCard';
import { subscriptionPlansStatic } from './subscriptionPlansStatic';

interface MobileManageSubscriptionContentProps {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  subscriptionPlans?: StripePlan[];
  subscription?: SubscriptionPlan;
  onUpdateConfirm?: (price_id: string) => void;
  onDowngradeConfirm?: (reason: string) => void;
  onResubscribeConfirm?: (sub_id: string) => void;
}

/**
 * MobileManageSubscriptionContent component allows users to manage their subscription plans.
 * It provides options to update, downgrade, or resubscribe to a subscription plan.
 *
 * @param {isOpen} isOpen - Determines if the modal is open.
 * @param {() => void} openModal - Callback function to handle modal open event.
 * @param {() => void} closeModal - Callback function to handle modal close event.
 * @param {Subscription} subscription - The current subscription details.
 * @param {subscriptionPlans} subscriptionPlans - The subscription plans available on stripe.
 * @param {(priceId: string) => void} onUpdateConfirm - Callback function to handle subscription update confirmation.
 * @param {(reason: string) => void} onDowngradeConfirm - Callback function to handle subscription downgrade confirmation.
 * @param {(subscriptionId: string) => void} onResubscribeConfirm - Callback function to handle resubscribe confirmation.
 *
 * @returns {JSX.Element} The rendered MobileManageSubscriptionContent component.
 */
const MobileManageSubscriptionContent: FC<MobileManageSubscriptionContentProps> = ({
  isOpen,
  openModal,
  closeModal,
  subscription,
  subscriptionPlans,
  onUpdateConfirm,
  onDowngradeConfirm,
  onResubscribeConfirm,
}) => {
  const [showUpgradeInfo, toggleShowUpgradeInfo] = useToggle(true);
  const [showDowngradeContent, toggleShowDowngradeContent] = useToggle(false);
  const { isMobileOrTablet } = useResponsiveDevice();

  useEffect(() => {
    toggleShowUpgradeInfo(
      !subscription?.isPlanCancelled && subscription?.planName !== PlanTypes.PRO,
    );
  }, [subscription, toggleShowUpgradeInfo]);

  const onUpdateConfirmClick = useCallback(() => {
    if (onUpdateConfirm && subscriptionPlans) {
      const proPlanPriceId = subscriptionPlans?.find(
        (plan) => plan.name === PlanTypes.PRO,
      )?.price_id;

      if (proPlanPriceId) {
        onUpdateConfirm(proPlanPriceId);
      }
    }
  }, [onUpdateConfirm, subscriptionPlans]);

  const onDowngradeClick = useCallback(() => {
    toggleShowDowngradeContent(true);
  }, [toggleShowDowngradeContent]);

  const onResubscribeClick = useCallback(() => {
    if (onResubscribeConfirm && subscription) {
      onResubscribeConfirm(subscription.id);
    }
  }, [onResubscribeConfirm, subscription]);

  const onCloseModal = () => {
    toggleShowUpgradeInfo(
      !subscription?.isPlanCancelled && subscription?.planName !== PlanTypes.PRO,
    );
    closeModal();
  };

  return (
    <Box>
      {showUpgradeInfo && (
        <SwipeableDrawer open={isOpen} onClose={closeModal} onOpen={openModal}>
          <Stack gap={1}>
            <Stack alignItems="center" mb={1}>
              <Puller />
            </Stack>
            <Typography variant="h3" textAlign="left">
              Upgrade to JobQuest Pro
            </Typography>
            <Typography variant="body3" textAlign="left" color="text.primary">
              Upgrade now to unlock unlimited Resume Scans, Job Match Scores, and Job X-Rays, giving
              your job search the ultimate edge.
            </Typography>
            <Typography variant="body3" textAlign="left" color="text.primary">
              Enjoy AI-driven resume customization and the Super Editor, designed to help you create
              a standout resume that grabs attention and opens doors to new opportunities.
            </Typography>

            <Button
              variant="contained"
              fullWidth
              onClick={() => toggleShowUpgradeInfo(false)}
              sx={{ mt: 2, borderRadius: 2 }}
            >
              View Plans
            </Button>
          </Stack>
        </SwipeableDrawer>
      )}

      {!showUpgradeInfo && (
        <Modal
          open={isOpen}
          onClose={onCloseModal}
          contentSx={{
            width: '100%',
            maxHeight: '90svh',
            minHeight: '70svh',
            alignSelf: 'end',
            padding: 0,
            boxSizing: 'border-box',
            borderRadius: '12px 12px 0px 0px',
          }}
        >
          <Stack>
            {!showDowngradeContent && (
              <Stack>
                <Stack flexDirection="row" justifyContent="space-between" padding="16px 12px">
                  <Typography variant="h3" textAlign="left">
                    Manage Subscription
                  </Typography>
                  <Icon
                    className="fi fi-rr-cross-small"
                    onClick={onCloseModal}
                    color="text.secondary"
                  />
                </Stack>
                <Divider />
              </Stack>
            )}
            <Stack
              sx={{
                flexDirection: 'row',
                maxHeight: '75svh',
                gap: 2,
                padding: showDowngradeContent ? 2 : '16px 8px',
                paddingBottom: 3,
                transition: 'all 0.3s ease',
                overflowY: 'auto',
              }}
            >
              {/* mobile content */}
              {!showDowngradeContent && !showUpgradeInfo && isMobileOrTablet && (
                <Stack
                  sx={{
                    height: '100%',
                    overflowY: 'hidden',
                    '.slick-dots': {
                      position: 'relative',
                      bottom: '0',
                    },
                    '.slick-dots li button': {
                      height: '16px',
                      width: '16px',
                    },
                    '.slick-dots li button:before': {
                      height: '16px',
                      width: '16px',
                      fontSize: '8px',
                    },
                    '.slick-dots li.slick-active button:before': {
                      color: 'primary.main',
                    },
                    '.slick-slider': {
                      paddingBottom: 0,
                    },
                  }}
                >
                  <Typography variant="body3" sx={{ paddingX: 1, paddingBottom: 2 }}>
                    Unlock unlimited scans, job match scores & more...
                  </Typography>
                  <Slider
                    dots
                    infinite={false}
                    speed={500}
                    slidesToShow={1}
                    slidesToScroll={1}
                    arrows={false}
                  >
                    {[...subscriptionPlansStatic].reverse().map((plan) => (
                      <SubscriptionPlanCard
                        key={plan.id}
                        plan={{
                          ...plan,
                          amount:
                            Number(
                              subscriptionPlans?.find((p) => p.name === plan.planName)?.amount,
                            ) || 0,
                        }}
                        subscription={subscription}
                        onUpdateConfirmClick={onUpdateConfirmClick}
                        onDowngradeClick={onDowngradeClick}
                        onResubscribeClick={onResubscribeClick}
                      />
                    ))}
                  </Slider>
                </Stack>
              )}

              {/* downgrade content */}
              {showDowngradeContent && !showUpgradeInfo && (
                <Stack sx={{ gap: 2 }}>
                  <Typography variant="h3">
                    Are you sure you want to cancel your subscription?
                  </Typography>
                  <DowngradeSubscriptionContent
                    nextInvoiceDate={subscription?.nextInvoiceDate}
                    onback={() => toggleShowDowngradeContent(false)}
                    onDowngradeConfirm={(reason: string) => {
                      if (onDowngradeConfirm) {
                        toggleShowDowngradeContent(false);
                        onDowngradeConfirm(reason);
                      }
                    }}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Modal>
      )}
    </Box>
  );
};

export default MobileManageSubscriptionContent;
