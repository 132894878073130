import { Link } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import jobQuestDesktopLogo from '../../assets/jobquest-logo.svg';
import jobQuestMobileLogo from '../../assets/jobquest-mobile-logo.svg';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import { PlanTypes } from '../../services/billing';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import theme from '../../theme/theme';
import Chip from './Chip';

// Define a custom interface for breakpoints
interface Breakpoints {
  xs?: string | number | undefined;
  sm?: string | number | undefined;
  md?: string | number | undefined;
  lg?: string | number | undefined;
  xl?: string | number | undefined;
}

interface LogoProps {
  width?: string | Breakpoints;
  height?: string | Breakpoints;
  style?: React.CSSProperties;
  enablePlanChip?: boolean;
  enableResponsiveLogo?: boolean;
}

/**
 * Logo Component
 *
 * This component renders the JobQuest logo, which can adapt its size and appearance
 * based on the provided props and device breakpoints. It supports responsive logos
 * for mobile and desktop and displays a plan chip if applicable.
 *
 * @component
 *
 * @param {string | Breakpoints} [width='130px'] - The width of the logo. Can be a fixed string or an object with responsive breakpoints.
 * @param {string | Breakpoints} [height='48px'] - The height of the logo. Can be a fixed string or an object with responsive breakpoints.
 * @param {React.CSSProperties} [style] - Additional CSS styles to apply to the logo image.
 * @param {boolean} [enablePlanChip=true] - Determines whether to show a chip indicating the plan type (e.g., "PRO").
 * @param {boolean} [enableResponsiveLogo=false] - Enables responsive logo switching between mobile and desktop versions.
 *
 * @returns {JSX.Element} A clickable logo component with optional plan chip and responsive behavior.
 *
 * @example
 * // Basic usage
 * <Logo />
 *
 * @example
 * // Responsive width and height
 * <Logo
 *   width={{ xs: '80px', sm: '100px', md: '130px', lg: '150px', xl: '180px' }}
 *   height={{ xs: '30px', sm: '40px', md: '50px', lg: '60px', xl: '70px' }}
 * />
 *
 * @example
 * // Logo with plan chip and responsive switching
 * <Logo enablePlanChip={true} enableResponsiveLogo={true} />
 *
 * @example
 * // Custom styles for the logo
 * <Logo
 *   style={{
 *     borderRadius: '8px',
 *     border: '2px solid #000',
 *   }}
 * />
 */
const Logo = ({
  width = '130px',
  height = '48px',
  style,
  enablePlanChip = true,
  enableResponsiveLogo = false,
}: LogoProps) => {
  const { isMobile } = useResponsiveDevice();
  const { accountStatus } = useAccountStatusStore();
  const subscription = accountStatus?.subscription;

  // Define the breakpoints you want to check
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));

  const getLogoWidth = () => {
    if (typeof width === 'string') {
      return width;
    }
    if (isXl) {
      return width.xl || width.lg || width.md || width.sm || width.xs;
    }
    if (isLg) {
      return width.lg || width.md || width.sm || width.xs;
    }
    if (isMd) {
      return width.md || width.sm || width.xs;
    }
    if (isSm) {
      return width.sm || width.xs;
    }
    if (isXs) {
      return width.xs;
    }
    return '';
  };

  const getLogoHeight = () => {
    if (typeof height === 'string') {
      return height;
    }
    if (isXl) {
      return height.xl || height.lg || height.md || height.sm || height.xs;
    }
    if (isLg) {
      return height.lg || height.md || height.sm || height.xs;
    }
    if (isMd) {
      return height.md || height.sm || height.xs;
    }
    if (isSm) {
      return height.sm || height.xs;
    }
    if (isXs) {
      return height.xs;
    }
    return '';
  };

  return (
    <Link
      to="/"
      style={{
        maxHeight: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        textDecoration: 'none',
      }}
    >
      <img
        src={enableResponsiveLogo && isMobile ? jobQuestMobileLogo : jobQuestDesktopLogo}
        alt="job-quest-logo"
        height={getLogoHeight()}
        width={getLogoWidth()}
        style={style}
      />
      {enablePlanChip && subscription && subscription !== PlanTypes.STANDARD && (
        <Chip
          sx={{
            backgroundColor: 'primary.main',
            marginBottom: 1,
            color: 'common.white',
            '& .MuiChip-label': {
              padding: `${theme.spacing(0.25)} ${theme.spacing(0.5)}`,
              fontSize: theme.spacing(1.125),
              fontWeight: 700,
              lineHeight: '100%',
              letterSpacing: '-0.045px',
            },
          }}
          label="PRO"
        />
      )}
    </Link>
  );
};

export default Logo;
