import { create } from 'zustand';

import { hasJWTToken, refreshLogin } from '../services/auth';
import type { AccountStatus, FeatureFlags } from '../services/billing';
import { PlanTypes, getAccountStatus } from '../services/billing';

type AccountStatusState = {
  accountStatus: AccountStatus | null;
  isImportResumeEnabled: boolean;
  isAICustomizeResumeEnabled: boolean;
  isCreateJobMatchScoreEnabled: boolean;
  isGenerateEnabled: boolean;
  isComposeEnabled: boolean;
  isAutofixEnabled: boolean;
  isSuperchargeEnabled: boolean;
  isJDImportEnabled: boolean;
  isSampleResumeEnabled: boolean;
  isBlankResumeEnabled: boolean;
  setAccountStatus: (status: AccountStatus) => void;
  resetToDefault: () => void;
  initialize: () => Promise<void | AccountStatus>;
  reInitialize: () => Promise<void | AccountStatus>;
};

const DEFAULT_FEATURE_FLAGS: FeatureFlags = {
  IMPORT_RESUME: false,
  AI_CUSTOMIZE_RESUME: false,
  CREATE_JOB_MATCH_SCORE: false,
  GENERATE: false,
  COMPOSE: false,
  AUTOFIX: false,
  SUPERCHARGE: false,
  JD_IMPORT: false,
  SAMPLE_RESUME: false,
  BLANK_RESUME: false,
};

const DEFAULT_ACCOUNT_STATUS: AccountStatus = {
  id: '',
  subscription: PlanTypes.STANDARD,
  planEndData: '',
  forceRefreshToken: false,
  featureFlags: DEFAULT_FEATURE_FLAGS,
  isPlanCancelled: false,
};

/**
 * Adds boolean fields to the feature flags object.
 *
 * @param {FeatureFlags} featureFlags - The feature flags object containing various feature toggles.
 * @returns {Object} An object with boolean fields indicating the enabled status of various features.
 * @property {boolean} isImportResumeEnabled - Indicates if the import resume feature is enabled.
 * @property {boolean} isAICustomizeResumeEnabled - Indicates if the AI customize resume feature is enabled.
 * @property {boolean} isCreateJobMatchScoreEnabled - Indicates if the create job match score feature is enabled.
 * @property {boolean} isGenerateEnabled - Indicates if the generate feature is enabled.
 * @property {boolean} isComposeEnabled - Indicates if the compose feature is enabled.
 * @property {boolean} isAutofixEnabled - Indicates if the autofix feature is enabled.
 * @property {boolean} isSuperchargeEnabled - Indicates if the supercharge feature is enabled.
 * @property {boolean} isJDImportEnabled - Indicates if the JD import feature is enabled.
 * @property {boolean} isSampleResumeEnabled - Indicates if the sample resume feature is enabled.
 * @property {boolean} isBlankResumeEnabled - Indicates if the blank resume feature is enabled.
 */
const addBooleanFields = (featureFlags: FeatureFlags) => ({
  isImportResumeEnabled: featureFlags.IMPORT_RESUME,
  isAICustomizeResumeEnabled: featureFlags.AI_CUSTOMIZE_RESUME,
  isCreateJobMatchScoreEnabled: featureFlags.CREATE_JOB_MATCH_SCORE,
  isGenerateEnabled: featureFlags.GENERATE,
  isComposeEnabled: featureFlags.COMPOSE,
  isAutofixEnabled: featureFlags.AUTOFIX,
  isSuperchargeEnabled: featureFlags.SUPERCHARGE,
  isJDImportEnabled: featureFlags.JD_IMPORT,
  isSampleResumeEnabled: featureFlags.SAMPLE_RESUME,
  isBlankResumeEnabled: featureFlags.BLANK_RESUME,
});

/**
 * A Zustand store for managing account status.
 *
 * @remarks
 * This store provides state management for account status, including setting the status,
 * resetting it to a default value, and initializing it by fetching from the server.
 * The store also provides a function to reinitialize the account status.
 *
 * @example
 * ```typescript
 * const { accountStatus, setAccountStatus, resetToDefault, initialize } = useAccountStatusStore();
 * ```
 *
 * @returns {object} The account status store with the following properties:
 * - `accountStatus`: The current account status.
 * - `setAccountStatus`: A function to set the account status.
 * - `resetToDefault`: A function to reset the account status to the default value.
 * - `initialize`: An async function to initialize the account status by fetching it from the server.
 * - `reInitialize`: An async function to reinitialize the account status.
 */
export const useAccountStatusStore = create<AccountStatusState>((set) => ({
  accountStatus: DEFAULT_ACCOUNT_STATUS,
  ...addBooleanFields(DEFAULT_FEATURE_FLAGS),
  setAccountStatus: (status) =>
    set(() => ({
      accountStatus: status,
      ...addBooleanFields(status.featureFlags),
    })),
  resetToDefault: () =>
    set(() => ({
      accountStatus: DEFAULT_ACCOUNT_STATUS,
      ...addBooleanFields(DEFAULT_FEATURE_FLAGS),
    })),
  initialize: async () => {
    if (!hasJWTToken()) {
      return Promise.reject(new Error('No JWT token found'));
    }

    // Fetch account status from the server
    try {
      const accountStatus = await getAccountStatus();
      set(() => ({
        accountStatus,
        ...addBooleanFields(accountStatus.featureFlags),
      }));
      if (accountStatus.forceRefreshToken) {
        await refreshLogin();
        const reInitialize = async () => useAccountStatusStore.getState().initialize();
        setTimeout(reInitialize, 2000);
      }

      return accountStatus;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  reInitialize: async (): Promise<void | AccountStatus> =>
    useAccountStatusStore.getState().initialize(),
}));
