import AiCustomizeImage from '../../assets/ai_customize_illustration.svg';
import JobScoreImage from '../../assets/job_match_score_illustration.svg';
import JdXrayImage from '../../assets/job_xray_illustration.svg';
import ResumeBuilderImage from '../../assets/sample_resume_illustration.svg';

enum OnboardingOptionKeys {
  JobMatchScore = 'job-match-score',
  AiCustomize = 'ai-customize',
  ResumeBuilder = 'resume-builder',
  JobXRay = 'job-xray',
}

export interface OnboardingFlowOptions {
  key: OnboardingOptionKeys;
  icon: string;
  heading: string;
  description: string;
  href: string;
}

export const slidesContent = [
  {
    title: 'Job Match Score',
    description:
      'Get your job match score before applying to check how well your resume matches the job description.',
    image: JobScoreImage,
  },
  {
    title: 'AI Customize',
    description: 'Tailor your resume to the job description with a single click.',
    image: AiCustomizeImage,
  },
  {
    title: 'Resume Builder',
    description:
      'Create your resume quickly by importing, editing, or starting from scratch with our proven ATS templates.',
    image: ResumeBuilderImage,
  },
  {
    title: 'Job X-Ray',
    description: 'Analyze job descriptions with ease and focus on key details instantly.',
    image: JdXrayImage,
  },
];

export const onboardingFlowOptions = [
  {
    key: OnboardingOptionKeys.JobMatchScore,
    icon: 'fi-rr-tachometer',
    heading: 'Job Match Score',
    description: 'Check if you are applying to the right job.',
    href: 'job-match',
  },
  {
    key: OnboardingOptionKeys.AiCustomize,
    icon: 'fi-rr-magic-wand',
    heading: 'AI Resume Tailor',
    description: 'Tailor Your Resume to the Target Job.',
    href: 'resume/ai-customize',
  },
  {
    key: OnboardingOptionKeys.ResumeBuilder,
    icon: 'fi-rr-layout-fluid',
    heading: 'Resume Builder',
    description: 'Build a resume in mins.',
    href: 'resume/create',
  },
  {
    key: OnboardingOptionKeys.JobXRay,
    icon: 'fi-rr-search-alt',
    heading: 'Job X-Ray',
    description: 'Get highlights of the job description.',
    href: 'job-xray',
  },
];
