import type { ReactNode } from 'react';

import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';

import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import Button from './Button';
import Icon from './Icon';
import Modal from './Modal/Modal';
import ModalActions from './Modal/ModalActions';
import ModalDetail from './Modal/ModalDetail';
import SwipeableDrawer, { Puller } from './SwipeableDrawer';

/**
 * This component renders a popup that adapts its presentation for mobile and desktop views.
 * On mobile devices, it uses a swipeable drawer that slides up from the bottom,
 * while on desktop, it uses a modal window.
 *
 * The content includes a title, description, and an action button labeled by default as "Got it,"
 * which can be customized with the `buttonLabel` prop. The popup also has a customizable close button
 * for the drawer on mobile, controlled by `drawerButtonType`, which can either show a puller handle or a close icon.
 *
 * The `onClose` callback is triggered when the button is clicked, or the drawer/modal is closed.
 *
 * @component
 *
 * @param {object} content - The content to display inside the popup, including `title`, `description`, and an optional `buttonLabel`.
 * @param {string} content.title - The title text displayed at the top of the popup.
 * @param {ReactNode} content.description - The description text displayed below the title.
 * @param {string} [content.buttonLabel] - The label text for the action button (default is "Got it").
 * @param {boolean} open - A boolean indicating whether the popup is open or closed.
 * @param {function} onClose - A callback function that is called when the popup is closed.
 * @param {function} onOpen - A callback function that is called when the popup is opened.
 * @param {string} [drawerButtonType='puller'] - Determines the style of the drawer close button for mobile view.
 *   Acceptable values are `'puller'` for a puller handle or `'close'` for a close icon.
 * @param {ReactNode} [actions] - Custom actions to display in place of the default "Got it" button. If provided,
 *   it will override the default button.
 *
 * @returns {JSX.Element} A swipeable drawer for mobile or modal for desktop with a title, description, and close action.
 *
 * @example
 * // Example usage with custom actions
 * const handleOpen = () => {
 *   console.log('Popup opened');
 * };
 *
 * const handleClose = () => {
 *   console.log('Popup closed');
 * };
 *
 * const content = {
 *   title: 'Info Title',
 *   description: 'This is a description of the popup content.',
 *   buttonLabel: 'Understood',
 * };
 *
 * const customActions = (
 *   <Stack direction="row" gap={1}>
 *     <Button onClick={handleClose}>Cancel</Button>
 *     <Button onClick={handleConfirm}>Confirm</Button>
 *   </Stack>
 * );
 *
 * <InfoPopup
 *   content={content}
 *   open={true}
 *   onClose={handleClose}
 *   onOpen={handleOpen}
 *   actions={customActions}
 * />
 */

interface PopupProps {
  content: { title: string; description: ReactNode; buttonLabel?: string };
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  drawerButtonType?: 'puller' | 'close';
  actions?: React.ReactNode;
}

const InfoPopup = ({
  content,
  open,
  onClose,
  onOpen,
  drawerButtonType = 'puller',
  actions,
}: PopupProps) => {
  const { isMobile } = useResponsiveDevice();
  const theme = useTheme();

  return isMobile ? (
    <SwipeableDrawer
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      contentProps={{
        sx: {
          textAlign: 'left',
        },
      }}
    >
      {drawerButtonType === 'puller' ? (
        <Puller sx={{ marginBottom: 3 }} />
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <IconButton aria-label="cross icon" component="button" onClick={onClose}>
            <Icon className="fi fi-rr-cross-small" fontSize="20px" />
          </IconButton>
        </Box>
      )}
      <Stack sx={{ gap: 2, width: '100%' }}>
        <Typography variant="h3">{content.title}</Typography>
        <Typography variant="body3" sx={{ maxHeight: '50vh', overflowY: 'auto' }}>
          {content.description}
        </Typography>
        {actions || (
          <Button
            onClick={onClose}
            sx={{
              marginTop: 2,
              width: '100%',
              padding: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
            }}
          >
            {content.buttonLabel || 'Got it'}
          </Button>
        )}
      </Stack>
    </SwipeableDrawer>
  ) : (
    <Modal open={open} contentSx={{ width: '31.25rem' }}>
      <>
        <ModalDetail title={content.title} description={content.description} />
        <ModalActions
          actions={actions || <Button onClick={onClose}> {content.buttonLabel || 'Got it'}</Button>}
        />
      </>
    </Modal>
  );
};

export default InfoPopup;
