import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import FormActions from '../../components/Form/FormActions';
import BreadCrumbs from '../../components/common/BreadCrumbs';
import Button from '../../components/common/Button';
import Logo from '../../components/common/Logo';
import SelectCard from '../../components/common/SelectCard';
import { useManageSubscriptionModal } from '../../contexts/ManageSubscriptionModal/ManageSubscriptionModal';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import RoutePaths from '../../routes/RoutePaths';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import { useAppBarStore } from '../../stores/AppBarStore';
import { useSideBarStore } from '../../stores/SideBarStore';
import { CreateResumeKey, resumeCreateOptions } from './constants';

const ResumeCreate = () => {
  const { hideAppBar, hideSideBar, showAppBar, showSideBar } = useSideBarStore();
  const { setAppBar, setInitialState } = useAppBarStore();
  const { isMobile, isMobileOrTablet } = useResponsiveDevice();
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(CreateResumeKey.IMPORT);

  const { isImportResumeEnabled, isSampleResumeEnabled, isBlankResumeEnabled } =
    useAccountStatusStore();
  const { openModal: openManageSubscriptionModal } = useManageSubscriptionModal();

  const BreadCrumbLinks = [
    {
      label: 'Back',
      href: RoutePaths.RESUMES,
    },
  ];

  useEffect(() => {
    if (isMobile) {
      setAppBar('Resumes', null);
      showAppBar();
      showSideBar();
    } else {
      hideSideBar();
      hideAppBar();
    }

    return () => {
      setInitialState(isMobile);
    };
  }, [hideAppBar, hideSideBar, isMobile, setAppBar, setInitialState, showAppBar, showSideBar]);

  const onCancel = () => {
    navigate(RoutePaths.RESUMES);
  };

  const onConfirm = () => {
    switch (selectedKey) {
      case CreateResumeKey.IMPORT:
        navigate(RoutePaths.RESUME_IMPORT);
        break;
      case CreateResumeKey.BLANK_RESUME:
        navigate(RoutePaths.RESUME_BLANK);
        break;
      case CreateResumeKey.SAMPLE_RESUME:
        navigate(RoutePaths.RESUME_SAMPLE);
        break;
      default:
        break;
    }
  };

  const isItemDisabled = ({ key }: { key: CreateResumeKey }) => {
    const disabledKeys = {
      [CreateResumeKey.IMPORT]: !isImportResumeEnabled,
      [CreateResumeKey.BLANK_RESUME]: !isBlankResumeEnabled,
      [CreateResumeKey.SAMPLE_RESUME]: !isSampleResumeEnabled,
    };
    return disabledKeys[key] || false;
  };

  return (
    <Stack
      sx={{
        gap: { xs: 1.5, sm: 11.5 },
        maxWidth: '100%',
        height: '100%',
        boxSizing: 'border-box',
        padding: {
          xs: `${theme.spacing(3)} ${theme.spacing(1.5)}`,
          sm: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(0)} ${theme.spacing(4)}`,
        },
        backgroundColor: { sm: 'background.mediumLight' },
      }}
    >
      {isMobile ? (
        <BreadCrumbs links={BreadCrumbLinks} />
      ) : (
        <Stack
          sx={{
            alignItems: 'flex-start',
            height: theme.spacing(6.25),
            paddingTop: 1.5,
            minWidth: '100%',
          }}
        >
          <Logo style={{ height: '100%' }} />
        </Stack>
      )}
      <Stack
        sx={{ gap: 2, height: '100%', boxSizing: 'border-box', justifyContent: 'space-between' }}
      >
        <Stack
          sx={{
            gap: { xs: 4, sm: 8 },
            alignItems: 'center',
          }}
        >
          <Typography variant={isMobile ? 'h3' : 'h1'} sx={{ textAlign: 'center' }}>
            How Would You Like to Create Your Resume?
          </Typography>
          <Grid
            spacing={2}
            container
            sx={{ justifyContent: 'center', width: '100%', maxWidth: '75rem' }}
          >
            {resumeCreateOptions.map((item) => (
              <Grid key={item.key} xs={12} sm={8} md={5} lg={4} sx={{ width: '100%' }}>
                <Box
                  onClick={() => {
                    if (isItemDisabled(item) && isMobileOrTablet) {
                      openManageSubscriptionModal();
                    }
                  }}
                >
                  <SelectCard
                    cardContent={item}
                    sx={{
                      borderColor:
                        // eslint-disable-next-line no-nested-ternary
                        isItemDisabled(item)
                          ? 'border.light'
                          : selectedKey === item.key
                          ? 'primary.main'
                          : 'border.light',
                    }}
                    disabled={isItemDisabled(item)}
                    showDisabledTooltip
                    onClick={(key) => setSelectedKey(key as CreateResumeKey)}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Stack>
        <FormActions
          sx={{
            ...(!isMobile && { position: 'absolute', bottom: '0', right: '0' }),
            padding: {
              xs: `${theme.spacing(0)} ${theme.spacing(1)}`,
              sm: `${theme.spacing(2.5)} ${theme.spacing(15)}`,
            },
            backgroundColor: 'common.white',
            borderTop: { sm: '1px solid' },
            borderColor: { sm: 'border.light' },
            width: '100%',
          }}
          actions={
            <Stack sx={{ gap: 1.5, flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Button
                variant="outlined"
                onClick={onCancel}
                sx={{
                  marginRight: 1,
                  width: { xs: '30%', sm: 'fit-content' },
                  ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
                }}
              >
                Back
              </Button>
              <Button
                disabled={
                  (selectedKey === CreateResumeKey.IMPORT && !isImportResumeEnabled) ||
                  (selectedKey === CreateResumeKey.BLANK_RESUME && !isBlankResumeEnabled) ||
                  (selectedKey === CreateResumeKey.SAMPLE_RESUME && !isSampleResumeEnabled)
                }
                sx={{
                  width: { xs: '70%', sm: 'fit-content' },
                  ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
                }}
                onClick={onConfirm}
              >
                Next
              </Button>
            </Stack>
          }
        />
      </Stack>
    </Stack>
  );
};

export default ResumeCreate;
