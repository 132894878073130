import type { ChangeEvent } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import JobDescriptionInput from '../../../components/JobDescriptionInput';
import ProgressManager from '../../../components/ProgressManager';
import { ProgressScreenType } from '../../../components/ProgressManager/constants';
import Button from '../../../components/common/Button';
import Logo from '../../../components/common/Logo';
import Stepper from '../../../components/common/Stepper';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { JobDescriptionTypes, uploadJobDescription } from '../../../services/jobDescriptions';
import { ResumesKeys, createResume } from '../../../services/resumes';
import theme from '../../../theme/theme';
import { isNilOrEmpty } from '../../../utils';
import ChooseCareerStage from '../../ResumeCreate/component/ChooseCareerStage';
import type { CareerStageKey } from '../../ResumeCreate/constants';
import OnboardingCard from './OnboardingCard';

/**
 * `OnboardingSampleResumeBuilder` Component
 *
 * This component facilitates the onboarding process for generating a sample resume.
 * Users progress through a step-by-step flow:
 * 1. Select their career stage.
 * 2. Provide a job description.
 *
 * Upon successful completion, the component generates a resume tailored to the user's input.
 *
 * ## Features:
 * - **Career Stage Selection:** Users choose their current career stage from predefined options.
 * - **Job Description Input:** Users can input job descriptions to guide resume creation.
 * - **Progress Tracker:** A progress screen indicates the resume generation process.
 * - **Responsive Design:** Mobile and desktop views are supported for better usability.
 * - **Error Handling:** Displays user-friendly error messages for API failures.
 *
 * @component
 * @returns {JSX.Element} Rendered `OnboardingSampleResumeBuilder` component.
 *
 * @example
 * <OnboardingSampleResumeBuilder />
 */
const OnboardingSampleResumeBuilder = () => {
  // State management for sample resume generation
  const [selectedCareerStage, setSelectedCareerStage] = useState<CareerStageKey>(); // Track the selected career stage
  const [jobDescription, setJobDescription] = useState<string>(); // Store the job description provided by the user
  const [activeStep, setActiveStep] = useState(1); // Manage the current step in the onboarding process
  const [resumeId, setResumeId] = useState(''); // Store the resume ID after a successful creation
  const [showProgress, setShowProgress] = useState(false); // Toggle display of the progress screen

  // Contexts and hooks
  const { showSnackbar } = useSnackbar(); // For displaying notifications
  const navigate = useNavigate(); // For navigating within the app
  const { isMobile } = useResponsiveDevice(); // Responsive behavior based on device type
  const queryClient = useQueryClient(); // Query client for cache management

  /**
   * `createSampleResume` mutation handles the process of creating a sample resume.
   *
   * ## On mutate:
   * - Executes immediately before the mutation function is triggered.
   * - Logs an analytics event to track the initiation of the resume generation process.
   * - Event details:
   *   - **Category**: `FORM_SUBMISSION`
   *   - **Action**: `SUBMIT`
   *   - **Label**: `Onboarding Sample Resume Generation started`
   *
   * ## On success:
   * - Invalidates the cache for resumes using the query key `ResumesKeys.RESUMES` to fetch updated data.
   * - Sets the created resume ID in the local state using `setResumeId`.
   * - Displays the progress screen by setting `setShowProgress` to `true`.
   *
   * ## On failure:
   * - Displays an error message in the snackbar with details of the failure.
   */
  const { isLoading: isCreatingResume, mutate: createSampleResume } = useMutation({
    retry: 3, // Retry on failure up to 3 times
    mutationFn: createResume,
    onSuccess: (data) => {
      // Invalidate cache and update state
      queryClient.invalidateQueries([ResumesKeys.RESUMES]);
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Onboarding Sample Resume Generation started',
      );
      setResumeId(data._id);
      setShowProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      // Show error notification on failure
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to create a sample resume. Please try again.',
      );
    },
  });

  /**
   * `uploadJD` mutation handles the upload of a job description.
   *
   * ## On mutate:
   * - Executes immediately before the mutation function is triggered.
   * - Logs an analytics event to track the initiation of the resume generation process.
   * - Event details:
   *   - **Category**: `FORM_SUBMISSION`
   *   - **Action**: `SUBMIT`
   *   - **Label**: `Upload Job Description for onboarding sample resume generation`
   *
   * ## On success:
   * - Invalidates the cache for job descriptions using the query key `JobDescriptionTypes.JOBXRAYS` to fetch updated data.
   * - Triggers the `createSampleResume` mutation with the uploaded job description ID and the selected career stage.
   *
   * ## On failure:
   * - Displays an error message in the snackbar with details of the failure.
   */
  const { isLoading: isJdUploading, mutate: uploadJD } = useMutation({
    retry: 3, // Retry on failure up to 3 times
    mutationFn: uploadJobDescription,
    onMutate: () => {
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Upload Job Description for onboarding sample resume generation',
      );
    },
    onSuccess: (jobDescriptionData) => {
      // Invalidate cache and trigger sample resume creation
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS]);
      createSampleResume({
        careerStage: selectedCareerStage,
        jobDescriptionId: jobDescriptionData._id,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      // Show error notification
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to upload Job Description. Please try again.',
      );
    },
  });

  /**
   * Handles the "Back" button functionality.
   *
   * ## Behavior:
   * - If the current step is the first step (`activeStep === 1`), it navigates to the previous page using `navigate(-1)`.
   * - Otherwise, it decrements the active step, moving the user to the previous step in the flow.
   */
  const onBackClick = () => {
    if (activeStep === 1) {
      navigate(-1);
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  /**
   * Handles the "Next" button functionality.
   *
   * ## Behavior:
   * - If the current step is the first step (`activeStep === 1`), it increments the active step, moving the user to the next step in the flow.
   * - If the current step is the second step (`activeStep === 2`), it initiates the job description upload process using the `uploadJD` mutation.
   */
  const onClickNext = () => {
    if (activeStep === 1) {
      setActiveStep(activeStep + 1); // Move to the next step
    } else if (activeStep === 2) {
      uploadJD(jobDescription as string); // Upload job description on final step
    }
  };

  // Update job description state when user inputs data
  const handleUpdateJobDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setJobDescription(event.target.value);
  };

  return (
    <>
      {/* Display progress screen if `showProgress` is true */}
      {showProgress ? (
        /**
         * `ProgressManager` component displays the progress of the sample resume generation process.
         * Shows a success event upon completion.
         *
         * Props:
         * - `id`: The ID of the uploaded resume.
         * - `progressScreenKey`: Specifies the progress screen type.
         * - `successEventLabel`: Logs the success event in analytics.
         */
        <ProgressManager
          id={resumeId}
          progressScreenKey={ProgressScreenType.SampleResume}
          successEventLabel="Onboarding Sample Resume Generation Successful"
        />
      ) : (
        <Box
          sx={{
            padding: { xs: `${theme.spacing(2)} ${theme.spacing(0.5)}`, sm: 2 },
            height: '100%',
            boxSizing: 'border-box',
          }}
        >
          {/* Displays the app logo at the top */}
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <Logo style={{ height: theme.spacing(6.25) }} />
          </Box>
          {/* Container for onboarding content */}
          <OnboardingCard
            rootSx={{ height: `calc(100% - ${theme.spacing(8.25)})` }}
            sx={{ width: '100%', maxWidth: '1000px' }}
            cardContentProps={{ sx: { gap: 2, alignItems: 'unset' } }}
          >
            <Stack sx={{ gap: { xs: 3, sm: 4 } }}>
              {/* Header with title and description */}
              <Stack sx={{ gap: 1.5, width: '100%' }}>
                <Typography variant={isMobile ? 'h2' : 'h1'}>Generate Sample Resume</Typography>
                <Typography variant="body2">
                  Generate a sample resume based on your career stage and a job description. Your
                  resume sections are automatically determined based on your career stage. You can
                  edit them later.
                </Typography>
              </Stack>
              {isMobile && <Stepper activeStep={activeStep} totalSteps={2} />}
              {/* Displays career stage selection. */}
              {activeStep === 1 ? (
                <ChooseCareerStage
                  onChange={setSelectedCareerStage}
                  defaultValue={selectedCareerStage}
                  headingProps={{ sx: { textAlign: 'left' }, paddingX: 0 }}
                  contentProps={{ lg: 6 }}
                />
              ) : (
                //  Displays job description input field.
                <JobDescriptionInput onUpdateDescription={handleUpdateJobDescription} />
              )}
            </Stack>

            {/* Navigation buttons for progressing through onboarding steps. */}
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                gap: 1.5,
              }}
            >
              {/* "Back" button moves to the previous step or exits the flow. */}
              <Button
                variant="outlined"
                onClick={onBackClick}
                sx={{
                  width: { xs: '30%', sm: 'fit-content' },
                  ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
                }}
              >
                Back
              </Button>

              {/* "Next" or "Generate" button moves to the next step or uploads the job description. */}
              <Button
                onClick={onClickNext}
                sx={{
                  width: { xs: '70%', sm: 'fit-content' },
                  padding: {
                    xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                    sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                  },
                }}
                loading={isJdUploading || isCreatingResume}
                disabled={
                  activeStep === 1
                    ? isNilOrEmpty(selectedCareerStage)
                    : isNilOrEmpty(jobDescription) || isJdUploading || isCreatingResume
                }
              >
                {activeStep === 1 ? 'Next' : 'Generate'}
              </Button>
            </Stack>
          </OnboardingCard>
        </Box>
      )}
    </>
  );
};

export default OnboardingSampleResumeBuilder;
