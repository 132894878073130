import { isNil } from 'ramda';

import { Stack, Typography } from '@mui/material';

import Button from '../../../components/common/Button';
import Card from '../../../components/common/Card';
import Chip from '../../../components/common/Chip';
import Icon from '../../../components/common/Icon';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { PlanTypes, type Receipt, type SubscriptionPlan } from '../../../services/billing';
import {
  StripeInvoiceStatus,
  getNextInvoiceDateText,
  getPlanAmountPerMonth,
  getPlanName,
  getTrialEndDateText,
  isFreeSubcriptionPlan,
} from '../../../utils/billingUtils';

const CurrentSubscription = ({
  subscriptionPlan,
  latestInvoice,
  onUpgradeDowngradeClick,
  onManagePaymentMethodClick,
}: {
  subscriptionPlan?: SubscriptionPlan;
  onUpgradeDowngradeClick?: () => void;
  latestInvoice?: Receipt;
  onManagePaymentMethodClick?: () => void;
}) => {
  const { planName, amount, nextInvoiceDate } = subscriptionPlan || {};
  const { isMobile } = useResponsiveDevice();

  const isFreePlan = isFreeSubcriptionPlan(planName);
  const isTrialPlan = planName === PlanTypes.TRIAL;
  const isProPlan = planName === PlanTypes.PRO;

  const shouldShowPerMonthText = amount && planName !== PlanTypes.STANDARD;

  return (
    <Stack
      sx={{
        gap: 2,
        width: '100%',
      }}
    >
      {!subscriptionPlan && <LoadingSpinner />}
      <Typography variant="h3">Current Subscription</Typography>
      <Card
        elevation={3}
        rootSx={{ paddingTop: 0.5 }}
        sx={{ borderRadius: 3 }}
        cardContentProps={{
          sx: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 3,
            gap: 0.5,
          },
        }}
      >
        <Stack gap={0.5}>
          <Typography variant="body2">
            {getPlanName(planName)}
            {isTrialPlan && (
              <Chip
                label="Free Trial"
                sx={{
                  marginLeft: 1,
                  padding: '0px 6px',
                  verticalAlign: 'top',
                  backgroundColor: 'primary.light',
                }}
              />
            )}
          </Typography>
          <Typography variant="h2">
            {getPlanAmountPerMonth(amount)}
            {!shouldShowPerMonthText && planName === PlanTypes.STANDARD && ` Forever`}
            {shouldShowPerMonthText && <Typography variant="label2"> per month</Typography>}
          </Typography>
          {isTrialPlan && (
            <Typography variant="body3" color="text.secondary">
              {getTrialEndDateText(nextInvoiceDate)}
            </Typography>
          )}
          {isProPlan && (
            <Typography
              variant={
                isNil(latestInvoice) || latestInvoice?.status === StripeInvoiceStatus.Paid
                  ? 'body3'
                  : 'assistiveBold'
              }
              sx={{
                color:
                  isNil(latestInvoice) || latestInvoice?.status === StripeInvoiceStatus.Paid
                    ? 'text.secondary'
                    : 'error.main',
              }}
            >
              {getNextInvoiceDateText(
                nextInvoiceDate,
                planName,
                latestInvoice?.status as StripeInvoiceStatus,
                subscriptionPlan?.isPlanCancelled,
              )}
            </Typography>
          )}
        </Stack>
        {!isFreePlan && (
          <Button
            variant="text"
            onClick={onUpgradeDowngradeClick}
            sx={{
              color: 'text.primary',
              ':hover': {
                backgroundColor: 'background.main',
              },
              ...(isMobile && { padding: 2 }),
            }}
          >
            Manage
          </Button>
        )}
        {isFreePlan && (
          <Button
            variant="contained"
            onClick={onUpgradeDowngradeClick}
            sx={{ ...(isMobile && { padding: 2 }) }}
          >
            <Icon className="fi fi-rr-progress-upload" fontSize="16px" color="common.white" />
            Upgrade
          </Button>
        )}
      </Card>

      <Button
        onClick={onManagePaymentMethodClick}
        variant="text"
        sx={{
          color: 'text.primary',
          width: 'fit-content',
          border: '1.5px solid',
          borderColor: 'border.light',
          ':hover': {
            backgroundColor: 'background.main',
          },
        }}
      >
        Manage Payment Method
      </Button>
    </Stack>
  );
};

export default CurrentSubscription;
