import ResumeEditor from '../components/ResumeEditor';
import AiCustomizedResume from '../pages/AiCustomizedResume';
import CreateJob from '../pages/Create/CreateJobDescription';
import CreateJobMatch from '../pages/Create/CreateJobMatch';
import JobMatchesPage from '../pages/JobMatches';
import JobScore from '../pages/JobScore';
import JobXrayView from '../pages/JobXrayView';
import JobXrays from '../pages/JobXrays';
import OnboardingPage from '../pages/Onboarding';
import OnboardingAiCustomizedResume from '../pages/Onboarding/components/OnboardingAiCustomizedResume';
import OnboardingBlankResumeBuilder from '../pages/Onboarding/components/OnboardingBlankResumeBuilder';
import OnboardingImportResume from '../pages/Onboarding/components/OnboardingImportResume';
import OnboardingJobMatch from '../pages/Onboarding/components/OnboardingJobMatch';
import OnboardingJobXray from '../pages/Onboarding/components/OnboardingJobXray';
import OnboardingResumeBuilder from '../pages/Onboarding/components/OnboardingResumeBuilder';
import OnboardingSampleResumeBuilder from '../pages/Onboarding/components/OnboardingSampleResumeBuilder';
import ProfileSettings from '../pages/ProfileSettings';
import ResumeCreate from '../pages/ResumeCreate';
import BlankResumeBuilder from '../pages/ResumeCreate/component/BlankResumeBuilder';
import ResumeImport from '../pages/ResumeCreate/component/ResumeImport';
import SampleResumeBuilder from '../pages/ResumeCreate/component/SampleResumeBuilder';
import ResumePage from '../pages/ResumePage';
import ResumeReorder from '../pages/ResumeReorder';
import Resumes from '../pages/Resumes';
import SignInPage from '../pages/SignIn';
import SignUpPage from '../pages/SignUp';
import VerificationPage from '../pages/Verification';
import RoutePaths from './RoutePaths';

interface RouteType {
  path: string;
  element: React.ReactNode;
  children?: RouteType[];
}

export const publicRoutes: RouteType[] = [
  {
    path: RoutePaths.HOME,
    // TODO: check for onboarding flag
    element: <JobMatchesPage />,
  },
  {
    path: RoutePaths.SIGN_IN,
    element: <SignInPage />,
  },
  {
    path: RoutePaths.SIGN_UP,
    element: <SignUpPage />,
  },
  {
    path: RoutePaths.VERIFICATION,
    element: <VerificationPage />,
  },
];

export const protectedRoutes: RouteType[] = [
  {
    path: RoutePaths.ONBOARDING,
    element: <OnboardingPage />,
    children: [
      { path: RoutePaths.ONBOARDING_JOB_MATCH, element: <OnboardingJobMatch /> },
      { path: RoutePaths.ONBOARDING_JOB_XRAY, element: <OnboardingJobXray /> },
      {
        path: RoutePaths.ONBOARDING_RESUME_AI_CUSTOMIZE,
        element: <OnboardingAiCustomizedResume />,
      },
      { path: RoutePaths.ONBOARDING_RESUME_CREATE, element: <OnboardingResumeBuilder /> },
    ],
  },
  // TODO: R&D and implement a fix for multi-nested routes
  { path: RoutePaths.ONBOARDING_RESUME_IMPORT, element: <OnboardingImportResume /> },
  { path: RoutePaths.ONBOARDING_RESUME_BLANK, element: <OnboardingBlankResumeBuilder /> },
  { path: RoutePaths.ONBOARDING_RESUME_SAMPLE, element: <OnboardingSampleResumeBuilder /> },
  {
    path: RoutePaths.RESUMES,
    element: <Resumes />,
  },
  {
    path: RoutePaths.JOBMATCHES,
    element: <JobMatchesPage />,
  },
  {
    path: RoutePaths.JOB_SCORE,
    element: <JobScore />,
  },
  {
    path: RoutePaths.JOBS,
    element: <JobXrays />,
  },
  {
    path: RoutePaths.RESUME_HANDLER,
    element: <ResumePage />,
  },
  { path: RoutePaths.RESUME_EDIT, element: <ResumeEditor /> },
  {
    // TODO: use nested routes
    path: RoutePaths.RESUME_REORDER,
    element: <ResumeReorder />,
  },
  {
    path: RoutePaths.CREATE_JOB_MATCH,
    element: <CreateJobMatch />,
  },
  {
    path: RoutePaths.CREATE_JOB,
    element: <CreateJob />,
  },
  { path: RoutePaths.AI_CUSTOMIZED, element: <AiCustomizedResume /> },
  { path: RoutePaths.JOB_XRAY, element: <JobXrayView /> },
  {
    path: RoutePaths.SETTINGS,
    element: <ProfileSettings />,
    children: [
      { path: 'account', element: <ProfileSettings /> },
      { path: 'billing', element: <ProfileSettings /> },
    ],
  },
  {
    path: RoutePaths.RESUME_CREATE,
    element: <ResumeCreate />,
    // Nested routes
    // children: [
    //   { path: '', element: <ResumeCreate /> },
    //   { path: 'import', element: <ResumeImport /> },
    //   { path: 'sample', element: null },
    //   { path: 'blank', element: null },
    // ],
  },
  {
    path: RoutePaths.RESUME_IMPORT,
    element: <ResumeImport />,
  },
  {
    path: RoutePaths.RESUME_BLANK,
    element: <BlankResumeBuilder />,
  },
  {
    path: RoutePaths.RESUME_SAMPLE,
    element: <SampleResumeBuilder />,
  },
];

export const routes = [
  ...publicRoutes,
  ...protectedRoutes,
  // TODO: add 404 page
];

export default routes;
