import ReactGA from 'react-ga4';

import { PROD_GA_MEASUREMENT_ID, STAGING_GA_MEASUREMENT_ID } from '../configs';

export enum EventCategory {
  USER_INTERACTION = 'User Interaction',
  NAVIGATION = 'Navigation',
  FORM_SUBMISSION = 'Form Submission',
  ERROR = 'Error',
}

export enum EventAction {
  CLICK = 'Click',
  SUBMIT = 'Submit',
  NAVIGATE = 'Navigate',
  DOWNLOAD = 'Download',
  VIEW = 'View',
  ERROR = 'Error',
}

export const initGA = () => {
  // TODO: if predev then disable GA

  // if url is staging or localhost, use staging GA measurement id
  const isStaging =
    window.location.hostname.includes('localhost') ||
    window.location.hostname.includes('staging.internal') ||
    window.location.hostname.includes('predev.internal');
  const measurementId = isStaging ? STAGING_GA_MEASUREMENT_ID : PROD_GA_MEASUREMENT_ID;
  ReactGA.initialize(measurementId);
};

export const logPageView = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

export const logEvent = (category: EventCategory, action: EventAction, label?: string) => {
  ReactGA.event({
    category, // Logical grouping of events
    action, // Specific action taken by the user
    label, // Optional label for additional context
  });
};

export const logException = (description: string) => {
  ReactGA.event({
    category: EventCategory.ERROR,
    action: EventAction.ERROR,
    label: description,
  });
};
