import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Button from '../../../components/common/Button';
import Logo from '../../../components/common/Logo';
import SelectCard from '../../../components/common/SelectCard';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import RoutePaths from '../../../routes/RoutePaths';
import theme from '../../../theme/theme';
import { isNilOrEmpty } from '../../../utils';
import { CreateResumeKey, resumeCreateOptions } from '../../ResumeCreate/constants';
import OnboardingCard from './OnboardingCard';

/**
 * The `OnboardingResumeBuilder` component guides users through the onboarding process
 * for creating a resume. It presents three options: importing an existing resume,
 * creating a blank resume, or generating a sample resume based on a job description.
 *
 * This component is part of the onboarding flow and ensures users can select their preferred
 * method for resume creation before proceeding to the respective steps.
 *
 * Features:
 * - Displays three selectable options using the `SelectCard` component.
 * - Responsive design with optimized layouts for mobile and desktop devices.
 * - "Back" and "Next" buttons to navigate between onboarding steps.
 *
 * @component
 * @returns {JSX.Element} Rendered `OnboardingResumeBuilder` component.
 *
 * @example
 * // Basic usage:
 * <OnboardingResumeBuilder />
 *
 * Navigation Details:
 * - Back button: Returns to the previous onboarding step using the browser's history.
 * - Next button: Navigates to a specific route based on the selected option:
 *    - Import Resume: Navigates to `RoutePaths.ONBOARDING_RESUME_IMPORT`
 *    - Blank Resume: Navigates to `RoutePaths.ONBOARDING_RESUME_BLANK`
 *    - Sample Resume: Navigates to `RoutePaths.ONBOARDING_RESUME_SAMPLE`
 *
 * Props:
 * - This component does not accept external props as it manages its state internally.
 *
 */
const OnboardingResumeBuilder = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const [selectedKey, setSelectedKey] = useState(CreateResumeKey.IMPORT);

  /**
   * Navigates back to the previous page in the onboarding flow.
   */
  const onBackClick = () => {
    navigate(-1);
  };

  /**
   * Handles navigation based on the selected resume creation option.
   * Redirects to different routes defined in `RoutePaths`.
   */
  const onNextClick = () => {
    switch (selectedKey) {
      case CreateResumeKey.IMPORT:
        navigate(RoutePaths.ONBOARDING_RESUME_IMPORT);
        break;
      case CreateResumeKey.BLANK_RESUME:
        navigate(RoutePaths.ONBOARDING_RESUME_BLANK);
        break;
      case CreateResumeKey.SAMPLE_RESUME:
        navigate(RoutePaths.ONBOARDING_RESUME_SAMPLE);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* Displays the JobQuest logo at the top */}
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <Logo style={{ height: theme.spacing(6.25) }} />
      </Box>

      {/* Card container for the onboarding content */}
      <OnboardingCard
        rootSx={{ height: `calc(100% - ${theme.spacing(8.25)})` }}
        cardContentProps={{
          sx: { alignItems: 'unset' },
        }}
      >
        {/* Grid layout for the title, description, and options */}
        <Grid spacing={2} container>
          <Grid xs={12} sx={{ paddingTop: 0, paddingBottom: 3 }}>
            <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ paddingBottom: 1.5 }}>
              How would you like to create your resume?
            </Typography>
            <Typography variant="body2">
              Choose how you want to create your resume. Whether building or refining your resume,
              we make it simple and efficient.
            </Typography>
          </Grid>
          {/* Map through available options and render as `SelectCard` */}
          {resumeCreateOptions.map((item) => (
            <Grid key={item.key} xs={12} sx={{ width: '100%' }}>
              <SelectCard
                cardContent={item}
                sx={{
                  borderColor: selectedKey === item.key ? 'primary.main' : 'border.light',
                  height: '100%',
                }}
                onClick={() => setSelectedKey(item.key)}
              />
            </Grid>
          ))}
        </Grid>
        {/* TODO: Create a generic component */}
        {/* Navigation buttons */}
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: 1.5,
          }}
        >
          <Button
            variant="outlined"
            onClick={onBackClick}
            sx={{
              width: { xs: '30%', sm: 'fit-content' },
              ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
            }}
          >
            Back
          </Button>
          <Button
            disabled={isNilOrEmpty(selectedKey)}
            onClick={onNextClick}
            sx={{
              width: { xs: '70%', sm: 'fit-content' },
              ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
            }}
          >
            Next
          </Button>
        </Stack>
      </OnboardingCard>
    </>
  );
};

export default OnboardingResumeBuilder;
