export const subscriptionPlansStatic = [
  {
    id: 'basic',
    planName: 'Standard',
    amount: 0,
    isRecurring: true,
    recurringPeriod: 'monthly',
    nextInvoiceDate: new Date('2023-11-01'),
    features: [
      {
        label: 'Resume Builder',
        subText: 'Purpose-built resume editor',
      },
      {
        label: 'Resume Download',
        subText: 'Unlimited resume downloads',
      },
      {
        label: 'ATS Templates',
        subText: 'Applicant Tracking System-friendly resume templates',
      },
      {
        label: 'Multiple Resumes',
        subText: 'Editing and managing multiple versions of resumes',
      },
    ],
  },
  {
    id: 'premium',
    planName: 'Pro',
    amount: 9.99,
    isRecurring: true,
    recurringPeriod: 'monthly',
    nextInvoiceDate: new Date('2023-11-01'),
    features: [
      {
        label: 'AI Resume Scans',
        subText: 'Import resumes for editing',
      },
      {
        label: 'AI Job X-Ray',
        subText: 'Highlights of job description',
      },
      {
        label: 'Job Match Score',
        subText: 'Resume match score & gap analysis for a job description',
      },
      {
        label: 'AI Resume Customization',
        subText: 'Tailoring a resume to a job description in a single-click',
      },
      {
        label: 'Super Editor',
        subText: 'AI-powered resume editor',
      },
    ],
  },
];
