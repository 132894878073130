import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Stack, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import Button from '../../../components/common/Button';
import Logo from '../../../components/common/Logo';
import { useSnackbar } from '../../../contexts/snackbar';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import RoutePaths from '../../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { ResumesKeys, createResume } from '../../../services/resumes';
import theme from '../../../theme/theme';
import { isNilOrEmpty } from '../../../utils';
import ChooseCareerStage from '../../ResumeCreate/component/ChooseCareerStage';
import type { CareerStageKey } from '../../ResumeCreate/constants';
import OnboardingCard from './OnboardingCard';

/**
 * `OnboardingBlankResumeBuilder` component facilitates the onboarding process for creating a blank resume.
 * Users can select their career stage, and the resume's sections are determined accordingly.
 *
 * @component
 * @returns {JSX.Element} Rendered `OnboardingBlankResumeBuilder` component.
 *
 * @example
 * // Basic usage:
 * <OnboardingBlankResumeBuilder />
 */
const OnboardingBlankResumeBuilder = () => {
  const [selectedCareerStage, setSelectedCareerStage] = useState<CareerStageKey>();

  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const queryClient = useQueryClient();

  /**
   * `createBlankResume` mutation handles the process of creating a blank resume.
   * On success:
   * - Invalidates the resumes cache to fetch updated data.
   * - Logs a success event using analytics.
   * - Shows a success message in the snackbar.
   * - Navigates to the resume handler page.
   *
   * On failure:
   * - Displays an error message in the snackbar.
   */
  const { isLoading: isCreatingResume, mutate: createBlankResume } = useMutation({
    retry: 3,
    mutationFn: createResume,
    onSuccess: (data) => {
      queryClient.invalidateQueries([ResumesKeys.RESUMES]); // invalidate, so that updated resumes are fetched
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Onboarding Blank Resume Creation Successful',
      );
      showSnackbar('success', 'Blank resume created successfully.');
      navigate(RoutePaths.RESUME_HANDLER.replace(':id', data?.id));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to create a blank Resume. Please try again.',
      );
    },
  });

  /**
   * Navigates back to the previous page in the onboarding flow.
   */
  const onBackClick = () => {
    navigate(-1);
  };

  /**
   * Handles the create button click, triggering the `createBlankResume` mutation.
   */
  const onCreateClick = (): void => {
    if (selectedCareerStage) {
      createBlankResume({ careerStage: selectedCareerStage });
    }
  };

  return (
    <Box
      sx={{
        padding: { xs: `${theme.spacing(2)} ${theme.spacing(0.5)}`, sm: 2 },
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {/* Displays the JobQuest logo at the top */}
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <Logo style={{ height: theme.spacing(6.25) }} />
      </Box>
      {/* Card container for the onboarding content */}
      <OnboardingCard
        rootSx={{ height: { md: `calc(100% - ${theme.spacing(8.25)})` } }}
        cardContentProps={{ sx: { gap: 4, alignItems: 'unset' } }}
      >
        <Stack sx={{ gap: 4 }}>
          {/* Header Section: Includes the title and description of the blank resume creation */}
          <Stack sx={{ gap: 1.5, width: '100%' }}>
            <Typography variant={isMobile ? 'h2' : 'h1'}>Create Blank Resume</Typography>
            <Typography variant="body2">
              Build your resume from scratch with our Super Editor. Your resume sections are
              automatically determined based on your career stage. You can edit them later.
            </Typography>
          </Stack>
          {/* Career Stage Selector: Allows the user to select their career stage */}
          <ChooseCareerStage
            onChange={setSelectedCareerStage}
            headingProps={{ sx: { textAlign: 'left' }, paddingX: 0 }}
            contentProps={{ lg: 6 }}
          />
        </Stack>

        {/* TODO: Create a generic component */}
        {/* Action buttons */}
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            gap: 1.5,
          }}
        >
          <Button
            variant="outlined"
            onClick={onBackClick}
            sx={{
              width: { xs: '30%', sm: 'fit-content' },
              ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
            }}
          >
            Back
          </Button>
          <Button
            onClick={onCreateClick}
            sx={{
              width: { xs: '70%', sm: 'fit-content' },
              padding: {
                xs: `${theme.spacing(2)} ${theme.spacing(1.5)}`,
                sm: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
              },
            }}
            loading={isCreatingResume}
            disabled={isNilOrEmpty(selectedCareerStage)}
          >
            Create
          </Button>
        </Stack>
      </OnboardingCard>
    </Box>
  );
};

export default OnboardingBlankResumeBuilder;
