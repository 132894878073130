import { useLayoutEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Box, useTheme } from '@mui/material';

import Logo from '../../components/common/Logo';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import { useAuthStore } from '../../stores/AuthStore';
import ChooseOnboardingFlow from './components/ChooseOnboardingFlow';
import Welcome from './components/Welcome';

enum SelectedOnboardingSection {
  WELCOME = 'welcome',
  ONBOARDING_FLOW_SELECTION = 'onboarding-flow-selection',
}

const OnboardingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { isOnboardingCompleted } = useAuthStore();
  const [selectedSection, setSelectedSection] = useState<SelectedOnboardingSection>(
    SelectedOnboardingSection.WELCOME,
  );

  // if onboarding true, then redirect to job matches page
  useLayoutEffect(() => {
    if (isOnboardingCompleted) {
      navigate(RoutePaths.JOBMATCHES);
    }
  }, [isOnboardingCompleted, navigate]);

  const onBackClick = () => {
    setSelectedSection(SelectedOnboardingSection.WELCOME);
  };

  const onGetStartedClick = () => {
    logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Onboarding Welcome Next Click');
    setSelectedSection(SelectedOnboardingSection.ONBOARDING_FLOW_SELECTION);
  };

  return (
    <Box
      sx={{
        padding: { xs: `${theme.spacing(2)} ${theme.spacing(0.5)}`, sm: 2 },
        height: '100%',
        boxSizing: 'border-box',
      }}
    >
      {location.pathname === RoutePaths.ONBOARDING && (
        <>
          <Box sx={{ marginBottom: theme.spacing(2) }}>
            <Logo style={{ height: theme.spacing(6.25) }} />
          </Box>
          {selectedSection === SelectedOnboardingSection.WELCOME && (
            <Welcome onGetStartedClick={onGetStartedClick} />
          )}
          {selectedSection === SelectedOnboardingSection.ONBOARDING_FLOW_SELECTION && (
            <ChooseOnboardingFlow onBackClick={onBackClick} />
          )}
        </>
      )}
      <Outlet />
    </Box>
  );
};

export default OnboardingPage;
