import http from './http';

export enum BillingKeys {
  INVOICES = 'invoices',
  CURRENT_SUBSCRIPTION_PLAN = 'current_subscription_plan',
  SUBSCRIPTION_PLANS = 'subscription_plans',
}

/**
 * Enum representing keys used in the billing service.
 *
 * @enum {string}
 * @property {string} INVOICES - Key for invoices.
 * @property {string} CURRENT_SUBSCRIPTION_PLAN - Key for a single subscription plan.
 * @property {string} SUBSCRIPTION_PLANS - Key for multiple subscription plans.
 */
export enum PlanTypes {
  STANDARD = 'Standard',
  TRIAL = 'Trial',
  PRO = 'Pro',
}

/**
 * Represents the feature flags available in the application.
 * Each flag is a boolean indicating whether a specific feature is enabled or disabled.
 *
 * @typedef {Object} FeatureFlags
 * @property {boolean} IMPORT_RESUME - Flag to enable or disable the resume import feature.
 * @property {boolean} AI_CUSTOMIZE_RESUME - Flag to enable or disable AI customization of resumes.
 * @property {boolean} CREATE_JOB_MATCH_SCORE - Flag to enable or disable job match score creation.
 * @property {boolean} GENERATE - Flag to enable or disable the generate feature.
 * @property {boolean} COMPOSE - Flag to enable or disable the compose feature.
 * @property {boolean} AUTOFIX - Flag to enable or disable the autofix feature.
 * @property {boolean} SUPERCHARGE - Flag to enable or disable the supercharge feature.
 * @property {boolean} JD_IMPORT - Flag to enable or disable job description import.
 * @property {boolean} SAMPLE_RESUME - Flag to enable or disable the sample resume feature.
 * @property {boolean} BLANK_RESUME - Flag to enable or disable the blank resume feature.
 */
export type FeatureFlags = {
  IMPORT_RESUME: boolean;
  AI_CUSTOMIZE_RESUME: boolean;
  CREATE_JOB_MATCH_SCORE: boolean;
  GENERATE: boolean;
  COMPOSE: boolean;
  AUTOFIX: boolean;
  SUPERCHARGE: boolean;
  JD_IMPORT: boolean;
  SAMPLE_RESUME: boolean;
  BLANK_RESUME: boolean;
};

/**
 * Represents the status of an account.
 *
 * @typedef {Object} AccountStatus
 * @property {string} id - The unique identifier for the account.
 * @property {PlanTypes} subscription - The type of subscription plan the account is on.
 * @property {string} planEndData - The date when the current plan ends.
 * @property {boolean} forceRefreshToken - Indicates whether a token refresh is forced.
 * @property {FeatureFlags} featureFlags - The feature flags associated with the account.
 * @property {boolean} isPlanCancelled - Indicates whether the current plan is cancelled.
 */
export type AccountStatus = {
  id: string;
  subscription: PlanTypes;
  planEndData: string;
  forceRefreshToken: boolean;
  featureFlags: FeatureFlags;
  isPlanCancelled: boolean;
};

/**
 * Represents a receipt or invoice for a billing transaction.
 */
export interface Receipt {
  id: string;
  invoiceDate: string;
  dueDate: string;
  paidDate: string;
  amount: string;
  status: string;
  url: string;
}

/**
 * Represents a Stripe plan.
 *
 * @interface StripePlan
 * @property {string} id - The unique identifier for the plan.
 * @property {string} name - The name of the plan.
 * @property {string} amount - The amount associated with the plan.
 * @property {string} price_id - The price ID associated with the plan.
 * @property {string} currency - The currency associated with the plan.
 */
export interface StripePlan {
  id: string;
  name: string;
  currency: string;
  amount: string;
  price_id: string;
}

/**
 * Represents a current subscription plan with details about the plan type, amount,
 * recurrence, and next invoice date.
 */
export interface SubscriptionPlan {
  id: string;
  planName: PlanTypes;
  amount: string;
  currency: string;
  isRecurring: boolean;
  recurringPeriod: string;
  nextInvoiceDate: Date;
  isPlanCancelled: boolean;
}

/**
 * Represents a subscription session.
 *
 * @interface SubscriptionSession
 *
 * @property {string} id - The unique identifier for the subscription session.
 * @property {string} url - The URL associated with the subscription session.
 * @property {number} amount_total - The total amount for the subscription session.
 * @property {string} currency - The currency used for the subscription session.
 * @property {Object} customer_details - The details of the customer.
 * @property {string} customer_details.email - The email of the customer.
 * @property {string} customer_details.name - The name of the customer.
 * @property {string} customer_details.address - The address of the customer.
 * @property {string} customer_details.phone - The phone number of the customer.
 * @property {string} customer_details.tax_exempt - The tax exemption status of the customer.
 * @property {string} customer_details.tax_ids - The tax IDs of the customer.
 * @property {number} expires_at - The expiration timestamp of the subscription session.
 * @property {string} status - The status of the subscription session.
 */
export interface SubscriptionSession {
  id: string;
  url: string;
  amount_total: number;
  currency: string;
  customer_details: {
    email: string;
    name: string;
    address: string;
    phone: string;
    tax_exempt: string;
    tax_ids: string;
  };
  expires_at: number;
  status: string;
}

/**
 * Interface representing a payment management session object.
 *
 * @interface ManagePaymentSession
 *
 * @property {string} id - Unique identifier for the payment session.
 * @property {string} object - Type of the object, typically a string identifier.
 * @property {string} configuration - Configuration settings for the payment session.
 * @property {number} created - Timestamp indicating when the payment session was created.
 * @property {string} customer - Identifier for the customer associated with the payment session.
 * @property {string | null} flow - Flow type of the payment session, can be null.
 * @property {boolean} livemode - Indicates if the session is in live mode.
 * @property {string | null} locale - Locale setting for the payment session, can be null.
 * @property {string | null} on_behalf_of - Identifier for the entity on whose behalf the payment session is created, can be null.
 * @property {string} return_url - URL to which the customer is redirected after the payment session.
 * @property {string} url - URL of the payment session.
 */
export interface ManagePaymentSession {
  id: string;
  object: string;
  configuration: string;
  created: number;
  customer: string;
  flow: string | null;
  livemode: boolean;
  locale: string | null;
  on_behalf_of: string | null;
  return_url: string;
  url: string;
}

/**
 * Fetches the list of invoices from the billing service.
 *
 * @returns {Promise<Receipt[]>} A promise that resolves to an array of `Receipt` objects if the request is successful.
 * @throws {Error} If the request fails or the response contains errors.
 */
export const getInvoices = async (): Promise<Receipt[]> => {
  try {
    const res = await http.get('/billing/invoices/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data?.result);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Fetches the current subscription plan of the user.
 *
 * @returns {Promise<SubscriptionPlan>} A promise that resolves to the current subscription plan if the request is successful.
 * @throws {Error} If the request fails or the response contains errors.
 */
export const getCurrentSubscription = async (): Promise<SubscriptionPlan> => {
  try {
    const res = await http.get('/billing/current_plan/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Fetches the feature flags for the billing service.
 *
 * @returns {Promise<FeatureFlags>} A promise that resolves to the feature flags data if the request is successful.
 * @throws {Error} If the request fails or the response contains errors.
 * @example
 * ```typescript
 * const flags = await getFeatureFlags();
 * console.log(flags);
 * ```
 */
export const getFeatureFlags = async (): Promise<FeatureFlags> => {
  try {
    const res = await http.get('/billing/feature_flags/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Fetches availble subscription plans on stripe from the billing service.
 *
 * @returns {Promise<StripePlan[]>} A promise that resolves to an array of StripePlan objects if the request is successful.
 * @throws {Error} If the request fails or the response contains errors.
 */
export const getSubcriptionPlans = async (): Promise<StripePlan[]> => {
  try {
    const res = await http.get('/billing/subscriptions/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data?.result);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Retrieves the account status from the billing service.
 *
 * @returns {Promise<AccountStatus>} A promise that resolves to the account status if the request is successful.
 * @throws {Error} If the request fails or the response contains errors.
 */
export const getAccountStatus = async (): Promise<AccountStatus> => {
  try {
    const res = await http.get('/billing/account_status/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Creates a subscription session by calling the backend with the provided price ID.
 *
 * This function sends a request to the backend to create a Stripe subscription session.
 * The backend processes the request and returns the subscription session information.
 *
 * @param {string} price_id - The ID of the price for the subscription.
 * @returns {Promise<SubscriptionSession>} - A promise that resolves to the subscription session information returned by the backend.
 *
 * @throws {Error} If the request fails or the response contains errors.
 */
export const createSubscriptionSession = async (price_id: string): Promise<SubscriptionSession> => {
  try {
    const res = await http.post(`/billing/subscribe/${price_id}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Fetches the session data for managing payment methods.
 *
 * This function sends a GET request to the `/billing/manage_payment_methods/` endpoint
 * and returns the session data if the request is successful.
 *
 * @returns {Promise<ManagePaymentSession>} A promise that resolves with the session data if the request is successful,
 * or rejects with an error if the request fails.
 *
 * @throws {Error} If the request fails or the response contains errors.
 */
export const getManagePaymentMethodSession = async (): Promise<ManagePaymentSession> => {
  try {
    const res = await http.get('/billing/manage_payment_methods/');
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Cancels a subscription with the given subscription ID and reason.
 *
 * @param {Object} params - The parameters for cancelling the subscription.
 * @param {string} params.subId - The ID of the subscription to cancel.
 * @param {string} params.reason - The reason for cancelling the subscription.
 * @returns {Promise<void>} A promise that resolves if the cancellation is successful, or rejects with an error.
 *
 * @throws {Error} If the cancellation request fails.
 */
export const cancelSubscription = async ({
  subId,
  reason,
}: {
  subId: string;
  reason: string;
}): Promise<void> => {
  try {
    const res = await http.delete(`/billing/subscription/${subId}`, {
      data: { reason },
    });
    if (res?.data?.status === 'success') {
      return await Promise.resolve();
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * Re-subscribes a user to a subscription, if the subscription was previously cancelled.
 *
 * @param {string} subId - The ID of the subscription to re-subscribe to.
 * @returns {Promise<SubscriptionSession>} A promise that resolves to the subscription session if successful, or rejects with an error.
 *
 * @throws {Error} If the HTTP request fails or the response contains errors.
 */
export const reSubscribe = async (subId: string): Promise<SubscriptionSession> => {
  try {
    const res = await http.patch(`/billing/subscription/${subId}`);
    if (res?.data?.status === 'success') {
      return await Promise.resolve(res.data?.data);
    }
    return await Promise.reject(new Error(res?.data?.errors));
  } catch (error) {
    return Promise.reject(error);
  }
};
