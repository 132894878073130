import type { ChangeEvent } from 'react';
import { useState } from 'react';

import { Box, Stack, Typography, useTheme } from '@mui/material';

import FileDropzone from '../../../components/FileDropzone';
import JobDescriptionInput from '../../../components/JobDescriptionInput';
import Button from '../../../components/common/Button';
import Logo from '../../../components/common/Logo';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import { isNilOrEmpty } from '../../../utils';
import OnboardingCard from './OnboardingCard';

interface UploadOnboardingProfileProps {
  onCreateClick: ({ description, file }: { description: string; file?: File }) => void;
  enableInputSection?: { resume: boolean; jobDescription: boolean };
  onBackClick: () => void;
  isLoading?: boolean;
  title?: string;
  description?: string;
  buttonLabel?: string; // New prop for button label
}

/**
 * `UploadOnboardingProfile` is a component that facilitates the user onboarding process by allowing
 * users to upload a job description and resume file, then triggers a callback to process this data.
 * It includes options for creating a profile and navigating back.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {(data: { description: string; file?: File }) => void} props.onCreateClick - Callback function triggered when the "Create" button is clicked with valid data.
 * @param {Object} props.enableInputSection - Configures which input sections are enabled (resume and/or job description).
 * @param {boolean} props.enableInputSection.resume - Flag to enable/disable the resume input section.
 * @param {boolean} props.enableInputSection.jobDescription - Flag to enable/disable the job description input section.
 * @param {() => void} props.onBackClick - Callback function triggered by the "Back" button to handle navigation.
 * @param {boolean} [props.isLoading=false] - Indicates if an upload action is in progress, disabling the "Create" button if true.
 * @param {string} [props.title] - Title text displayed at the top of the onboarding card.
 * @param {string} [props.description] - Descriptive text displayed beneath the title.
 * @param {string} [props.buttonLabel="Create"] - Label text for the action button; defaults to "Create" but can be customized, e.g., to "Tailor".
 *
 * @returns {JSX.Element} - Renders the UploadOnboardingProfile component.
 *
 * @example
 * // Example usage of UploadOnboardingProfile component:
 * const handleCreate = ({ description, file }) => {
 *   // Handle data processing for job description and resume file
 *   console.log("Job Description:", description);
 *   console.log("Resume File:", file);
 * };
 *
 * const handleBack = () => {
 *   // Handle back navigation
 *   console.log("Navigating back...");
 * };
 *
 * <UploadOnboardingProfile
 *   onCreateClick={handleCreate}
 *   onBackClick={handleBack}
 *   isLoading={false}
 *   title="Create Job Match Score"
 *   description="Match your resume to the job description to check alignment and receive a detailed score report."
 *   enableInputSection={{ resume: true, jobDescription: true }}
 *   buttonLabel="Tailor" // Custom button label
 * />
 */

const UploadOnboardingProfile = ({
  onCreateClick,
  onBackClick,
  enableInputSection = { resume: true, jobDescription: true },
  isLoading,
  title,
  description,
  buttonLabel = 'Create', // Default label
}: UploadOnboardingProfileProps) => {
  const [jobDescription, setJobDescription] = useState<string>();
  const [file, setFile] = useState<File>();
  const { isMobile } = useResponsiveDevice();
  const theme = useTheme();

  const handleCreateClick = () => {
    onCreateClick({
      description: enableInputSection.jobDescription ? jobDescription || '' : '',
      file: enableInputSection.resume ? file : undefined,
    });
  };

  const handleUpdateJobDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setJobDescription(event.target.value);
  };

  return (
    <>
      <Box sx={{ marginBottom: theme.spacing(2) }}>
        <Logo style={{ height: theme.spacing(6.25) }} />
      </Box>
      <OnboardingCard
        rootSx={{ height: `calc(100% - ${theme.spacing(8.25)})` }}
        cardContentProps={{ sx: { gap: 4, justifyContent: 'flex-start' } }}
      >
        <Stack sx={{ gap: 1.5, width: '100%' }}>
          <Typography variant={isMobile ? 'h2' : 'h1'}>{title}</Typography>
          <Typography variant="body2">{description}</Typography>
        </Stack>
        <Stack sx={{ justifyContent: 'space-between', gap: 3, height: '100%' }}>
          <Stack gap={2.5}>
            {enableInputSection.resume && (
              <FileDropzone onFileChange={(inputFile: File | undefined) => setFile(inputFile)} />
            )}
            {enableInputSection.jobDescription && (
              <JobDescriptionInput onUpdateDescription={handleUpdateJobDescription} />
            )}
          </Stack>
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: 1.5,
            }}
          >
            <Button
              variant="outlined"
              onClick={onBackClick}
              sx={{
                width: { xs: '30%', sm: 'fit-content' },
                ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
              }}
            >
              Back
            </Button>
            <Button
              disabled={
                isLoading ||
                (enableInputSection.jobDescription && isNilOrEmpty(jobDescription)) ||
                (enableInputSection.resume && isNilOrEmpty(file))
              }
              onClick={handleCreateClick}
              sx={{
                width: { xs: '70%', sm: 'fit-content' },
                ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
              }}
            >
              {buttonLabel} {/* Use the buttonLabel prop here */}
            </Button>
          </Stack>
        </Stack>
      </OnboardingCard>
    </>
  );
};

export default UploadOnboardingProfile;
