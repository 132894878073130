import { Global } from '@emotion/react';
import type { SwipeableDrawerProps as MuiSwipeableDrawerProps, StackProps } from '@mui/material';
import { Box, SwipeableDrawer as MuiSwipeableDrawer, Stack } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

const drawerBleeding = 56;

export const DrawerContent = styled(Stack)({
  height: '100%',
  padding: 16,
  textAlign: 'center',
  alignItems: 'center',
});

export const Puller = styled(Box)(({ theme }) => ({
  width: 54,
  height: 4,
  backgroundColor: theme.palette.text.tertiary,
  borderRadius: 12,
}));

interface SwipeableDrawerProps extends MuiSwipeableDrawerProps {
  contentProps?: StackProps;
}

const SwipeableDrawer = ({
  open,
  onOpen,
  onClose,
  children,
  contentProps,
  ...restProps
}: SwipeableDrawerProps) => {
  const theme = useTheme();

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: 'auto',
            overflow: 'visible',
            borderRadius: '12px 12px 0px 0px',
          },
        }}
      />
      <MuiSwipeableDrawer
        anchor="bottom"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen
        ModalProps={{
          keepMounted: true,
        }}
        sx={{ zIndex: theme.zIndex.drawer + 1 }}
        {...restProps}
      >
        <DrawerContent {...contentProps}>{children}</DrawerContent>
      </MuiSwipeableDrawer>
    </>
  );
};

export default SwipeableDrawer;
