import { isEmpty, isNil, isNotNil } from 'ramda';

import type { MissingFields } from '../components/ProgressManager/constants';
import { ProgressScreenType, missingFieldMap } from '../components/ProgressManager/constants';
import { PDFFontSizes } from '../hooks/useHTMLRenderer';
import type { ResumeTemplateKey } from '../interface';
import type { JobProgressSource } from '../services/jobDescriptions';
import {
  Storage,
  getShowLogInSnackbarStatus,
  setShowLogInSnackbarStatus,
} from '../services/storage';
import { DraftType } from '../stores/SuperEditorDrafts';

enum TemplateTypes {
  Standard = 'standard',
  Modern = 'modern',
}
export interface ScoreColumnProps {
  progress: number;
  score: number;
  status: string;
  source: JobProgressSource;
  id: string;
}

export interface MissingInfoContent {
  missingFields: MissingFields[] | undefined;
  missingFieldsEndpoint: string;
  jobDescriptionId: string;
  hasMissingFields: boolean;
}

export const isIOSDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const getResumeTemplateBaseFontSize = (templateKey: ResumeTemplateKey) => {
  const pattern = new RegExp(`${TemplateTypes.Modern}`);
  if (pattern.test(templateKey)) {
    return PDFFontSizes['9px'];
  }
  return PDFFontSizes['10px'];
};

export const getJobDescId = (url: string) => {
  const urlArray = url.split('job-matches/');
  if (urlArray.length === 2) {
    return urlArray[1];
  }
  return null;
};

/**
 * Returns the icon corresponding to the given draft type.
 * @param type - The draft type.
 * @returns The icon class name.
 */
export const getDraftsIcon = (type: DraftType, disabled = false) => {
  if (disabled) {
    return 'fi fi-rr-lock';
  }

  switch (type) {
    case DraftType.GENERATE:
      return 'fi fi-rr-magic-wand';
    case DraftType.AUTOFIX:
      return 'fi fi-rr-book-spells';
    case DraftType.SUPERCHARGE:
      return 'fi fi-rr-thunderstorm';
    default:
      return 'fi fi-rr-magic-wand';
  }
};

/**
 * Returns the title text for a draft based on its type, status, section title, and index.
 * @param type - The type of the draft.
 * @param status - The status of the draft.
 * @param sectionTitle - The title of the section.
 * @param index - The index of the draft.
 * @returns The title text for the draft.
 */
export const getDraftTitleText = (
  type: DraftType,
  status: string,
  sectionTitle: string,
  index: number,
) => {
  if (status === 'loading') {
    switch (type) {
      case DraftType.GENERATE:
        return `Generating ${sectionTitle}`;
      case DraftType.AUTOFIX:
        return `Autofixing ${sectionTitle}`;
      case DraftType.SUPERCHARGE:
        return `Supercharging ${sectionTitle}`;
      default:
        return `Generating ${sectionTitle}`;
    }
  } else {
    return `Draft ${index + 1}`;
  }
};

/**
 * Returns the tooltip text based on the draft type, section title, disabled state, and maximum drafts allowed.
 * @param type - The type of draft (GENERATE, AUTOFIX, SUPERCHARGE).
 * @param sectionTitle - The title of the section.
 * @param disabled - Indicates if the tooltip should be shown as disabled.
 * @param isMaxDraft - Indicates if the maximum number of drafts has been reached.
 * @param maxDrafts - The maximum number of drafts allowed.
 * @returns The tooltip text based on the input parameters.
 */
export const getDraftTooltipText = (
  type: DraftType, // TODO: consider using object instead of multiple params
  sectionTitle: string,
  disabled: boolean,
  isMaxDraft: boolean,
  maxDrafts = 2,
  overrideDisabledText?: string | React.ReactNode,
) => {
  if (isMaxDraft) {
    let typeText = 'Generate';

    if (type === DraftType.AUTOFIX) {
      typeText = 'Autofix';
    } else if (type === DraftType.SUPERCHARGE) {
      typeText = 'Supercharge';
    }

    return `Maximum of ${maxDrafts} drafts allowed. Discard a draft to ${typeText}`;
  }

  if (disabled) {
    if (overrideDisabledText) {
      return overrideDisabledText;
    }

    switch (type) {
      case DraftType.GENERATE:
        return `Your most recent work experience must have at least 10 words to generate ${sectionTitle}`;
      case DraftType.AUTOFIX:
        return `Autofix is applicable when the editor has at least one item and 10 words.`;
      case DraftType.SUPERCHARGE:
        return `Supercharge is applicable when the editor has at least one item and 10 words.`;
      default:
        return 'Write something to generate or autofix';
    }
  }

  switch (type) {
    case DraftType.GENERATE:
      return `Generate or regenerate contents`;
    case DraftType.AUTOFIX:
      return `Fix grammar or writing style errors`;
    case DraftType.SUPERCHARGE:
      return `Enrich the write-up with vivid details`;
    default:
      return 'Generate or regenerate contents';
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNotNilOrEmpty = (value: any) => isNotNil(value) && !isEmpty(value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNilOrEmpty = (value: any) => isNil(value) || isEmpty(value);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addSeparator = (value1: any, value2: any, delimiter: string) => {
  if (isNotNilOrEmpty(value1) && isNotNilOrEmpty(value2)) {
    return `${value1}${delimiter}${value2}`;
  }
  if (isNotNilOrEmpty(value1)) {
    return value1;
  }
  if (isNotNilOrEmpty(value2)) {
    return value2;
  }
  return '';
};

export const isNumber = (value: number | null): boolean =>
  typeof value === 'number' && !Number.isNaN(value);

export const isPositiveNumber = (value: number | null): boolean =>
  typeof value === 'number' && !Number.isNaN(value) && value > 0;

export const isNegativeNumber = (value: number | null): boolean =>
  typeof value === 'number' && !Number.isNaN(value) && value <= 0;

export const validateGpa = (gpa: number | null, totalGpa: number | null) => {
  // GPA is null or NaN
  if (!isNumber(gpa)) {
    // Total GPA is greater than 0
    if (isPositiveNumber(totalGpa)) {
      return 'GPA is required';
    }
    // both GPA and Total GPA are not provided
    return true;
  }
  // GPA is negative number
  if (isNegativeNumber(gpa)) {
    return 'Invalid GPA';
  }
  // Both GPA and Total GPA are provided and are positive
  if (
    isPositiveNumber(gpa) &&
    isPositiveNumber(totalGpa) &&
    (gpa as number) > (totalGpa as number)
  ) {
    return 'Your GPA should not be higher than the total';
  }
  return true;
};

export const validateTotalGpa = (gpa: number | null, totalGpa: number | null) => {
  const isValidGpa = validateGpa(gpa, totalGpa);
  // Dont run validations if gpa is not provided (null)
  if (isValidGpa !== true) {
    return true;
  }
  if (!isNumber(totalGpa)) {
    return 'Total GPA is required';
  }
  if (isNegativeNumber(totalGpa)) {
    return 'Invalid Total GPA';
  }
  if ((gpa as number) > (totalGpa as number)) {
    return 'Your Total GPA should not be lower than the GPA';
  }
  return true;
};

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

// TODO: move these utilities to a htmlUtils file
export const getTextFromHtml = (htmlString = '') => htmlString.replace(/<[^>]*>?/gm, '');

export const hasTextInsideHtml = (htmlString = '') => {
  // Remove all HTML tags
  const textOnly = getTextFromHtml(htmlString);

  // Check if the resulting string is empty
  return textOnly.trim() !== '';
};

export const isHtmlString = (str: string) => /<[^>]*>/.test(str);

export const sortScoreColumn = (value1: ScoreColumnProps, value2: ScoreColumnProps) => {
  if (value1.status === value2.status) {
    if (value1.status === 'done') {
      return value1.score - value2.score;
    }
    return 0;
  }
  return value1.status.localeCompare(value2.status);
};

export const getProgressScreenSnackbarMessage = (progressScreenKey: string) => {
  if (progressScreenKey === ProgressScreenType.JobMatchScore) {
    return {
      success: 'Job match score created successfully',
      error: 'Failed to create job match score',
    };
  }
  if (progressScreenKey === ProgressScreenType.AiResumeScan) {
    return { success: 'New resume imported successfully', error: 'Failed to import new resume' };
  }
  if (progressScreenKey === ProgressScreenType.JDXray) {
    return {
      success: 'New Job Description added successfully',
      error: 'Failed to import new Job Description',
    };
  }
  if (progressScreenKey === ProgressScreenType.AiCustomize) {
    return {
      success: 'Resume AI customized successfully',
      error: 'Failed to AI customize resume',
    };
  }
  if (progressScreenKey === ProgressScreenType.SampleResume) {
    return {
      success: 'Sample resume created successfully',
      error: 'Failed to create sample resume',
    };
  }
  return { success: '', error: 'Something went wrong' };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getJobDescriptionProgressStatus = (response: any, key: ProgressScreenType) => {
  switch (key) {
    case ProgressScreenType.JDXray:
      return { ...response, id: response._id };
    case ProgressScreenType.JobMatchScore:
      return response?.job_description;
    case ProgressScreenType.AiCustomize:
    case ProgressScreenType.SampleResume:
      return response?.job_description;
    default:
      return null;
  }
};

// TODO: update types
export const compareProgressStatus = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
  progressScreenKey: ProgressScreenType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  status: any,
) => {
  switch (progressScreenKey) {
    case ProgressScreenType.JDXray:
    case ProgressScreenType.AiResumeScan:
      return response.status === status;
    case ProgressScreenType.JobMatchScore:
      return response?.match_score?.status === status;
    case ProgressScreenType.AiCustomize:
      return response?.ai_customization?.status === status;
    case ProgressScreenType.SampleResume:
      return response?.ai_generation?.status === status;
    default:
      return false;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const processMissingFields = (response: any, progressScreenKey: ProgressScreenType) => {
  const jobDescriptionProgressStatus = getJobDescriptionProgressStatus(response, progressScreenKey);
  const missingFieldsContent: MissingInfoContent = {
    missingFields: undefined,
    hasMissingFields:
      jobDescriptionProgressStatus && jobDescriptionProgressStatus.status === 'missing',
    missingFieldsEndpoint: '',
    jobDescriptionId: jobDescriptionProgressStatus ? jobDescriptionProgressStatus.id : '',
  };

  switch (progressScreenKey) {
    case ProgressScreenType.JDXray:
      missingFieldsContent.missingFieldsEndpoint = `/job-description/xray/${jobDescriptionProgressStatus.id}`;
      break;
    case ProgressScreenType.JobMatchScore:
      missingFieldsContent.missingFieldsEndpoint = `/match-score`;
      break;
    case ProgressScreenType.AiCustomize:
    case ProgressScreenType.SampleResume:
      missingFieldsContent.missingFieldsEndpoint = `/resumes`;
      break;
    default:
      break;
  }

  if (missingFieldsContent.hasMissingFields) {
    // Pass form data for missing fields only
    const filteredMissingFields = missingFieldMap.filter((field) =>
      jobDescriptionProgressStatus.missing_fields.some((missingField: string) =>
        field.name.includes(missingField),
      ),
    );
    missingFieldsContent.missingFields = filteredMissingFields;
  }
  return missingFieldsContent;
};

export const getListingSnackbar = (
  currentStatus: string,
  previousStatuskey: string,
  isNavigatedFromProgressPage: boolean,
  setIsNavigatedFromProgressPage: (value: boolean) => void,
  snackbarMessage: {
    error: string;
    success: string;
    in_progress: string;
    missing: string;
  },
) => {
  // read previous resume listing status from storage and update with new status
  const previousStatus = Storage.get(previousStatuskey);
  Storage.set(previousStatuskey, currentStatus);

  // TODO: quick fix, show login snackbar with some delay
  const logInSnackbarStatus = getShowLogInSnackbarStatus();
  if (!logInSnackbarStatus) {
    setTimeout(() => {
      setShowLogInSnackbarStatus(true);
    }, 5000);
  }

  if (previousStatus) {
    const isMissingStatus =
      (previousStatus !== 'pending' && currentStatus === 'pending') ||
      (previousStatus !== 'missing' && currentStatus === 'missing');

    // TODO: rework on the keys. These should come from the enum.
    if (isMissingStatus && isNavigatedFromProgressPage) {
      setIsNavigatedFromProgressPage(false);
      return { severity: 'error', message: snackbarMessage.missing, showControl: true };
    }
    if (previousStatus !== 'error' && currentStatus === 'error' && logInSnackbarStatus) {
      return { severity: 'error', message: snackbarMessage.error };
    }
    if (previousStatus !== 'done' && currentStatus === 'done' && logInSnackbarStatus) {
      return { severity: 'success', message: snackbarMessage.success };
    }
  }

  if (previousStatus !== 'in_progress' && currentStatus === 'in_progress' && logInSnackbarStatus) {
    return { severity: 'info', message: snackbarMessage.in_progress };
  }

  return null;
};
