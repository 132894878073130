import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ProgressManager from '../../../components/ProgressManager';
import { ProgressScreenType } from '../../../components/ProgressManager/constants';
import { useSnackbar } from '../../../contexts/snackbar';
import { EventAction, EventCategory, logEvent } from '../../../services/analytics';
import { JobDescriptionTypes } from '../../../services/jobDescriptions';
import { JobMatchScoreKeys, createNewJobMatchScore } from '../../../services/jobMatches';
import { ResumesKeys, uploadJdResume } from '../../../services/resumes';
import UploadOnboardingProfile from './UploadOnboardingProfile';

/**
 * `OnboardingJobMatch` component manages the onboarding flow for creating a job match score.
 * It allows users to upload a job description and resume, and then calculates a match score
 * to assess how well the resume aligns with the job description.
 *
 * This component handles the upload process, displays progress, and manages any errors encountered.
 *
 * @component
 * @returns {JSX.Element} Rendered `OnboardingJobMatch` component
 *
 * @example
 * // Example usage:
 * <OnboardingJobMatch />
 *
 */
const OnboardingJobMatch = () => {
  const [matchScoreId, setMatchScoreId] = useState('');
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showProgress, setShowProgress] = useState(false);
  const queryClient = useQueryClient(); // Query client for cache management

  /**
   * `createJobMatch` mutation sends a request to create a job match score.
   *
   * On success, it logs an event, sets the `matchScoreId` state with the response ID,
   * and displays the progress manager to track the processing of the job match score.
   */
  const { mutate: createJobMatch } = useMutation({
    retry: 3,
    mutationFn: createNewJobMatchScore,
    onSuccess: (res) => {
      // Invalidate cache to refresh job match score data
      queryClient.invalidateQueries([JobMatchScoreKeys.JOBMATCHES]);
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Onboarding Job Match Score Creation started',
      );
      setMatchScoreId(res?.data.match_score_id);
      setShowProgress(true);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors || 'Failed to create job match score. Please try again.',
      );
    },
  });

  /**
   * Handles the upload of a job description and resume, required for creating a match score.
   *
   * @async
   * @function
   * @param {Object} data - Data object containing the job description and optional resume file.
   * @param {string} data.description - Job description text to upload.
   * @param {File} [data.file] - Optional resume file.
   * @returns {Promise<Object>} API response containing job description and resume data.
   */
  const uploadJdResumeQueryFuntion = async ({
    description,
    file,
  }: {
    description: string;
    file?: File;
  }) => {
    const res = await uploadJdResume(description, file as File);
    return res;
  };

  /**
   * `uploadJdResumeData` mutation uploads the job description and resume data.
   *
   * On success, it invokes the `createJobMatch` mutation to initiate match score creation.
   * Handles potential errors by displaying appropriate snackbar messages.
   */
  const { mutate: uploadJdResumeData, isLoading: isJdResumeUploading } = useMutation({
    retry: 3,
    mutationFn: uploadJdResumeQueryFuntion,
    onMutate: () => {
      logEvent(
        EventCategory.FORM_SUBMISSION,
        EventAction.SUBMIT,
        'Upload Job Description & resume data for onboarding job match score',
      );
    },
    onSuccess: (jdResumeData: { job_description_id: string; resume_id: string }) => {
      // Invalidate cache to refresh job x-rays and resume data
      queryClient.invalidateQueries([JobDescriptionTypes.JOBXRAYS, ResumesKeys.RESUMES]);
      createJobMatch({
        job_description_id: jdResumeData.job_description_id,
        resume_id: jdResumeData.resume_id,
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      showSnackbar(
        'error',
        error.response?.data?.errors ||
          'Failed to upload Resume or Job Description. Please try again.',
      );
    },
  });

  /**
   * Handles navigation back to the previous page in the onboarding process.
   */
  const onBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {showProgress ? (
        /**
         * `ProgressManager` displays the status of job match score calculation.
         * Log a success event on completion.
         */
        <ProgressManager
          id={matchScoreId}
          progressScreenKey={ProgressScreenType.JobMatchScore}
          successEventLabel="Onboarding Job Match Score Creation Successful"
        />
      ) : (
        /**
         * `UploadOnboardingProfile` component renders the upload form
         * and triggers `uploadJdResumeData` on form submission.
         */
        <UploadOnboardingProfile
          onCreateClick={uploadJdResumeData}
          onBackClick={onBackClick}
          isLoading={isJdResumeUploading}
          title="Create Job Match Score"
          description="Match your resume to the job description to check alignment and receive a detailed score report."
        />
      )}
    </>
  );
};

export default OnboardingJobMatch;
