import { useState } from 'react';
import { useNavigate } from 'react-router';

import { Stack, Typography, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import Button from '../../../components/common/Button';
import SelectCard from '../../../components/common/SelectCard';
import useResponsiveDevice from '../../../hooks/useResponsiveDevice';
import RoutePaths from '../../../routes/RoutePaths';
import { isNilOrEmpty } from '../../../utils';
import type { OnboardingFlowOptions } from '../constants';
import { onboardingFlowOptions } from '../constants';
import OnboardingCard from './OnboardingCard';

interface ChooseOnboardingFlowProps {
  onBackClick: () => void;
}

/**
 * Renders a component that allows the user to choose an onboarding flow.
 * The component displays different flow options and allows the user to select one.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Function} props.onBackClick - The callback function to be called when the "Back" button is clicked.
 * @returns {JSX.Element} The rendered ChooseOnboardingFlow component.
 *
 * @example
 *
 * <ChooseOnboardingFlow onBackClick={() => {}} />
 */
const ChooseOnboardingFlow = ({ onBackClick }: ChooseOnboardingFlowProps) => {
  const [selectedItem, setSelectedItem] = useState<OnboardingFlowOptions>();
  const { isMobile } = useResponsiveDevice();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleItemClick = (item: OnboardingFlowOptions) => {
    setSelectedItem(item);
  };

  const onNextClick = () => {
    navigate(`${RoutePaths.ONBOARDING}/${selectedItem?.href || ''}`);
  };

  return (
    <OnboardingCard
      rootSx={{ height: `calc(100% - ${theme.spacing(8.25)})` }}
      cardContentProps={{
        sx: { alignItems: 'unset' },
      }}
    >
      <Grid spacing={2} container>
        <Grid xs={12} sx={{ paddingTop: 0, paddingBottom: 3 }}>
          <Typography variant={isMobile ? 'h2' : 'h1'} sx={{ paddingBottom: 1.5 }}>
            Choose what you’d like to do
          </Typography>
          <Typography variant="body2">
            Explore tools to evaluate job fit, tailor your resume, build a new one, or analyze job
            descriptions.
          </Typography>
        </Grid>
        {onboardingFlowOptions.map((item) => (
          <Grid key={item.key} xs={12} md={6} sx={{ width: '100%' }}>
            <SelectCard
              cardContent={item}
              sx={{
                borderColor: selectedItem?.key === item.key ? 'primary.main' : 'border.light',
                height: '100%',
              }}
              onClick={() => handleItemClick(item)}
            />
          </Grid>
        ))}
      </Grid>

      <Stack
        sx={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          gap: 1.5,
        }}
      >
        <Button
          variant="outlined"
          onClick={onBackClick}
          sx={{
            width: { xs: '30%', sm: 'fit-content' },
            ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
          }}
        >
          Back
        </Button>
        <Button
          disabled={isNilOrEmpty(selectedItem)}
          onClick={onNextClick}
          sx={{
            width: { xs: '70%', sm: 'fit-content' },
            ...(isMobile && { padding: `${theme.spacing(2)} ${theme.spacing(1.5)}` }),
          }}
        >
          Next
        </Button>
      </Stack>
    </OnboardingCard>
  );
};

export default ChooseOnboardingFlow;
