import FAQs from '../../../components/FAQs/FAQs';

export const BillingFaqs = () => {
  const links = [
    {
      label: 'Do I need to provide payment information to start a free trial?',
      href: 'https://jobquest.ai/pricing/',
    },
    {
      label: 'What does the JobQuest free trial include?',
      href: 'https://jobquest.ai/pricing/',
    },
    {
      label: 'What happens after the free trial ends?',
      href: 'https://jobquest.ai/pricing/',
    },
    {
      label: 'Can I cancel my subscription anytime?',
      href: 'https://jobquest.ai/pricing/',
    },
    {
      label: 'Can I get a refund?',
      href: 'https://jobquest.ai/pricing/',
    },
    {
      label: 'Is my credit card information secure?',
      href: 'https://jobquest.ai/pricing/',
    },
    {
      label: 'Who should I contact for payment-related issues?',
      href: 'https://jobquest.ai/pricing/',
    },
  ];

  return <FAQs links={links} />;
};
