import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import Confetti from 'react-confetti';
import { useLocation, useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import BreadCrumbs from '../../components/common/BreadCrumbs';
import Tabs from '../../components/common/Tabs';
import { useSnackbar } from '../../contexts/snackbar';
import useResponsiveAppBar from '../../hooks/useResponsiveAppBar';
import useResponsiveDevice from '../../hooks/useResponsiveDevice';
import RoutePaths from '../../routes/RoutePaths';
import { EventAction, EventCategory, logEvent } from '../../services/analytics';
import { BillingKeys, PlanTypes } from '../../services/billing';
import { deleteUserProfile } from '../../services/user';
import { useAccountStatusStore } from '../../stores/AccountStatusStore';
import { useAuthStore } from '../../stores/AuthStore';
import theme from '../../theme/theme';
import BillingSection from './components/BillingSection';
import DeleteAccount from './components/DeleteAccount';

// TODO: generalize Confetti
const ConfettiColors = ['#00B050', '#007435', '#F4C5C8', '#228EF2', '#7030A0', '#D67319'];

/**
 * ProfileSettings component renders the settings page for user profiles.
 * It includes tabs for 'Account' and 'Billing' settings, and handles
 * navigation and state management for these sections.
 *
 * @component
 * @example
 * return (
 *   <ProfileSettings />
 * )
 *
 * @returns {JSX.Element} The rendered ProfileSettings component.
 *
 * @remarks
 * - Utilizes `useAuth` for authentication-related actions like logout.
 * - Uses `useSnackbar` to display notifications.
 * - Employs `useLocation` and `useNavigate` from `react-router-dom` for routing.
 * - Uses `useMemo` to memoize the tabs content and default selected tab value.
 * - Uses `useMutation` from `react-query` to handle profile deletion.
 *
 * @function
 * @name ProfileSettings
 */
const ProfileSettings = () => {
  const { logout } = useAuthStore();
  const { showSnackbar } = useSnackbar();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useResponsiveDevice();
  const { reInitialize: reInitializeAccountStatusStore } = useAccountStatusStore();
  const queryClient = useQueryClient();

  const timer = useRef<NodeJS.Timeout | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [confettiPieces, setConfettiPieces] = useState(0);

  const TabsContent = useMemo(
    () => [
      { value: 'account', label: 'Account', icon: 'fi fi-rr-user' },
      { value: 'billing', label: 'Billing', icon: 'fi fi-rr-credit-card' },
    ],
    [],
  );

  const BreadCrumbLinks = [
    {
      label: 'Settings',
      href: RoutePaths.SETTINGS,
    },
  ];

  const defaultSelectedTabValue = useMemo(() => {
    // check if the path is /settings/account or /settings/billing or /settings/
    const selectedTabvalue = location.pathname.split('/')[2];
    return selectedTabvalue !== '' ? selectedTabvalue : 'account';
  }, [location.pathname]);

  const { mutate: deleteProfile } = useMutation({
    mutationFn: deleteUserProfile,
    onSuccess: () => {
      logEvent(EventCategory.USER_INTERACTION, EventAction.CLICK, 'Delete Account started');
      logout();
      showSnackbar('success', 'Account Deleted');
    },
  });

  // TODO: create a custom hook for mutationWithRetry
  // A mutation to reinitialize account status store
  // checks if account status is updated and show confetti & success message
  // else keeps retrying until it is updated for 1 minute
  const { mutate: fetchAccountStatusForPlanChange } = useMutation({
    mutationFn: reInitializeAccountStatusStore,
    onSuccess(data) {
      if (data?.subscription === PlanTypes.PRO) {
        setTimeout(() => {
          setConfettiPieces(200);
          queryClient.invalidateQueries([BillingKeys.CURRENT_SUBSCRIPTION_PLAN]);
          queryClient.invalidateQueries([BillingKeys.INVOICES]);
          logEvent(EventCategory.FORM_SUBMISSION, EventAction.SUBMIT, 'User subscription upgraded');
          showSnackbar('success', 'Upgraded to JobQuest Pro');
          setRetryCount(0);

          // after 7 seconds, set confetti pieces to 0, so they can gracefully fall away
          // and new pieces are not generated
          timer.current = setTimeout(() => {
            setConfettiPieces(0);
            // remove the query params from the url
            navigate(RoutePaths.BILLING);
          }, 4000);
        }, 1000);

        return;
      }

      // keep retrying until account status is updated for <= 60 retries
      if (retryCount < 60) {
        setRetryCount(retryCount + 1);
        timer.current = setTimeout(() => {
          fetchAccountStatusForPlanChange();
        }, 1000);
      } else {
        setRetryCount(0);
        showSnackbar('error', 'Problem updating the application status. Please refresh the page.');
      }
    },
  });

  const updateLocationPath = (value: string) => {
    // update the browser path when the tab is changed
    navigate(`${RoutePaths.SETTINGS}/${value}`);
  };

  useLayoutEffect(() => {
    // check if query params has action=success
    const queryParams = new URLSearchParams(location.search);
    const action = queryParams.get('action');

    if (action === 'success') {
      fetchAccountStatusForPlanChange();
    }

    return () => clearTimeout(timer.current as NodeJS.Timeout);
  }, [location.search, navigate, queryClient, fetchAccountStatusForPlanChange, showSnackbar]);

  useResponsiveAppBar('Settings', null);

  return (
    <Stack
      sx={{
        padding: { xs: `${theme.spacing(2)} ${theme.spacing(0.5)}`, sm: 0 },
        maxWidth: { lg: '80vw' },
        gap: 4,
        margin: 'auto',
      }}
    >
      <Confetti
        numberOfPieces={confettiPieces}
        tweenDuration={500}
        colors={ConfettiColors}
        initialVelocityY={8}
        gravity={0.08}
      />
      {!isMobile && <BreadCrumbs links={BreadCrumbLinks} />}
      <Tabs
        tabs={TabsContent}
        defaultSelected={defaultSelectedTabValue}
        onChange={updateLocationPath}
        sx={{ paddingLeft: { xs: 0, sm: 3.5 }, boxSizing: 'border-box' }}
      >
        <DeleteAccount onDelete={deleteProfile} />
        <BillingSection />
      </Tabs>
    </Stack>
  );
};
export default ProfileSettings;
